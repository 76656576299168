import { ApplicationConstants as BaseApplicationConstants } from 'src/app/utils/ApplicationConstants';

export class ApplicationConstants extends BaseApplicationConstants {

  static readonly SUCCESS_LABLE_ADD_USER = "User has been added successfully";
  static readonly SPECIALASSESMENT_LABEL = "SPECIALASSESMENT";
  static APP_BASE_HREF_GROUP_PORTAL: any;
  //static GROUP_PORTAL: string;
  static TOKEN_KEY: any;
  static USER_PROFILE_INFO_ID_KEY: any;
  static AUTHORITIES_KEY: any;
  static EMAIL_KEY: any;
  static FULL_NAME_KEY: any;
  static GROUP_ID_KEY: any;
  static GROUP_NAME: any;
  static URL_TOKENS_LIST_KEY: any;

}
