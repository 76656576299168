import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppResponse } from 'src/app/model/AppResponse';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from 'projects/group-portal/src/app/auth-token/services/token-storage.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AppRegExConstants } from '../../utils/AppRegExConstants';
import * as $ from 'jquery';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { CommonService } from '../../utils/common-service';
import { environment } from 'projects/system-portal/src/environments/environment';
import { delay } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DatePipe } from '@angular/common';

const helper = new JwtHelperService();
@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.css'],
  providers: [NgxSpinnerService]
})
export class EditGroupComponent implements OnInit {

  groupMasterId: string;
  appResponse: AppResponse;
  resData: AppResponse;
  groupRegistrationForm: FormGroup;
  submitted = false;
  intlObj : any;
  contactNum : any;
  isError : boolean = true;
  countryCode : any;
  countryCodeLength : any;
  flag : boolean = false;
  editable: boolean;
  public subscriptionPlanYear :any;
  resetFlag : boolean = false;

  public startDate: Date;
  public endDate : Date;
  public tempStartDate : Date;
  public tempEndDate : Date;

  public min = new Date(2019, 12, 1, 0, 0);
  public max = new Date();
  public minDateOfBirth = new Date(1899, 12, 1, 0, 0);
  public maxDateOfBirth = new Date();
  public minLeaseEndDate = new Date(2019, 12, 1, 0, 0);
  public minLeaseEndDateTemp ;
  @ViewChild('myInput', { static: false }) myInput: ElementRef;
  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private httpService: HttpTransactionService, private router: Router, private commonService: CommonService, private tokenService: TokenStorageService, private spinner: NgxSpinnerService, private datePipe: DatePipe) { }


  ngOnInit() {


    this.activatedRoute.params.subscribe(params => {
      this.groupMasterId = params.id;
    });
    // tslint:disable-next-line: max-line-length
   
    this.groupRegistrationForm = this.formBuilder.group({
      "firstName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "lastName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "groupName": ['', [Validators.required, Validators.pattern(AppRegExConstants.GROUP_NAME)]],
      "city": ['', [Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "state": ['', [Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "country": ['', [ Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "zipCode": ['', [ Validators.pattern(AppRegExConstants.ALPHABETS_DIGITS_ONLY)]],
      "startDate": ['', [Validators.required]],
      "endDate":['',[Validators.required]],
      "addressLine1": ['', Validators.required],
      "addressLine2": [''],
      "email": ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
      "houseCount": ['', [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)]],
      "groupRegistrationNum": ['', [Validators.required]],
      "housecost":['',[Validators.required]],
      "yearlyDiscount":['',[Validators.required]],
      "subscriptionname":['',[Validators.required]],
      "mailSent": [''],
      "status": [''],
      "groupAdmins": this.formBuilder.array([])
    });
    
  }

  ngAfterViewInit() {
    this.spinner.show();
    // console.log("-----");
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GROUP + this.groupMasterId).subscribe(data => {
      this.appResponse = new AppResponse(data);
      this.spinner.hide();
      // console.log(this.appResponse);
      setTimeout(() => {
        //below time set added by aishwaryaa for HB-631 30/12/2021
      }, 2000);
      if( data.status === 200 ){
          this.fillTextfeildData();
      }  else if (data.status === 401) {
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(['login']);
     } else if (data.status === 403) {
          //403 URL not accessible
          this.router.navigate(['home']);
    }
  
      
  });



  }

  get f() { return this.groupRegistrationForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.spinner.show();
    
    for (let el in this.groupRegistrationForm.controls) {
          if (this.groupRegistrationForm.controls[el].errors) {
            // console.log(el+"::::")
          }
    } 
    // console.log("-----"+!this.isError)

    if (this.groupRegistrationForm.invalid || !this.isError) {
      this.spinner.hide();
      return;
    } else {
      //console.log("FORM:" + JSON.stringify(this.groupRegistrationForm.value));
      var formData = new FormData();
     formData.append("contact",this.intlObj.getNumber());
     formData.append("groupName",this.groupRegistrationForm.controls['groupName'].value.trim());
     formData.append("firstName",this.groupRegistrationForm.controls['firstName'].value);
     formData.append("lastName",this.groupRegistrationForm.controls['lastName'].value);
     formData.append("email",this.groupRegistrationForm.controls['email'].value);
     formData.append("addressLine1",this.groupRegistrationForm.controls['addressLine1'].value);
     formData.append("addressLine2",this.groupRegistrationForm.controls['addressLine2'].value);
     formData.append("houseCount",this.groupRegistrationForm.controls['houseCount'].value);
     formData.append("groupRegistrationNum",this.groupRegistrationForm.controls['groupRegistrationNum'].value);
     formData.append("mailSent",this.groupRegistrationForm.controls['mailSent'].value);
     formData.append("status",this.groupRegistrationForm.controls['status'].value);
     formData.append("subStartDate",this.groupRegistrationForm.controls['startDate'].value);
     formData.append("subEndDate",this.groupRegistrationForm.controls['endDate'].value);
     formData.append("city",this.groupRegistrationForm.controls['city'].value);
     formData.append("state",this.groupRegistrationForm.controls['state'].value);
     formData.append("country",this.groupRegistrationForm.controls['country'].value);
     formData.append("zipCode",this.groupRegistrationForm.controls['zipCode'].value);
     formData.append("housecost",this.groupRegistrationForm.controls['housecost'].value);
     formData.append("yearlyDiscount",this.groupRegistrationForm.controls['yearlyDiscount'].value);
     formData.append("subscriptionname",this.groupRegistrationForm.controls['subscriptionname'].value); 
    // formData.append("groupAdmins", (this.groupRegistrationForm.get("groupAdmins").value));    
    // formData.append("groupAdmins",JSON.stringify(this.groupRegistrationForm.get("groupAdmins").value)); 
    //formData.append("groupAdmins",['{ "addressLine1":"add1",  "addressLine2":"add2", "city": "city1", "state": "State2", "country": "countryVal",  "zipCode":"641302",firstName": "fistnamehere", "lastName":"lastnamehere", "email":"testemail@gmail.com", "status":0,  "mailSent" : 0}']);

    //  console.log("FORM:" + JSON.stringify(formData));
    
     // http service method call
      this.httpService.update(formData, environment.BASE_URL + ApplicationURIConstants.EDIT_GROUP + this.groupMasterId)
        .subscribe((res) => {
          this.spinner.hide();
          if (res != undefined) {
            this.resData = new AppResponse(res);
            if (res.status == 200) {
              this.submitted = false;
              $("#category").prop('selectedIndex', 0);
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['ga-list']);
              }, 2000);  //15s
            } else if (res.status == 401) {
              this.router.navigate(['login']);
            } else {
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
            }
          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }


  }

  changeFunction() {
    if ($("#mailSent").is(":checked")) {
      this.groupRegistrationForm.value.isMailSent = true;
    } else {
      this.groupRegistrationForm.value.isMailSent = false;
    }
  }

  changeStatus() {
    if ($("#isActive").is(":checked")) {
      this.groupRegistrationForm.value.status = true;
    } else {
      this.groupRegistrationForm.value.status = false;
    }
  }

  fillTextfeildData(){

    if( this.appResponse.data != null ){
      if(this.appResponse.data.startDate != null){
        this.tempStartDate = new Date(this.appResponse.data.startDate);
        this.startDate = new Date(this.tempStartDate.getFullYear(), this.tempStartDate.getMonth(), this.tempStartDate.getDate());
        this.minLeaseEndDateTemp = this.startDate;
        var datetemp = new Date(this.minLeaseEndDateTemp.getFullYear(),this.minLeaseEndDateTemp.getMonth(),this.minLeaseEndDateTemp.getDate()+1);
        this.minLeaseEndDate = datetemp;
      }
      if(this.appResponse.data.endDate != null){
        this.tempEndDate = new Date(this.appResponse.data.endDate);
        this.endDate = new Date(this.tempEndDate.getFullYear(), this.tempEndDate.getMonth(), this.tempEndDate.getDate());
      }
      
      this.groupRegistrationForm = this.formBuilder.group({
        "firstName": [this.appResponse.data.firstName, [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
        "lastName": [this.appResponse.data.lastName, [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
        "groupName": [this.appResponse.data.groupName, [Validators.required, Validators.pattern(AppRegExConstants.GROUP_NAME)]],
        "startDate": [this.startDate, [Validators.required]],
        "endDate":[this.endDate,[Validators.required]],
        "addressLine1": [this.appResponse.data.addressLine1, Validators.required],
        "addressLine2": [this.appResponse.data.addressLine2],
        "email": [this.appResponse.data.email, [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
        "houseCount": [this.appResponse.data.houseCount, [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)]],
        "groupRegistrationNum": [this.appResponse.data.groupRegistrationNum, [Validators.required]],
        "city": [this.appResponse.data.city, [Validators.pattern(AppRegExConstants.ALPHABETS)]],
        "state": [this.appResponse.data.state, [Validators.pattern(AppRegExConstants.ALPHABETS)]],
        "country": [this.appResponse.data.country, [ Validators.pattern(AppRegExConstants.ALPHABETS)]],
        "zipCode": [this.appResponse.data.zipCode, [ Validators.pattern(AppRegExConstants.ALPHABETS_DIGITS_ONLY)]],
        "housecost":[this.appResponse.data.houseCost,[Validators.required]],
        "yearlyDiscount":[this.appResponse.data.yearlyDiscount,[Validators.required]],
        "subscriptionname":[this.appResponse.data.subscriptionName,[Validators.required]],
        "mailSent": [''],
        "status": [''],
        "groupAdmins": this.formBuilder.array([])
      });

      if (this.appResponse.data.isEmailSent == 'true') {
        $("#mailSent").prop("checked", true);
        this.groupRegistrationForm.controls['mailSent'].setValue(true);
      } else {
        $("#mailSent").prop("checked", false);
        this.groupRegistrationForm.controls['mailSent'].setValue(false);
      }

      if (this.appResponse.data.status == "true") {
        $( "#isActive" ).prop( "checked", true );
        this.groupRegistrationForm.controls['status'].setValue(true);
      } else {
        $("#isActive").prop('checked', false);
        this.groupRegistrationForm.controls['status'].setValue(false);
      }
      //Contact - HB-426 Hevisa Patel 28-01-2021 Start
      this.intlObj.setNumber(this.appResponse.data.contact);
       $("#phoneInput").focus();
       this.myInput.nativeElement.blur();
       this.resetFlag = true;
    }
  }


  onKeyup(event){
    // console.log(event)
  }

  telInputObject(obj) {
    // console.log("===111");
    this.intlObj = obj;
    //console.log("-----???"+this.appResponse.data.contact);
    obj.setNumber(this.appResponse.data.contact);
    this.flag = true;
  }

  onCountryChange(country: any) {
    // console.log("======="+country.dialCode);
    this.countryCode = country.dialCode;
    if(this.flag)
      this.intlObj.setNumber('');
    else
      this.flag = true;
    
  }

  hasError(obj) {
    this.isError = obj;
    // console.log('hasError: ', obj);
}
/* HB-677 Priya -SystemPotal BuildErrorFix Start */
	getNumber(obj){
		// console.log("*******"+JSON.stringify(obj));
	}
/* HB-677 Priya -SystemPotal BuildErrorFix end */

test(){
  // console.log("ttttt");
}
  

onChange(data) {
  if(data !== null){
    this.editable = true;
    this.minLeaseEndDateTemp = data;
    var datetemp = new Date(this.minLeaseEndDateTemp.getFullYear(),this.minLeaseEndDateTemp.getMonth(),this.minLeaseEndDateTemp.getDate()+1);
    this.minLeaseEndDate = datetemp;
    this.groupRegistrationForm.controls['endDate'].setValue(null);
 }else{
    this.groupRegistrationForm.controls['endDate'].setValue(null);
    this.editable = false;
  }
   }


   clearSubscriptionStartDateValue() {
    this.groupRegistrationForm.controls['startDate'].setValue(null);
  }
  clearSubscriptionEndDateValue() {
    this.groupRegistrationForm.controls['endDate'].setValue(null);
  }

  onChangeEndDate(data){

  }

  checkForError(obj){
    this.resetFlag = false;
    this.myInput.nativeElement.blur();
    $("#phoneInput").focus();
  }
  
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if(charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
         return true;
      else   
         return false;
    }
    return true;
  
  }
  changeEndDate(data){
    if(this.groupRegistrationForm.controls['subscriptionname'].value != "")    {
      if(this.groupRegistrationForm.controls['startDate'].value != null){
        if(this.groupRegistrationForm.controls['subscriptionname'].value =="1year"){
          this.subscriptionPlanYear = 1;
        } else if(this.groupRegistrationForm.controls['subscriptionname'].value =="2year"){
          this.subscriptionPlanYear = 2;
        }else if(this.groupRegistrationForm.controls['subscriptionname'].value =="3year"){
          this.subscriptionPlanYear = 3;
        }
      
        this.tempEndDate = new Date(this.groupRegistrationForm.controls['startDate'].value);
        this.endDate = new Date(this.tempEndDate.getFullYear() + this.subscriptionPlanYear, this.tempEndDate.getMonth(), this.tempEndDate.getDate());
        this.groupRegistrationForm.controls['endDate'].setValue(this.endDate);
        // console.log("EndDate:::"+this.endDate);
      }
      else{
        this.groupRegistrationForm.controls['startDate'].setErrors({ required: true });
      }
    }
    else{
      this.groupRegistrationForm.controls['subscriptionname'].setErrors({ required: true });
    }
  }
  //Adding Groupadmins
  groupAdmins(): FormArray {
    return this.groupRegistrationForm.get("groupAdmins") as FormArray;
  }

  newGroupAdmins(): FormGroup {
    return this.formBuilder.group({
      email: ""
    });
  }

  addGroupAdmins() {
    this.groupAdmins().push(this.newGroupAdmins());
  }

  removeGA(ga) {
    this.groupAdmins().removeAt(ga);
  }
  

}
