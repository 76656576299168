import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../utils/common-service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { AppRegExConstants } from 'projects/system-portal/src/app/utils/AppRegExConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/system-portal/src/environments/environment';

import { DatePipe } from '@angular/common';
import * as internal from 'assert';
declare var $: any;

@Component({
  selector: 'app-group-registration',
  templateUrl: './group-registration.component.html',
  styleUrls: ['./group-registration.component.css']
})
export class GroupRegistrationComponent implements OnInit {

  @ViewChild('myInput', { static: false }) myInput: ElementRef; 
  groupRegistrationForm: FormGroup;  
  resData: AppResponse;
  submitted = false;
  intlObj : any;
  contactNum : any;
  isError : boolean = true;
  countryCode : any;
  countryCodeLength : any;
  flag : boolean = false; 
  resetFlag : boolean = false; //HB-677 Priya -SystemPotal BuildErrorFix
  public startDate: Date;
  public endDate : Date;
  public tempStartDate : Date;
  public tempEndDate : Date;
  public subscriptionPlanYear : any;
  
  public minLeaseEndDate = new Date(2019, 12, 1, 0, 0); //HB-677 Priya -SystemPotal BuildErrorFix
  
  private groups = environment.BASE_URL + ApplicationURIConstants.GROUPS;

  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router, private datePipe: DatePipe) { }

  ngOnInit() {

    $("#firstName").focus();

    this.groupRegistrationForm = this.formBuilder.group({
      "addressLine1": ['', Validators.required],
      "addressLine2": [''],
      "city": ['', [Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "state": ['', [Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "country": ['', [ Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "zipCode": ['', [ Validators.pattern(AppRegExConstants.ALPHABETS_DIGITS_ONLY)]],
      "firstName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "lastName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "email": ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
      //"contact": ['', [Validators.required, Validators.pattern(AppRegExConstants.CONTACT_NUMBER)]],
      "houseCount": ['',[Validators.required, Validators.pattern(AppRegExConstants.DIGITS)]],//Anitha
      "groupRegistrationNum": ['',[Validators.required]],//Anitha
      "groupName": ['', [Validators.required, Validators.pattern(AppRegExConstants.GROUP_NAME)]],
      "startDate":['',[Validators.required]],//Anitha
      "endDate":['',[Validators.required]], //Anitha
      "housecost":['',[Validators.required]],
      "yearlyDiscount":['',[Validators.required]],
      "subscriptionname":['',[Validators.required]],
      "mailSent": [''],
        "status": [''],
        "groupAdmins": this.formBuilder.array([])
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.groupRegistrationForm.controls; }

  onSubmit() {

    this.submitted = true;
    // console.log("FORM:" + JSON.stringify(this.groupRegistrationForm.value));
    for (let el in this.groupRegistrationForm.controls) {
          if (this.groupRegistrationForm.controls[el].errors) {
            // console.log("Invalid :::"+el+"::::")
          }
    } 
    if (this.groupRegistrationForm.invalid) {
      return;
    } else {

      if ($("#isActive").is(":checked")) {
        this.groupRegistrationForm.controls['status'].setValue(true);
      } else {
        this.groupRegistrationForm.controls['status'].setValue(false);
      }

      if ($("#mailSent").is(":checked")) {
        this.groupRegistrationForm.controls['mailSent'].setValue(true);
      } else {
        this.groupRegistrationForm.controls['mailSent'].setValue(false);
      }
      
      var formData = new FormData();      
      formData.append("addressLine1",this.groupRegistrationForm.controls['addressLine1'].value);
      formData.append("addressLine2",this.groupRegistrationForm.controls['addressLine2'].value);
      formData.append("city",this.groupRegistrationForm.controls['city'].value);
      formData.append("state",this.groupRegistrationForm.controls['state'].value);
      formData.append("country",this.groupRegistrationForm.controls['country'].value);
      formData.append("zipCode",this.groupRegistrationForm.controls['zipCode'].value);
      formData.append("firstName",this.groupRegistrationForm.controls['firstName'].value);
      formData.append("lastName",this.groupRegistrationForm.controls['lastName'].value);
      formData.append("email",this.groupRegistrationForm.controls['email'].value);
      formData.append("contact",this.intlObj.getNumber());
      formData.append("houseCount",this.groupRegistrationForm.controls['houseCount'].value);
      formData.append("groupName",this.groupRegistrationForm.controls['groupName'].value.trim());
      formData.append("groupRegistrationNum",this.groupRegistrationForm.controls['groupRegistrationNum'].value);
      formData.append("startDate",this.groupRegistrationForm.controls['startDate'].value);
      formData.append("endDate",this.groupRegistrationForm.controls['endDate'].value);     
      formData.append("mailSent",this.groupRegistrationForm.controls['mailSent'].value);
      formData.append("status",this.groupRegistrationForm.controls['status'].value);
      formData.append("subscriptionname",this.groupRegistrationForm.controls['subscriptionname'].value);
      formData.append("housecost",this.groupRegistrationForm.controls['housecost'].value);
      formData.append("yearlyDiscount",this.groupRegistrationForm.controls['yearlyDiscount'].value);
      formData.append("subStartDate",this.groupRegistrationForm.controls['startDate'].value);
      formData.append("subEndDate",this.groupRegistrationForm.controls['endDate'].value); 
      //formData.append("groupAdmins",JSON.stringify(this.groupRegistrationForm.get("groupAdmins").value));  
      
      // console.log("FORM:" + JSON.stringify(formData));

      this.httpService.save(formData, this.groups)
        .subscribe((res) => {
          this.resData = new AppResponse(res);
          if (res.status == 200) {
              this.submitted = false;
              this.groupRegistrationForm.reset();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, ApplicationConstants.GRP_REG_MSG);
              setTimeout(() => {
                this.router.navigate(['ga-list']);
                //below time set added by aishwaryaa for HB-631 30/12/2021
            }, 2000);

          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }
  }

  //contact field fn
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if(charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
         return true;
      else   
         return false;
    }
    return true;
  
  }

  checkForError(obj){
	this.resetFlag = true;  //HB-677 Priya -SystemPotal BuildErrorFix
    this.myInput.nativeElement.blur();
    $("#phoneInput").focus();
  }
  /* HB-677 Priya -SystemPotal BuildErrorFix Start */
	clearSubscriptionStartDateValue() {
		this.groupRegistrationForm.controls['startDate'].setValue(null);
	}

	clearSubscriptionEndDateValue() {
		this.groupRegistrationForm.controls['endDate'].setValue(null);
	}
	getNumber(obj){
		// console.log("*******"+JSON.stringify(obj));
	}

   /* HB-677 Priya -SystemPotal BuildErrorFix End */
  telInputObject(obj) {
    this.intlObj = obj;
    this.flag = true;
  }

  onCountryChange(country: any) {
    this.countryCode = country.dialCode;
    if(this.flag)
      this.intlObj.setNumber('');
    else
      this.flag = true;
    
  }
  hasError(obj) {
    this.isError = obj;
    // console.log('hasError: ', obj);
}

  
onChangeEndDate(){

}
  changeFunction() {
    if ($("#mailSent").is(":checked")) {
      this.groupRegistrationForm.value.isMailSent = true;
    } else {
      this.groupRegistrationForm.value.isMailSent = false;
    }
  }

  changeStatus() {
    if ($("#isActive").is(":checked")) {
      this.groupRegistrationForm.value.status = true;
    } else {
      this.groupRegistrationForm.value.status = false;
    }
  }
  changeEndDate(data){
    if(this.groupRegistrationForm.controls['subscriptionname'].value != "")    {
      if(this.groupRegistrationForm.controls['startDate'].value != null){
        if(this.groupRegistrationForm.controls['subscriptionname'].value =="1year"){
          this.subscriptionPlanYear = 1;
        } else if(this.groupRegistrationForm.controls['subscriptionname'].value =="2year"){
          this.subscriptionPlanYear = 2;
        }else if(this.groupRegistrationForm.controls['subscriptionname'].value =="3year"){
          this.subscriptionPlanYear = 3;
        }
      
        this.tempEndDate = new Date(this.groupRegistrationForm.controls['startDate'].value);
        this.endDate = new Date(this.tempEndDate.getFullYear() + this.subscriptionPlanYear, this.tempEndDate.getMonth(), this.tempEndDate.getDate());
        this.groupRegistrationForm.controls['endDate'].setValue(this.endDate);
        // console.log("endDate:::"+this.endDate);
      }
      else{
        this.groupRegistrationForm.controls['startDate'].setErrors({ required: true });
      }
    }
    else{
      this.groupRegistrationForm.controls['subscriptionname'].setErrors({ required: true });
    }
  }
  //Adding Groupadmins
  
  groupAdmins(): FormArray {
    return this.groupRegistrationForm.get("groupAdmins") as FormArray;
  }

  newGroupAdmins(): FormGroup {
    return this.formBuilder.group({
      "addressLine1": ['', Validators.required],
      "addressLine2": [''],
      "city": ['', [Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "state": ['', [Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "country": ['', [ Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "zipCode": ['', [ Validators.pattern(AppRegExConstants.ALPHABETS_DIGITS_ONLY)]],
      "firstName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "lastName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "email": ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
      "status":[''],
      "mailSent" : ['']
    });
  }

  addGroupAdmins() {
    this.groupAdmins().push(this.newGroupAdmins());
  }

  removeGA(ga) {
    this.groupAdmins().removeAt(ga);
  }
}
