import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService } from "../../utils/common-service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  ValidationErrors,
  FormControl,
} from "@angular/forms";
import { HttpTransactionService } from "../../../../../../src/app/services/http-transaction.service";
import { AppRegExConstants } from "src/app/utils/AppRegExConstants";
import { AppResponse } from "../../../../../../src/app/model/AppResponse";
import { ApplicationConstants } from "../../utils/ApplicationConstants";
import { ApplicationURIConstants } from "../../utils/ApplicationURIConstants";
import { environment } from "../../../environments/environment";
import { TokenStorageService } from "../../../../../group-portal/src/app/auth-token/services/token-storage.service";
import { NgxSpinnerService } from "ngx-spinner";

import { DatePipe } from "@angular/common";
import * as internal from "assert";

declare var $: any;

@Component({
  selector: "app-add-subscriber",
  templateUrl: "./add-subscriber.component.html",
  styleUrls: ["./add-subscriber.component.css"],
})
export class AddSubscriberComponent implements OnInit {
  @ViewChild("myInput", { static: false }) myInput: ElementRef;
  addSubscriberForm: FormGroup;
  resData: AppResponse;
  submitted = false;
  intlObj: any;
  subplantype: any;
  hidediscount: boolean = false;
  actualhousecost: any;
  contactNum: any;
  isError: boolean = true;
  countryCode: any;
  subplanterm: any;
  countryCodeLength: any;
  baseamount: any;
  tempper: any;
  specialdiscountshow: boolean = false;
  sdp: boolean = false;
  addtionalHouseCount: any;
  specialDiscount: any;
  specialDiscountType: any;
  tempAmount: any;
  amountperhouse: any;
  costPerUnit: any;
  totaldiscount: any;
  temp: any;
  temp2: any;
  specialdiscountinvalid: boolean;
  totalAmount: any;
  pretotalAmount: any;
  remarks: boolean = false;
  flag: boolean = false;
  public startDate: Date;
  public endDate: Date;
  public tempEndDate: Date;
  public traildate: Date;
  public subscriptionPlanYear: any;
  public trailTerm: any;
  subscriptionConfigResponse: AppResponse;
  subscriptionConfigArr = [];
  public subPlanType: string;
  public subPlanTerm: string;
  public freeTrail: String;
  public houseCount: number;
  public subConfig = [];
  public tooltip: string = ApplicationConstants.DEFAULT_TOOLTIP_MSG;
  resetFlag: boolean = false;
  public GRnumber: String;

  public currentdate = new Date();
  public min = new Date(this.currentdate.getFullYear() - 3, 1, 12, 10, 30);

  public formationmindate = new Date(
    this.currentdate.getFullYear() - 100,
    1,
    12,
    10,
    30
  );

  public minLeaseEndDate = new Date(2019, 12, 1, 0, 0);
  public minLeaseEndDateTemp;

  private addSubscriber =
    environment.BASE_URL + ApplicationURIConstants.ADD_SUBSCRIBER;

  private emailVerificationLink =
    environment.BASE_URL + ApplicationURIConstants.EMAIL_VERIFICATION_LINK;
  sdp2: boolean;
  sdp3: boolean;
  sd: boolean;
  sdvalid: boolean = false;
  housecounterr: boolean;
  finalamount: number;
  showbaseamounterror: boolean;
  dollorlimiterror: boolean;
  showpherror: boolean;
  basictrue: boolean;
  extendedtrue: boolean;
  premiumtrue: boolean;
  virtualarcprice: any;
  virtualmanagerprice: any;
  dedicatedphoneprice: any;
  webhostingprice: any;
  domainprice: any;
  addonamount: number;
  premiumaddonamount: number;
  domaindate: any;
  registrationdate: Date;
  isError2: boolean = true;
  showpherror2: boolean;
  intlObj2: any;
  countryCode2: any;
  gemail: any;
  nextdomaindate: any;
  nextdomainmonth: any;
  nextdomainyear: any;
  nextdomain: any;

  constructor(
    private formBuilder: FormBuilder,
    private httpService: HttpTransactionService,
    private router: Router,
    private commonService: CommonService,
    private tokenService: TokenStorageService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    // console.log(this.min);
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    var date = new Date().getDate();
    var random = Math.floor(1000 + Math.random() * 9000);
    this.GRnumber = "GR_" + year + month + date + random;
    $("#groupName").focus();
    // added by gopal for HB-629 on 31/12/2021
    this.addSubscriberForm = this.formBuilder.group({
      groupName: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.GROUP_NAME)],
      ],
      groupRegistrationNum: [this.GRnumber, [Validators.required]],
      entityIdentityNumber: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ENTITY_NUMBER),
        ],
      ],
      subscriptionPlanTerm: ["", [Validators.required]],
      trailperiod: ["", [Validators.required]],
      subscriptionPlanType: ["", [Validators.required]],
      houseCount: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)],
      ],
      housecost: ["", [Validators.required]], //pattern validation removed -Validators.pattern(AppRegExConstants.DECIMAL_INPUT
      formationDate: ["", [Validators.required]],
      specialDiscount: ["", [Validators.required]],
      discount: [0],
      discountType: [""],
      totalSubscription: [""],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      addressLine1: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ADDRESS),
          this.noWhitespaceValidator,
        ],
      ],
      country: ["", [Validators.pattern(AppRegExConstants.ALPHABETS)]],
      zipCode: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ZIPCODE),
          Validators.minLength(5),
          Validators.maxLength(6),
        ],
      ], //validation changed from ALPHABETS_DIGITS_ONLY to DIGITS
      contact: ["", Validators.required],
      groupAdmins: this.formBuilder.array([]),
      remarks: [""],
      totalcostperhouse: [""],
      baseamount: [""],
      publishkey: [""],
      secretkey: [""],
      accountid: [""],
      purchasesmail: ["", [Validators.pattern(AppRegExConstants.EMAIL)]],
      salesmail: ["", [Validators.pattern(AppRegExConstants.EMAIL)]],
      expensesmail: ["", [Validators.pattern(AppRegExConstants.EMAIL)]],
      suppliersmail: ["", [Validators.pattern(AppRegExConstants.EMAIL)]],
      virtualmanager: [false],
      dedicatedphone: [false],
      virtualarc: [false],
      premiumvirtualarc: [false],
      webhosting: [false],
      domainregistration: [false],
    });
    this.addSubscriberForm.controls["discount"].disable();
    this.addGAEmail();

    //get Subscription config
    this.httpService
      .get(
        environment.BASE_URL + ApplicationURIConstants.GET_SUBSCRIPTION_CONFIG
      )
      .subscribe((data) => {
        this.subscriptionConfigResponse = new AppResponse(data);
        if (data.status === 200) {
          if (this.subscriptionConfigResponse.data != null) {
            for (let obj of this.subscriptionConfigResponse.data) {
              this.subscriptionConfigArr.push(obj);
            }
            // console.log(this.subscriptionConfigArr);
          }
        } else if (data.status === 401) {
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(["login"]);
        } else if (data.status === 403) {
          //403 URL not accessible
          this.router.navigate(["home"]);
        }
      });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.addSubscriberForm.controls;
  }

  getFormValidationErrors() {
    Object.keys(this.addSubscriberForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors =
        this.addSubscriberForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          //  console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  onSubmit() {
    console.log(this.addSubscriberForm.get("groupAdmins")["controls"][0]["value"]);
    
    this.addSubscriberForm.get("groupAdmins")["controls"][0]["value"]["contact"]=this.intlObj2.getNumber();
    console.log(this.addSubscriberForm.get("groupAdmins")["controls"][0]["value"]);
    console.log(this.intlObj2.getNumber());
    
    
    console.log(
      JSON.stringify(this.addSubscriberForm.get("groupAdmins").value)
    );
    console.log(this.addSubscriberForm.get("groupAdmins").value.length);
    if (this.addSubscriberForm.get("groupAdmins").value.length == 0) {
      this.commonService.showNotificationSuccess(
        ApplicationConstants.MSG_BOX_LABEL,
        ApplicationConstants.DANGER_LABLE,
        "Please Add Atleast One Group Admin"
      );
      return;
    }
    this.addSubscriberForm.controls["groupRegistrationNum"].setValue(
      this.GRnumber
    );

    console.log(this.intlObj.getNumber().length + "ph error");
    if (this.intlObj.getNumber().length == 0) {
      this.showpherror = true;
      // this.isError = true;
    } else {
      this.showpherror = false;
      // this.isError = false;
    }

    this.showpherror2 = false;

    // this.isError2=false;

    if (
      this.sdp ||
      this.sdp2 ||
      this.sdp3 ||
      this.sd ||
      this.sdvalid ||
      this.dollorlimiterror ||
      !this.isError ||
      this.showpherror ||
      this.showpherror2
    ) {
      console.log("inside if");

      return;
    }

    this.submitted = true;
    console.log("stop");

    if (this.intlObj.getNumber()) {
      this.addSubscriberForm.controls["contact"].setValue(
        this.intlObj.getNumber()
      );
      console.log("inside set number");
    }
    console.log("FORM:" + JSON.stringify(this.addSubscriberForm.value));
    for (let el in this.addSubscriberForm.controls) {
      console.log("insdie for");

      if (this.addSubscriberForm.controls[el].errors) {
        console.log("Invalid :::" + el + "::::");
      }
    }
    if (this.addSubscriberForm.invalid) {
      return;
    } else {
      // console.log("hema shanker");
      // console.log(this.addSubscriberForm.controls);
      this.domaindate = new Date();

      console.log(this.domaindate);

      this.nextdomaindate = this.domaindate.getDate()-1;
      this.nextdomainmonth = this.domaindate.getMonth() + 1;
      this.nextdomainyear = this.domaindate.getFullYear() + 1;

      console.log(this.nextdomaindate);
      console.log(this.nextdomainmonth);
      console.log(this.nextdomainyear);

      this.nextdomain = new Date(
        this.nextdomainmonth +
          "/" +
          this.nextdomaindate +
          "/" +
          this.nextdomainyear
      );

      console.log(this.nextdomain + "next domain date");

      var formData = new FormData();
      formData.append(
        "groupName",
        this.addSubscriberForm.controls["groupName"].value.trim()
      );
      formData.append(
        "groupRegistrationNum",
        this.addSubscriberForm.controls["groupRegistrationNum"].value
      );
      formData.append(
        "entityIdentityNumber",
        this.addSubscriberForm.controls["entityIdentityNumber"].value
      );
      formData.append(
        "subscriptionPlanType",
        this.addSubscriberForm.controls["subscriptionPlanType"].value
      );
      formData.append(
        "formationDate",
        this.addSubscriberForm.controls["formationDate"].value
      );
      formData.append(
        "subscriptionPlanTerm",
        this.addSubscriberForm.controls["subscriptionPlanTerm"].value
      );
      formData.append(
        "trailperiod",
        this.addSubscriberForm.controls["trailperiod"].value
      );
      formData.append(
        "groupAdminsData",
        JSON.stringify(this.addSubscriberForm.get("groupAdmins").value)
      );
      formData.append(
        "houseCount",
        this.addSubscriberForm.controls["houseCount"].value
      );
      formData.append(
        "addressLine1",
        this.addSubscriberForm.controls["addressLine1"].value
      );
      formData.append(
        "housecost",
        this.addSubscriberForm.controls["housecost"].value
      );
      formData.append(
        "zipCode",
        this.addSubscriberForm.controls["zipCode"].value
      );
      formData.append(
        "specialDiscount",
        this.addSubscriberForm.controls["specialDiscount"].value
      );
      formData.append(
        "totalSubscription",
        this.addSubscriberForm.controls["totalSubscription"].value
      );
      formData.append(
        "publishkey",
        this.addSubscriberForm.controls["publishkey"].value
      );
      formData.append(
        "secretkey",
        this.addSubscriberForm.controls["secretkey"].value
      );
      formData.append(
        "accountid",
        this.addSubscriberForm.controls["accountid"].value
      );
      formData.append(
        "purchasesMail",
        this.addSubscriberForm.controls["purchasesmail"].value
      );
      formData.append(
        "suppliersMail",
        this.addSubscriberForm.controls["suppliersmail"].value
      );
      formData.append(
        "expensesMail",
        this.addSubscriberForm.controls["expensesmail"].value
      );
      formData.append(
        "salesMail",
        this.addSubscriberForm.controls["salesmail"].value
      );

      // console.log(this.addSubscriberForm.controls['discount'].value);

      formData.append(
        "discount",
        this.addSubscriberForm.controls["discount"].value
      );
      formData.append(
        "discountType",
        this.addSubscriberForm.controls["discountType"].value
      );
      formData.append(
        "country",
        this.addSubscriberForm.controls["country"].value
      );
      formData.append(
        "startDate",
        this.addSubscriberForm.controls["startDate"].value
      );
      formData.append("contact", this.intlObj.getNumber());
      formData.append(
        "endDate",
        this.addSubscriberForm.controls["endDate"].value
      );
      formData.append(
        "remarks",
        this.addSubscriberForm.controls["remarks"].value
      );
      formData.append(
        "domainregistration",
        this.addSubscriberForm.controls["domainregistration"].value
      );
      formData.append(
        "virtualmanager",
        this.addSubscriberForm.controls["virtualmanager"].value
      );
      formData.append(
        "dedicatedphone",
        this.addSubscriberForm.controls["dedicatedphone"].value
      );
      formData.append(
        "virtualarc",
        this.addSubscriberForm.controls["virtualarc"].value
      );

      formData.append(
        "premiumvirtualarc",
        this.addSubscriberForm.controls["premiumvirtualarc"].value
      );
      formData.append(
        "webhosting",
        this.addSubscriberForm.controls["webhosting"].value
      );
      formData.append("actions", "Created");
      formData.append("primarycontact", "1");
      if (this.addSubscriberForm.controls["domainregistration"].value == true)
        formData.append("domainregistrationdate", this.domaindate);
      if (this.addSubscriberForm.controls["domainregistration"].value == true)
        formData.append("nextdomain", this.nextdomain);
      // formData.append("totalSubscription", this.addSubscriberForm.controls['totalSubscription'].value);
      this.gemail = this.addSubscriberForm.get("groupAdmins").value[0].email;
      // console.log("FORM:" + JSON.stringify(formData));
      console.log(formData.append);
      console.log(
        "dataaaaaaaaaaaaaaaaaaaa" +
          this.addSubscriberForm.get("groupAdmins").value[0].email
      );

      this.httpService.save(formData, this.addSubscriber).subscribe((res) => {
        this.resData = new AppResponse(res);
        if (res.status == 200) {
          // console.log("hello this is if")

          this.submitted = false;
          this.addSubscriberForm.reset();
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.SUCCESS_LABLE,
            ApplicationConstants.GRP_REG_MSG
          );

          this.httpService
            .save({ email: this.gemail }, this.emailVerificationLink)
            .subscribe((res) => {
              console.log("emailllllllllllllllllllllllllll" + res);
              // console.log("emailllllllllllllllllllllllllll"+res);

              if (res.status == 200) {
                // console.log("hello this is if")

                this.submitted = false;
                this.addSubscriberForm.reset();
                this.commonService.showNotificationSuccess(
                  ApplicationConstants.MSG_BOX_LABEL,
                  ApplicationConstants.SUCCESS_LABLE,
                  ApplicationConstants.GRP_REG_MSG
                );
              } else {
                // console.log("hello this is else")
                this.commonService.showNotificationSuccess(
                  ApplicationConstants.MSG_BOX_LABEL,
                  ApplicationConstants.DANGER_LABLE,
                  `${this.resData.errors}`
                );
              }
            });
          setTimeout(() => {
            this.router.navigate(["subscriber-list"]);
            // below time set added by aishwaryaa for HB-631 30/12/2021
          }, 2000);
        } else {
          // console.log("hello this is else")
          this.commonService.showNotificationSuccess(
            ApplicationConstants.MSG_BOX_LABEL,
            ApplicationConstants.DANGER_LABLE,
            `${this.resData.errors}`
          );
        }
      });
    }
  }

  //contact field fn
  numberOnly(event): boolean {
    this.showpherror = false;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
        return true;
      else return false;
    }
    return true;
  }

  checkForError(obj) {
    console.log(obj.target.value + "ph error");

    if (obj.target.value.length == 0) {
      this.isError = true;
      this.showpherror = true;
    } else {
      this.showpherror = false;
      this.isError = false;
    }

    this.resetFlag = false;
    this.myInput.nativeElement.blur();
    $("#phoneInput").focus();
  }

  telInputObject(obj) {
    this.intlObj = obj;
    this.flag = true;
    this.showpherror = false;
  }

  onCountryChange(country: any) {
    this.countryCode = country.dialCode;
    if (this.flag) this.intlObj.setNumber("");
    else this.flag = true;
  }
  hasError(obj) {
    this.isError = obj;
    if (this.intlObj.getNumber().length == 0) {
      this.isError = true;
      this.showpherror = true;
    } else {
      this.showpherror = false;
      this.isError = false;
    }
    if (obj) {
      this.isError = obj;
    }
    // console.log('hasError: ', obj);
  }
  getNumber(obj) {
    // console.log("*******" + JSON.stringify(obj));
  }

  // <---------------------------contact in group admin--------------------->

  checkForError2(obj) {
    console.log(obj.target.value + "ph error");

    if (obj.target.value.length == 0) {
      this.isError2 = true;
      this.showpherror2 = true;
    } else {
      this.showpherror2 = false;
      this.isError2 = false;
    }

    this.resetFlag = false;
    this.myInput.nativeElement.blur();
    $("#contact").focus();
  }

  telInputObject2(obj) {
    this.intlObj2 = obj;
    this.flag = true;
    this.showpherror2 = false;
  }

  onCountryChange2(country: any) {
    this.countryCode2 = country.dialCode;
    if (this.flag) this.intlObj2.setNumber("");
    else this.flag = true;
  }
  hasError2(obj) {
    this.isError2 = obj;
    if (this.intlObj2.getNumber().length == 0) {
      this.isError2 = true;
      this.showpherror2 = true;
    } else {
      this.showpherror2 = false;
      this.isError2 = false;
    }
    if (obj) {
      this.isError2 = obj;
    }
    // console.log('hasError: ', obj);
  }

  changeSpecialDiscountAdditionalHouseCost() {
    console.log(this.addSubscriberForm.controls["houseCount"].value);

    this.housecounterr = false;
    if (this.addSubscriberForm.controls["houseCount"].value == 0) {
      this.housecounterr = true;
    }
    if (this.addSubscriberForm.controls["houseCount"].value) {
      console.log("true");
    } else {
      this.housecounterr = false;
    }
    this.subPlanTerm =
      this.addSubscriberForm.controls["subscriptionPlanTerm"].value;
    this.freeTrail = this.addSubscriberForm.controls["trailperiod"].value;
    this.subPlanType =
      this.addSubscriberForm.controls["subscriptionPlanType"].value;
    this.houseCount = this.addSubscriberForm.controls["houseCount"].value;
    this.addSubscriberForm.controls["housecost"].setValue(0);
    this.addSubscriberForm.controls["specialDiscount"].setValue(0);
    this.tooltip = ApplicationConstants.DEFAULT_TOOLTIP_MSG;
    this.subConfig = [];

    if (
      this.subscriptionConfigArr &&
      this.subPlanTerm != "" &&
      this.subPlanType != "" &&
      this.houseCount &&
      this.subscriptionConfigResponse.data
    ) {
      if (this.houseCount > ApplicationConstants.DEFAULT_HOUSE_COUNT) {
        this.hidediscount = true;
        console.log("inside above 235 in dis");

        // console.log(this.subConfig);

        this.subscriptionConfigArr.forEach((currentElement) => {
          console.log("in for");

          if (
            currentElement.subscriptionplanterm.toLowerCase() ==
              this.subPlanTerm &&
            currentElement.subscriptionplantype.toLowerCase() ==
              this.subPlanType.toLowerCase()
          ) {
            console.log("in  one if ");

            // console.log(currentElement.subscriptionplanterm.toLowerCase() + currentElement.subscriptionplantype.toLowerCase());
            // console.log(currentElement);

            this.subConfig.push(currentElement);
            // console.log(this.subConfig.length);
          }
        });
        // console.log(this.subConfig);
      } else {
        this.hidediscount = false;
        if (this.houseCount == 0) {
          this.housecounterr = true;
        } else {
          this.housecounterr = false;
        }
        console.log("inside below 235 in dis");
        // this.subConfig.forEach(i=>{
        //   // this.subConfig.pop();
        //   console.log(i);

        // })

        // console.log(this.subConfig);

        this.subscriptionConfigArr.forEach((currentElement) => {
          // console.log(currentElement.subscriptionplanterm.toLowerCase() + currentElement.subscriptionplantype.toLowerCase());
          if (
            currentElement.subscriptionplanterm.toLowerCase() ==
              this.subPlanTerm &&
            currentElement.subscriptionplantype.toLowerCase() ==
              this.subPlanType.toLowerCase()
          ) {
            // console.log(currentElement.subscriptionplanterm.toLowerCase() + currentElement.subscriptionplantype.toLowerCase());
            //   console.log(currentElement);
            this.subConfig.push(currentElement);
            // this.subConfig[0].additionalpriceperhouse=0;
            //  this.subConfig[0].specialdiscount=0;
            //  console.log(this.subConfig.length);
          }
          // console.log(this.subConfig);

          // console.log(this.subConfig[0].specialdiscount);
          // console.log(this.subConfig[0].additionalpriceperhouse);
        });

        // if(this.subplantype=='Basic'){
        //   this.addSubscriberForm.controls['totalcostperhouse'].setValue(1);
        // }else if(this.subplantype=='Extended'){
        //   // this.subConfig[0].price=658;
        //   this.addSubscriberForm.controls['totalcostperhouse'].setValue(2);
        // }else{
        //   // this.subConfig[0].price=1316;
        //   this.addSubscriberForm.controls['totalcostperhouse'].setValue(4);
        // }
      }

      // console.log(this.subConfig);

      if (this.subConfig) {
        this.addSubscriberForm.controls["housecost"].setValue(
          this.subConfig[0].additionalpriceperhouse
        );
        this.addSubscriberForm.controls["specialDiscount"].setValue(
          this.subConfig[0].specialdiscount
        );
        this.addSubscriberForm.controls["baseamount"].setValue(
          this.subConfig[0].price
        );

        this.tooltip = this.subConfig[0].tooltip;
        this.baseamount = this.subConfig[0].price;
        // console.log( this.baseamount);
      }
    }
  }

  varc() {
    console.log(this.addSubscriberForm.controls["virtualarc"].value);
  }
  hosting() {
    console.log(this.addSubscriberForm.controls["webhosting"].value);
  }
  domain() {
    console.log(this.addSubscriberForm.controls["domainregistration"].value);
  }
  dphonenumber() {
    console.log(this.addSubscriberForm.controls["dedicatedphone"].value);
  }
  vmanager() {
    console.log(this.addSubscriberForm.controls["virtualmanager"].value);
  }
  vparc() {
    console.log(this.addSubscriberForm.controls["premiumvirtualarc"].value);
  }

  forcheckbox() {
    if (
      this.addSubscriberForm.controls["subscriptionPlanType"].value == "Basic"
    ) {
      this.basictrue = true;
      this.extendedtrue = false;
      this.premiumtrue = false;
      this.addSubscriberForm.controls["virtualarc"].setValue(false);
      this.addSubscriberForm.controls["virtualmanager"].setValue(false);
      this.addSubscriberForm.controls["webhosting"].setValue(false);
      this.addSubscriberForm.controls["domainregistration"].setValue(false);
      this.addSubscriberForm.controls["dedicatedphone"].setValue(false);
      console.log(this.basictrue);
      this.addSubscriberForm.controls["premiumvirtualarc"].setValue(false);
    } else if (
      this.addSubscriberForm.controls["subscriptionPlanType"].value ==
      "Extended"
    ) {
      this.extendedtrue = true;
      this.basictrue = false;
      this.premiumtrue = false;
      this.addSubscriberForm.controls["virtualarc"].setValue(false);
      this.addSubscriberForm.controls["virtualmanager"].setValue(false);
      this.addSubscriberForm.controls["webhosting"].setValue(false);
      this.addSubscriberForm.controls["domainregistration"].setValue(false);
      this.addSubscriberForm.controls["dedicatedphone"].setValue(false);
      console.log(this.extendedtrue);
      this.addSubscriberForm.controls["premiumvirtualarc"].setValue(false);
    } else if (
      this.addSubscriberForm.controls["subscriptionPlanType"].value == "Premium"
    ) {
      this.premiumtrue = true;
      this.basictrue = false;
      this.extendedtrue = false;
      this.addSubscriberForm.controls["virtualarc"].setValue(false);
      this.addSubscriberForm.controls["virtualmanager"].setValue(false);
      this.addSubscriberForm.controls["webhosting"].setValue(false);
      this.addSubscriberForm.controls["domainregistration"].setValue(false);
      this.addSubscriberForm.controls["dedicatedphone"].setValue(false);
      console.log(this.premiumtrue);
      this.addSubscriberForm.controls["premiumvirtualarc"].setValue(false);
    }
  }
  calculate() {
    if (this.addSubscriberForm.controls["discount"].value != null) {
      if (
        this.addSubscriberForm.controls["discountType"].value == "dollor" &&
        this.addSubscriberForm.controls["discount"].value == 0
      ) {
        this.sdvalid = true;
        this.sd = false;
      } else {
        this.sdvalid = false;
        this.sd = false;
      }
      this.remarks = true;
    } else {
      this.remarks = false;
      if (this.addSubscriberForm.controls["discountType"].value == "dollor") {
        this.sd = true;
        this.sdvalid = false;
      } else {
        this.sd = false;
        this.sdvalid = false;
      }
    }

    this.subplanterm = this.subConfig[0].subscriptionplanterm;
    this.subplantype = this.subConfig[0].subscriptionplantype;
    this.virtualarcprice = this.subConfig[0].virtualarc;
    this.virtualmanagerprice = this.subConfig[0].virtualmanager;
    this.dedicatedphoneprice = this.subConfig[0].dedicatedphone;
    this.webhostingprice = this.subConfig[0].webhosting;
    this.domainprice = this.subConfig[0].domainregistration;

    // console.log(this.virtualarcprice+"virtual arc");
    // console.log(this.virtualmanagerprice+"virtual manager");
    // console.log(this.dedicatedphoneprice+"dedicated phone");
    // console.log(this.webhostingprice+"web hosting");
    // console.log(this.domainprice +"domain registration");

    this.specialDiscountType =
      this.addSubscriberForm.controls["discountType"].value;

    if (
      this.specialDiscountType == "percentage" ||
      this.specialDiscountType == "dollor"
    ) {
      this.specialdiscountshow = true;
      this.addSubscriberForm.controls["discount"].enable();
    } else {
      this.specialdiscountshow = false;
    }

    this.specialDiscount = this.addSubscriberForm.controls["discount"].value;

    if (this.houseCount >= 235) {
      this.addtionalHouseCount = this.houseCount - 235;
      console.log("inside above 235 homes top");
    } else if (this.houseCount < 235) {
      this.sdp = false;
      this.sdp2 = false;
      this.sdp3 = false;
      this.totalAmount = this.houseCount;
      console.log(this.specialDiscountType);

      if (this.specialDiscountType == "dollor") {
        console.log("inisde dollor");

        console.log(this.specialDiscount + " " + this.baseamount);
        if (this.specialDiscount > this.baseamount) {
          this.showbaseamounterror = true;
        } else {
          this.showbaseamounterror = false;

          this.finalamount = this.baseamount - this.specialDiscount;
        }
      } else {
        this.showbaseamounterror = false;
        this.finalamount = this.baseamount;
      }
      if (this.specialDiscount == "") {
        this.finalamount = this.baseamount - this.specialDiscount;
      }

      if (
        this.addSubscriberForm.controls["subscriptionPlanType"].value == "Basic"
      ) {
        this.addSubscriberForm.controls["totalSubscription"].setValue(
          this.finalamount
        );
      } else if (
        this.addSubscriberForm.controls["subscriptionPlanType"].value ==
        "Extended"
      ) {
        this.addonamount = 0;
        if (this.addSubscriberForm.controls["virtualarc"].value == true) {
          this.addonamount += Number(this.virtualarcprice);
        }
        if (this.addSubscriberForm.controls["virtualmanager"].value == true) {
          this.addonamount += Number(this.virtualmanagerprice);
        }
        if (this.addSubscriberForm.controls["dedicatedphone"].value == true) {
          this.addonamount += Number(this.dedicatedphoneprice);
        }
        if (this.addSubscriberForm.controls["webhosting"].value == true) {
          this.addonamount += Number(this.webhostingprice);
        }
        if (
          this.addSubscriberForm.controls["domainregistration"].value == true
        ) {
          this.addonamount += Number(this.domainprice);
        }
        this.addSubscriberForm.controls["totalSubscription"].setValue(
          Number(this.finalamount) + Number(this.addonamount)
        );
      } else if (
        this.addSubscriberForm.controls["subscriptionPlanType"].value ==
        "Premium"
      ) {
        if (this.addonamount > 0) {
          console.log("inside if");

          this.addSubscriberForm.controls["totalSubscription"].setValue(
            this.finalamount
          );
          this.premiumaddonamount = 0;
          if (
            this.addSubscriberForm.controls["premiumvirtualarc"].value == true
          ) {
            console.log("inside premium if");

            this.premiumaddonamount += Number(this.virtualarcprice);
            this.addSubscriberForm.controls["totalSubscription"].setValue(
              Number(this.finalamount) + Number(this.premiumaddonamount)
            );
          } else {
            this.addSubscriberForm.controls["totalSubscription"].setValue(
              this.finalamount
            );
          }
        } else {
          this.addSubscriberForm.controls["totalSubscription"].setValue(
            this.finalamount
          );
          this.premiumaddonamount = 0;
          console.log("inside premium if");

          if (
            this.addSubscriberForm.controls["premiumvirtualarc"].value == true
          ) {
            this.premiumaddonamount += Number(this.virtualarcprice);
            this.addSubscriberForm.controls["totalSubscription"].setValue(
              Number(this.finalamount) + Number(this.premiumaddonamount)
            );
          } else {
            this.addSubscriberForm.controls["totalSubscription"].setValue(
              this.finalamount
            );
          }
        }
      }
      // this.addSubscriberForm.controls["totalSubscription"].setValue(
      //   this.finalamount );

      // console.log("before condition line 773");

      // console.log(this.finalamount+"line 776");

      return this.totalAmount;
    }

    if (this.specialDiscountType == "percentage") {
      this.dollorlimiterror = false;
      if (this.subplanterm == "1year") {
        this.totaldiscount = this.specialDiscount + 10;
      } else if (this.subplanterm == "2year") {
        this.totaldiscount = this.specialDiscount + 20;
      } else if (this.subplanterm == "3year") {
        this.totaldiscount = this.specialDiscount + 30;
      }
      if (this.subplantype == "Basic") {
        this.actualhousecost = 1;
      } else if (this.subplantype == "Extended") {
        this.actualhousecost = 2;
      } else {
        this.actualhousecost = 4;
      }

      this.sdp = false;

      this.tempper = (this.actualhousecost / 100) * this.totaldiscount;
      this.amountperhouse = this.actualhousecost - this.tempper;
      this.addSubscriberForm.controls["totalcostperhouse"].setValue(
        this.amountperhouse.toFixed(2)
      );
      this.tempAmount = this.addtionalHouseCount * this.amountperhouse;

      this.totalAmount = this.baseamount + this.tempAmount;

      this.totalAmount = this.totalAmount.toFixed(2);

      // this.addSubscriberForm.controls["totalSubscription"].setValue(
      //   this.totalAmount
      // );

      if (
        this.addSubscriberForm.controls["subscriptionPlanType"].value == "Basic"
      ) {
        this.addSubscriberForm.controls["totalSubscription"].setValue(
          this.totalAmount
        );
      } else if (
        this.addSubscriberForm.controls["subscriptionPlanType"].value ==
        "Extended"
      ) {
        this.addonamount = 0;
        if (this.addSubscriberForm.controls["virtualarc"].value == true) {
          // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount)+Number(this.virtualarcprice));
          this.addonamount += Number(this.virtualarcprice);
          // console.log(this.addonamount+"arc");

          // console.log(this.totalAmount+"inside arc");
        }
        if (this.addSubscriberForm.controls["virtualmanager"].value == true) {
          // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.virtualmanagerprice));
          this.addonamount += Number(this.virtualmanagerprice);
          // console.log(this.addonamount+"virtualmanagerprice");
          // console.log(this.totalAmount+"inside virtualmanager");
        }
        if (this.addSubscriberForm.controls["dedicatedphone"].value == true) {
          // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.dedicatedphoneprice));
          this.addonamount += Number(this.dedicatedphoneprice);
          // console.log(this.addonamount+"dedicatedphoneprice");
          // console.log(this.totalAmount+"inside dedicatedphone");
        }
        if (this.addSubscriberForm.controls["webhosting"].value == true) {
          // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.webhostingprice));
          this.addonamount += Number(this.webhostingprice);
          // console.log(this.addonamount+"webhostingprice");
          // console.log(this.totalAmount+"inside webhosting");
        }
        if (
          this.addSubscriberForm.controls["domainregistration"].value == true
        ) {
          // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.domainprice));
          this.addonamount += Number(this.domainprice);
          // console.log(this.totalAmount+"inside domainregistration");
          // console.log(this.addonamount+"domainprice");
        }
        this.addSubscriberForm.controls["totalSubscription"].setValue(
          Number(this.totalAmount) + Number(this.addonamount)
        );
      } else if (
        this.addSubscriberForm.controls["subscriptionPlanType"].value ==
        "Premium"
      ) {
        if (this.addonamount > 0) {
          console.log("inside if");
          this.premiumaddonamount = 0;
          this.addSubscriberForm.controls["totalSubscription"].setValue(
            this.totalAmount
          );

          if (
            this.addSubscriberForm.controls["premiumvirtualarc"].value == true
          ) {
            console.log("inside premium if");

            this.premiumaddonamount += Number(this.virtualarcprice);
            this.addSubscriberForm.controls["totalSubscription"].setValue(
              Number(this.totalAmount) + Number(this.premiumaddonamount)
            );
          } else {
            this.addSubscriberForm.controls["totalSubscription"].setValue(
              this.totalAmount
            );
          }
        } else {
          this.addSubscriberForm.controls["totalSubscription"].setValue(
            this.totalAmount
          );

          this.premiumaddonamount = 0;
          if (
            this.addSubscriberForm.controls["premiumvirtualarc"].value == true
          ) {
            console.log("inside premium else");

            this.premiumaddonamount += Number(this.virtualarcprice);
            this.addSubscriberForm.controls["totalSubscription"].setValue(
              Number(this.totalAmount) + Number(this.premiumaddonamount)
            );
          } else {
            this.addSubscriberForm.controls["totalSubscription"].setValue(
              this.totalAmount
            );
          }
        }
        //       this.addonamount=0;
        //       if(this.addSubscriberForm.controls['virtualarc'].value==true){
        //         // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount)+Number(this.virtualarcprice));
        //         this.addonamount+=Number(this.virtualarcprice);
        //         // console.log(this.addonamount+"arc");

        //         // console.log(this.totalAmount+"inside arc");
        //       } if(this.addSubscriberForm.controls['virtualmanager'].value==true){
        //         // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.virtualmanagerprice));
        //         this.addonamount+=Number(this.virtualmanagerprice);
        //         // console.log(this.addonamount+"virtualmanagerprice");
        //         // console.log(this.totalAmount+"inside virtualmanager");
        //       } if(this.addSubscriberForm.controls['dedicatedphone'].value==true){
        //         // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.dedicatedphoneprice));
        //         this.addonamount+=Number(this.dedicatedphoneprice);
        //         // console.log(this.addonamount+"dedicatedphoneprice");
        //         // console.log(this.totalAmount+"inside dedicatedphone");
        //       } if(this.addSubscriberForm.controls['webhosting'].value==true){
        //         // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.webhostingprice));
        //         this.addonamount+=Number(this.webhostingprice);
        //         // console.log(this.addonamount+"webhostingprice");
        //         // console.log(this.totalAmount+"inside webhosting");
        //       } if(this.addSubscriberForm.controls['domainregistration'].value==true){
        //         // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.domainprice));
        //         this.addonamount+=Number(this.domainprice);
        //         // console.log(this.totalAmount+"inside domainregistration");
        //         // console.log(this.addonamount+"domainprice");
        //       }
        // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.totalAmount) +Number(this.addonamount));
      }

      // console.log(this.finalamount+"line 754");
      // console.log(this.totalAmount+"line 794");

      if (this.subplanterm == "1year") {
        if (this.specialDiscount <= 90 && this.specialDiscount > 0) {
        } else {
          this.sdp = true;
          this.sdp2 = false;
          this.sdp3 = false;
        }
      } else if (this.subplanterm == "2year") {
        if (this.specialDiscount <= 80 && this.specialDiscount > 0) {
          this.sdp2 = false;
        } else {
          this.sdp2 = true;
          this.sdp = false;
          this.sdp3 = false;
        }
      } else if (this.subplanterm == "3year") {
        if (this.specialDiscount <= 70 && this.specialDiscount > 0) {
          this.sdp3 = false;
        } else {
          this.sdp3 = true;
          this.sdp2 = false;
          this.sdp = false;
        }
      }
    } else if (this.specialDiscountType == "dollor") {
      this.sdp = false;
      this.sdp2 = false;
      this.sdp3 = false;
      this.specialdiscountinvalid = false;

      if (this.houseCount >= 235) {
        this.addtionalHouseCount = this.houseCount - 235;
      } else if (this.houseCount < 235) {
        this.totalAmount = this.houseCount;
        this.finalamount = this.baseamount - this.specialDiscount;

        this.addSubscriberForm.controls["totalSubscription"].setValue(
          this.finalamount
        );

        // console.log(this.finalamount+"line 835");
        // console.log(this.totalAmount+"line 836");
        return this.totalAmount;
      }
      if (this.subplanterm == "1year") {
        this.totaldiscount = 10;
      } else if (this.subplanterm == "2year") {
        this.totaldiscount = 20;
      } else {
        this.totaldiscount = 30;
      }
      if (this.subplantype == "Basic") {
        this.actualhousecost = 1;
      } else if (this.subplantype == "Extended") {
        this.actualhousecost = 2;
      } else {
        this.actualhousecost = 4;
      }

      this.tempper = (this.actualhousecost / 100) * this.totaldiscount;
      this.amountperhouse = this.actualhousecost - this.tempper;

      this.addSubscriberForm.controls["totalcostperhouse"].setValue(
        this.amountperhouse.toFixed(2)
      );

      this.tempAmount = this.addtionalHouseCount * this.amountperhouse;

      this.tempAmount = this.tempAmount - this.specialDiscount;

      this.totalAmount = this.baseamount + this.tempAmount;

      this.totalAmount = this.totalAmount.toFixed(2);

      if (this.totalAmount < 0) {
        this.dollorlimiterror = true;
      } else {
        this.dollorlimiterror = false;
      }
      // this.addSubscriberForm.controls["totalSubscription"].setValue(
      //   this.totalAmount
      // );
      if (
        this.addSubscriberForm.controls["subscriptionPlanType"].value == "Basic"
      ) {
        this.addSubscriberForm.controls["totalSubscription"].setValue(
          this.totalAmount
        );
      } else if (
        this.addSubscriberForm.controls["subscriptionPlanType"].value ==
        "Extended"
      ) {
        this.addonamount = 0;
        if (this.addSubscriberForm.controls["virtualarc"].value == true) {
          // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount)+Number(this.virtualarcprice));
          this.addonamount += Number(this.virtualarcprice);
          // console.log(this.addonamount+"arc");

          // console.log(this.totalAmount+"inside arc");
        }
        if (this.addSubscriberForm.controls["virtualmanager"].value == true) {
          // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.virtualmanagerprice));
          this.addonamount += Number(this.virtualmanagerprice);
          // console.log(this.addonamount+"virtualmanagerprice");
          // console.log(this.totalAmount+"inside virtualmanager");
        }
        if (this.addSubscriberForm.controls["dedicatedphone"].value == true) {
          // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.dedicatedphoneprice));
          this.addonamount += Number(this.dedicatedphoneprice);
          // console.log(this.addonamount+"dedicatedphoneprice");
          // console.log(this.totalAmount+"inside dedicatedphone");
        }
        if (this.addSubscriberForm.controls["webhosting"].value == true) {
          // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.webhostingprice));
          this.addonamount += Number(this.webhostingprice);
          // console.log(this.addonamount+"webhostingprice");
          // console.log(this.totalAmount+"inside webhosting");
        }
        if (
          this.addSubscriberForm.controls["domainregistration"].value == true
        ) {
          // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.domainprice));
          this.addonamount += Number(this.domainprice);
          // console.log(this.totalAmount+"inside domainregistration");
          // console.log(this.addonamount+"domainprice");
        }
        this.addSubscriberForm.controls["totalSubscription"].setValue(
          Number(this.totalAmount) + Number(this.addonamount)
        );
      } else if (
        this.addSubscriberForm.controls["subscriptionPlanType"].value ==
        "Premium"
      ) {
        this.addSubscriberForm.controls["totalSubscription"].setValue(
          this.totalAmount
        );

        if (this.addonamount > 0) {
          console.log("inside if");
          this.premiumaddonamount = 0;
          this.addSubscriberForm.controls["totalSubscription"].setValue(
            this.totalAmount
          );
          this.premiumaddonamount = 0;
          if (
            this.addSubscriberForm.controls["premiumvirtualarc"].value == true
          ) {
            console.log("inside premium if");

            this.premiumaddonamount += Number(this.virtualarcprice);
            this.addSubscriberForm.controls["totalSubscription"].setValue(
              Number(this.totalAmount) + Number(this.premiumaddonamount)
            );
          } else {
            this.addSubscriberForm.controls["totalSubscription"].setValue(
              this.totalAmount
            );
          }
        } else {
          this.addSubscriberForm.controls["totalSubscription"].setValue(
            this.totalAmount
          );
          this.premiumaddonamount = 0;
          if (
            this.addSubscriberForm.controls["premiumvirtualarc"].value == true
          ) {
            console.log("inside premium else");

            this.premiumaddonamount += Number(this.virtualarcprice);
            this.addSubscriberForm.controls["totalSubscription"].setValue(
              Number(this.totalAmount) + Number(this.premiumaddonamount)
            );
          } else {
            this.addSubscriberForm.controls["totalSubscription"].setValue(
              this.totalAmount
            );
          }
        }
        //       this.addonamount=0;
        //       if(this.addSubscriberForm.controls['virtualarc'].value==true){
        //         this.addonamount+=Number(this.virtualarcprice);

        //       } if(this.addSubscriberForm.controls['virtualmanager'].value==true){
        //         this.addonamount+=Number(this.virtualmanagerprice);
        //       } if(this.addSubscriberForm.controls['dedicatedphone'].value==true){
        //         this.addonamount+=Number(this.dedicatedphoneprice);
        //       } if(this.addSubscriberForm.controls['webhosting'].value==true){
        //         this.addonamount+=Number(this.webhostingprice);
        //       } if(this.addSubscriberForm.controls['domainregistration'].value==true){
        //         this.addonamount+=Number(this.domainprice);
        //       }
        // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.totalAmount) +Number(this.addonamount));
      }

      // console.log(this.finalamount+"line 754");
      // console.log(this.totalAmount+"line 879");
    } else {
      if (this.houseCount >= 235) {
        this.addtionalHouseCount = this.houseCount - 235;
        if (this.subplanterm == "1year") {
          this.totaldiscount = 10;
        } else if (this.subplanterm == "2year") {
          this.totaldiscount = 20;
        } else {
          this.totaldiscount = 30;
        }
        if (this.subplantype == "Basic") {
          this.actualhousecost = 1;
        } else if (this.subplantype == "Extended") {
          this.actualhousecost = 2;
        } else {
          this.actualhousecost = 4;
        }
        this.tempper = (this.actualhousecost / 100) * this.totaldiscount;
        this.amountperhouse = this.actualhousecost - this.tempper;
        this.addSubscriberForm.controls["totalcostperhouse"].setValue(
          this.amountperhouse.toFixed(2)
        );
        this.tempAmount = this.addtionalHouseCount * this.amountperhouse;

        this.totalAmount = this.baseamount + this.tempAmount;

        this.totalAmount = this.totalAmount.toFixed(2);

        // this.addSubscriberForm.controls["totalSubscription"].setValue(
        //   this.totalAmount
        // );

        if (
          this.addSubscriberForm.controls["subscriptionPlanType"].value ==
          "Basic"
        ) {
          this.addonamount = 0;
          this.addSubscriberForm.controls["totalSubscription"].setValue(
            this.totalAmount
          );
        } else if (
          this.addSubscriberForm.controls["subscriptionPlanType"].value ==
          "Extended"
        ) {
          this.addonamount = 0;
          if (this.addSubscriberForm.controls["virtualarc"].value == true) {
            // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount)+Number(this.virtualarcprice));
            this.addonamount += Number(this.virtualarcprice);
            // console.log(this.addonamount+"arc");

            // console.log(this.totalAmount+"inside arc");
          }
          if (this.addSubscriberForm.controls["virtualmanager"].value == true) {
            // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.virtualmanagerprice));
            this.addonamount += Number(this.virtualmanagerprice);
            // console.log(this.addonamount+"virtualmanagerprice");
            // console.log(this.totalAmount+"inside virtualmanager");
          }
          if (this.addSubscriberForm.controls["dedicatedphone"].value == true) {
            // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.dedicatedphoneprice));
            this.addonamount += Number(this.dedicatedphoneprice);
            // console.log(this.addonamount+"dedicatedphoneprice");
            // console.log(this.totalAmount+"inside dedicatedphone");
          }
          if (this.addSubscriberForm.controls["webhosting"].value == true) {
            // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.webhostingprice));
            this.addonamount += Number(this.webhostingprice);
            // console.log(this.addonamount+"webhostingprice");
            // console.log(this.totalAmount+"inside webhosting");
          }
          if (
            this.addSubscriberForm.controls["domainregistration"].value == true
          ) {
            // this.addSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.domainprice));
            this.addonamount += Number(this.domainprice);
            // console.log(this.totalAmount+"inside domainregistration");
            // console.log(this.addonamount+"domainprice");
          }
          this.addSubscriberForm.controls["totalSubscription"].setValue(
            Number(this.totalAmount) + Number(this.addonamount)
          );
        } else if (
          this.addSubscriberForm.controls["subscriptionPlanType"].value ==
          "Premium"
        ) {
          if (this.addonamount > 0) {
            console.log("inside if");

            this.addSubscriberForm.controls["totalSubscription"].setValue(
              this.totalAmount
            );
            this.premiumaddonamount = 0;
            if (
              this.addSubscriberForm.controls["premiumvirtualarc"].value == true
            ) {
              console.log("inside premium if");

              this.premiumaddonamount += Number(this.virtualarcprice);
              this.addSubscriberForm.controls["totalSubscription"].setValue(
                Number(this.totalAmount) + Number(this.premiumaddonamount)
              );
            } else {
              this.addSubscriberForm.controls["totalSubscription"].setValue(
                this.totalAmount
              );
            }
          } else {
            this.addSubscriberForm.controls["totalSubscription"].setValue(
              this.totalAmount
            );

            this.premiumaddonamount = 0;
            if (
              this.addSubscriberForm.controls["premiumvirtualarc"].value == true
            ) {
              console.log("inside premium else");

              this.premiumaddonamount += Number(this.virtualarcprice);
              this.addSubscriberForm.controls["totalSubscription"].setValue(
                Number(this.totalAmount) + Number(this.premiumaddonamount)
              );
            } else {
              this.addSubscriberForm.controls["totalSubscription"].setValue(
                this.totalAmount
              );
            }
          }
        }

        // console.log(this.finalamount+"line 754");
        console.log(this.totalAmount + "line 911");
      } else if (this.houseCount < 235) {
        if (this.subplantype == "Basic") {
          this.actualhousecost = 1;
        } else if (this.subplantype == "Extended") {
          this.actualhousecost = 2;
        } else {
          this.actualhousecost = 4;
        }
        this.totalAmount = this.houseCount;

        this.addSubscriberForm.controls["totalcostperhouse"].setValue(
          this.actualhousecost
        );

        this.addSubscriberForm.controls["totalSubscription"].setValue(
          this.baseamount
        );
        console.log(this.finalamount + "line 930");
        console.log(this.totalAmount + "line 931");
        console.log(this.baseamount + "line 932");

        return this.baseamount;
      }
    }
  }
  changeEndDate(data) {
    if (this.addSubscriberForm.controls["subscriptionPlanTerm"].value != "") {
      if (this.addSubscriberForm.controls["startDate"].value != null) {
        if (
          this.addSubscriberForm.controls["subscriptionPlanTerm"].value ==
          "1year"
        ) {
          this.subscriptionPlanYear = 1;
        } else if (
          this.addSubscriberForm.controls["subscriptionPlanTerm"].value ==
          "2year"
        ) {
          this.subscriptionPlanYear = 2;
        } else if (
          this.addSubscriberForm.controls["subscriptionPlanTerm"].value ==
          "3year"
        ) {
          this.subscriptionPlanYear = 3;
        }
        this.tempEndDate = new Date(
          this.addSubscriberForm.controls["startDate"].value
        );
        this.endDate = new Date(
          this.tempEndDate.getFullYear() + this.subscriptionPlanYear,
          this.tempEndDate.getMonth(),
          this.tempEndDate.getDate() - 1
        );
        this.addSubscriberForm.controls["endDate"].setValue(this.endDate);
      } else {
        this.addSubscriberForm.controls["startDate"].setErrors({
          required: true,
        });
      }
    } else {
      this.addSubscriberForm.controls["subscriptionPlanTerm"].setErrors({
        required: true,
      });
    }
  }

  changeStartDate(data) {
    if (this.addSubscriberForm.controls["trailperiod"].value != "") {
      // if (this.addSubscriberForm.controls['startDate'].value != null) {
      if (this.addSubscriberForm.controls["trailperiod"].value == "Immediate") {
        // var now = new Date();
        // this.trailTerm= now.setDate(now.getDate() + 30);

        this.traildate = new Date();
        this.tempEndDate = new Date(
          this.traildate.getFullYear(),
          this.traildate.getMonth(),
          this.traildate.getDate()
        );
      }
      if (this.addSubscriberForm.controls["trailperiod"].value == "30Days") {
        // var now = new Date();
        // this.trailTerm= now.setDate(now.getDate() + 30);

        this.traildate = new Date();
        this.tempEndDate = new Date(
          this.traildate.getFullYear(),
          this.traildate.getMonth(),
          this.traildate.getDate() + 30
        );
      } else if (
        this.addSubscriberForm.controls["trailperiod"].value == "60Days"
      ) {
        this.traildate = new Date();
        this.tempEndDate = new Date(
          this.traildate.getFullYear(),
          this.traildate.getMonth(),
          this.traildate.getDate() + 60
        );
      } else if (
        this.addSubscriberForm.controls["trailperiod"].value == "90Days"
      ) {
        this.traildate = new Date();
        this.tempEndDate = new Date(
          this.traildate.getFullYear(),
          this.traildate.getMonth(),
          this.traildate.getDate() + 90
        );
      }
      // this.traildate = new Date();
      // this.tempEndDate = new Date(this.traildate.getFullYear() , this.traildate.getMonth(), this.traildate.getDate()+  this.trailTerm);
      this.addSubscriberForm.controls["startDate"].setValue(this.tempEndDate);
      // }
      // else {
      //   this.addSubscriberForm.controls['startDate'].setErrors({ required: true });
      // }
    } else {
      this.addSubscriberForm.controls["subscriptionPlanTerm"].setErrors({
        required: true,
      });
    }
  }
  //Adding Groupadmins

  groupAdmins(): FormArray {
    return this.addSubscriberForm.get("groupAdmins") as FormArray;
  }

  newGroupAdmin(): FormGroup {
    return this.formBuilder.group({
      firstName: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)],
      ],
      lastName: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)],
      ],
      email: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)],
      ],
      contact: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)],
      ],
    });
  }

  addGAEmail() {
    this.groupAdmins().push(this.newGroupAdmin());
  }

  removeGAEmail(ga) {
    this.groupAdmins().removeAt(ga);
  }
}
