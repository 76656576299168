import { Component, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { environment } from 'projects/system-portal/src/environments/environment';

@Component({
  selector: 'app-sa-config-list',
  templateUrl: './sa-config-list.component.html',
  styleUrls: ['./sa-config-list.component.css']
})
export class SaConfigListComponent implements OnInit {

  dataTable: any;
  resData: AppResponse;
  // tslint:disable-next-line: max-line-length
  constructor(private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService) {
  }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.SYSTEM_CONGIGS).subscribe(res => {
      this.resData = new AppResponse(res);
      setTimeout(() => {
        $('#saConfigsDatatable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 6,
            orderable: false
          },
          {
            targets: 1,
            width: '30%'
          }
        ]
        });
      }, 200);
      if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });

  }

}
