import { ApplicationURIConstants as BaseApplicationURIConstants } from 'src/app/utils/ApplicationURIConstants';

export class ApplicationURIConstants extends BaseApplicationURIConstants{

  // Base Url
  static readonly BASE_URL = 'http://localhost:8080/system-portal';

  // group configs
  static readonly GROUP_CONGIGS = '/groupConfigs';

  // system configs
  static readonly SYSTEM_CONGIGS = '/appSettings';
  static readonly GROUP_LIST = '/groupList';

  static readonly GROUPS = '/groups';
  static readonly GROUP = '/groupList/';
  static readonly EDIT_GROUP = '/editGroup/';
  static readonly GET_USER_PROFILE = "/get_user_profile";
  static readonly EDIT_USER_PROFILE = "/edit-profile";
  static readonly FORGET_PASSWORD = "/forgetPassword"
  static readonly TOKEN = '/tokens/';
  static readonly RESET_PASSWORD = '/resetPassword';
  static readonly CHANGE_PASSWORD = '/changePassword/';


  // Router Authentication
  static readonly WHITE_LISTED_DOMAINS = 'http://localhost:8080/system-portal';
  static readonly BLACK_LISTED_ROUTERS = 'http://localhost:8080/system-portal/signin';

  //Error Log
  static readonly ERROR_LOG = '/errorlog';
  
    /** HB-678, HB-679,HB-680,HB-681, HB-682,HB-683 Priya Start*/  
  static readonly GET_SYSTEMADMINS_LIST = '/getAllSAList';
  static readonly ACTIVATE_SYSTEMADMIN = '/activateSA';  
  static readonly ADD_SYSTEMADMIN = '/addSA';
  static readonly UPDATE_SYSTEMADMIN = "/edit-profile";
  static readonly DELETE_SYSTEMADMIN = '/deleteSA';
  static readonly DEACTIVATE_SYSTEMADMIN = '/deactivateSA';
  static readonly SEND_OTP_TO_EMAIL_OF_SYSTEMADMIN = '/sendOTPtoEmailOfSA';
  static readonly VALIDATE_OTP_TOSEND_RESETPWDLINK_SA ='/validateOTPtoSendResetPwdLinkToSA';
   /** HB-678, HB-679,HB-680,HB-681, HB-682,HB-683 Priya End*/
   
   /** HB-630, HB-629, HB-631,HB-632,HB-654 Priya Start */ 

  static readonly GET_GROUP_NAMES = '/getgroupnames'; 
	static readonly SUBSCRIBER_LIST = '/subscriberList';  
	static readonly TEMPLATE_EDIT_SUBSCRIBER = 'edit-subscriber/'; 
	static readonly ADD_SUBSCRIBER = '/addSubscriber';
	static readonly UPDATE_SUBSCRIBER = '/updateSubscriber';
	static readonly GET_SUBSCRIBER = '/getSubscriber';
	static readonly GET_ACTIVE_GROUPADMIN_DETAILS = '/getActiveGroupAdminDetails';
	static readonly GET_GROUPADMIN_DETAILS = '/getGroupAdminDetails';
	static readonly GET_SUBSCRIPTION_CONFIG = '/getSubscriptionConfig';
	static readonly DEACTIVATE_SUBSCRIBER = '/deactivateSubscriber';
	static readonly ACTIVATE_SUBSCRIBER = '/activateSubscriber';
	static readonly UPDATE_GROUPADMIN = '/updateGroupAdmin';
	static readonly ADD_GROUPADMIN = '/addGroupAdmin';
	static readonly DELETE_GROUPADMIN = '/deleteGroupAdmin';
	static readonly DEACTIVATE_GROUPADMIN = '/deactivateGroupAdmin';
	static readonly ACTIVATE_GROUPADMIN = '/activateGroupAdmin';
	static readonly SEND_OTP_TO_EMAIL_OF_GROUPADMIN = '/sendOTPtoEmailOfGroupAdmin';
  static readonly SENT_OTP_TO_PROMOTE_PRIMARY_EMAIL='/sentOtpPromoteEmail';
	static readonly VALIDATE_OTP_TOSEND_RESETPWDLINK = '/validateOTPToSendResetPwdLink';
  static readonly GET_PAYMENT_REPORT = '/getpaymentreport';

  static readonly SUBSCRIPTION_HISTORY='/subscriptionhistory'
  static readonly GET_TAXPERCENTAGE='/zipcodetaxpercentage'
  static readonly GET_BILLING_HISTORY = '/getbillinghistory';
  static readonly GENERATE_TAX = '/generatetax';


  static readonly PROMOTE_AS_PRIMARY_GROUP_ADMIN = '/promoteasadmin';

  static readonly EMAIL_VERIFICATION_LINK='/sendemailverify';
  static readonly SENT_EMAIL_TO_PRIMARY_GA="/sendotptoprimaryga";
	 /** HB-630, HB-629, HB-631,HB-632,HB-654 Priya End */ 
   
}

