import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ModuleWithProviders } from '@angular/core';
import { GaListComponent } from './components/ga-list/ga-list.component';
import { SaConfigListComponent } from './components/sa-config-list/sa-config-list.component';
import { AppRoutingModule } from './utils/app-routing.module';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { AuthGuard } from './auth-token/AuthGuard ';
import { httpInterceptorProviders } from './auth-token/auth-interceptor';
import { ApplicationConstants } from './utils/ApplicationConstants';
import { HeaderComponent } from './shared/pages/header/header.component';
import { FooterComponent } from './shared/pages/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ApplicationURIConstants } from './utils/ApplicationURIConstants';
import { NavComponent } from './shared/pages/nav/nav.component';
import { GroupRegistrationComponent } from './components/group-registration/group-registration.component';
import { GaViewComponent } from './components/ga-view/ga-view.component';
import { EditGroupComponent } from './components/edit-group/edit-group.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { environment } from '../environments/environment';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxCaptchaModule } from 'ngx-captcha';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ChartsModule } from 'ng2-charts';
import { SystemAdminsListComponent } from './components/system-admins-list/system-admins-list.component'; //HB-683 Priya
import { SubscriberListComponent } from './components/subscriber-list/subscriber-list.component';  // HB-630 Priya
import { EditSubscriberComponent } from './components/edit-subscriber/edit-subscriber.component';  //HB-629 Priya
import { AddSubscriberComponent } from './components/add-subscriber/add-subscriber.component'; //HB-629 Priya
import { GroupadminsListComponent } from './components/groupadmins-list/groupadmins-list.component'; // HB-631 Priya
import { DeactivateSubscriberComponent } from './components/deactivate-subscriber/deactivate-subscriber.component';  // HB-632 Priya
import { ActivateSubscriberComponent } from './components/activate-subscriber/activate-subscriber.component';
import { AuditPaymentComponent } from './components/audit-payment/audit-payment.component';  // HB-654 Priya
import { MatAutocompleteModule, MatInputModule } from '@angular/material';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SystemSubscriptionHistoryComponent } from './components/system-subscription-history/system-subscription-history.component';
import { BillingHistoryComponent } from './components/billing-history/billing-history.component';
import { ZipcodePercentageComponent } from './components/zipcode-percentage/zipcode-percentage.component';
const providers = [
  HttpTransactionService,
  AuthGuard,
  httpInterceptorProviders,
  {provide: APP_BASE_HREF, useValue: ApplicationConstants.APP_BASE_HREF_SYSTEM_PORTAL}
];

export function tokenGetter() {
  return JSON.parse(localStorage.getItem(ApplicationConstants.SYSTEM_PORTAL))[0][ApplicationConstants.TOKEN_KEY];
}

@NgModule({
  declarations: [
    AppComponent,
    SaConfigListComponent,
    GaListComponent,
    NavComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    DashboardComponent,
    GroupRegistrationComponent,
    GaViewComponent,
    EditGroupComponent,
    EditProfileComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    SystemAdminsListComponent, //HB-683 Priya
	SubscriberListComponent, //HB-630 Priya
    EditSubscriberComponent, AddSubscriberComponent, //HB-629 Priya	
    GroupadminsListComponent,  //HB-631 Priya
	DeactivateSubscriberComponent, //HB-632 Priya
	ActivateSubscriberComponent, AuditPaymentComponent, SystemSubscriptionHistoryComponent, BillingHistoryComponent, ZipcodePercentageComponent //HB-654 Priya
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatInputModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.ERROR }),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        whitelistedDomains: [environment.WHITE_LISTED_DOMAINS],
        blacklistedRoutes: [environment.BLACK_LISTED_ROUTERS]
      }
    }),
    NgbModule,
    ChartsModule,
    Ng2TelInputModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgxCaptchaModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule
    
  ],
  providers: [providers,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }

@NgModule({})
export class SystemPortalModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers
    };
  }
}
