import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { CommonService } from 'projects/system-portal/src/app/utils/common-service';
import { Router } from '@angular/router';
import { AppRegExConstants } from 'src/app/utils/AppRegExConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationConstants } from 'src/app/utils/ApplicationConstants';
import { environment } from 'projects/system-portal/src/environments/environment';
import { TokenStorageService } from '../../auth-token/services/token-storage.service'; // HB-677 Priya
import { NgxSpinnerService } from 'ngx-spinner';
import { MouseEvent } from 'ngx-bootstrap/utils/facade/browser';
declare var $: any;

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css'],
  providers: [NgxSpinnerService]
})
export class EditProfileComponent implements OnInit {

  responseData: AppResponse;
  requestData: AppResponse;
  userEditForm: FormGroup;
  tokenData : String [] = [];
  intlObj : any;
  contactNum : any;
  isError : boolean = true;
  countryCode : any;
  countryCodeLength : any;
  flag : boolean = false;
  //code added by aishwaryaa for hb-749
  isSuperAdmin;
  //code end 
  resetFlag : boolean = false;//HB-426 Hevisa Patel 28-01-2021 
 // @ViewChild("phoneInpt3",{static : false}) phoneInpt3: ElementRef;
  @ViewChild('phoneInpt2',{static : false}) phoneInpt2: ElementRef;
  @ViewChild('myInput', { static: true }) myInput: ElementRef;
//HB-677 Priya token service
  constructor(private httpService: HttpTransactionService, private formBuilder: FormBuilder, private commonService: CommonService, private router: Router, private spinner: NgxSpinnerService, private tokenService: TokenStorageService) {
  }
  
  ngOnInit() {  
    this.userEditForm = this.formBuilder.group({
      "firstName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "lastName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],     
     // "contact": ['', [Validators.required]],     
      "email": ['', [ Validators.pattern(AppRegExConstants.EMAIL)]],
      "userProfileInfoId": ['', [Validators.required]]    
     });

   
  }

  ngAfterViewInit() {
    this.spinner.show();
    // console.log("=======>>>> ngAfterViewInit <<<<==========");
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_USER_PROFILE).subscribe(res => {
      // console.log(JSON.stringify(res));
      this.spinner.hide();
      this.responseData = new AppResponse(res);
      
    if (res.status == 200) {
        this.fillTextfeildData();
        // console.log("------222222222222");
      //   const event = new KeyboardEvent('keyup', {
      //                bubbles: true
      //  });
      //   this.contInput.nativeElement.dispatchEvent(event);
      //  console.log("------"+!this.isError);
     // $(this).closest('#phoneInpt2').find('#phoneInpt').click();
    // this.phoneInpt2.nativeElement.click();
    $( "#phoneInpt2" ). trigger( "click" );
    $( "#firstName" ). trigger( "click" );
    }else if (res.status === 401) {
        //401 token related issue
       this.router.navigate(['login']);
    }  else if (res.status == 403){        
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.errors}`); 
          this.router.navigate(['home']);
    } else{
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.responseData.errors}`);
    }
      
    });
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.userEditForm.controls; }
  
  submitted = false;
  private editUserProfile = environment.BASE_URL + ApplicationURIConstants.EDIT_USER_PROFILE;

 onSubmit(){
  this.spinner.show();
  
    this.submitted = true;
    
    if (this.userEditForm.invalid || !this.isError) {
      //  console.log("----->> EditUser Invalid <<------");
      // this.userEditForm.controls['contact'].setValue( this.userEditForm.value.contact);
       this.spinner.hide();
       return;
    } else {
     // this.userEditForm.controls['contact'].setValue(this.intlObj.getNumber());
     var formData = new FormData();
     formData.append("contact",this.intlObj.getNumber());
     formData.append("userProfileInfoId",this.userEditForm.controls['userProfileInfoId'].value);
     formData.append("firstName",this.userEditForm.controls['firstName'].value);
     formData.append("lastName",this.userEditForm.controls['lastName'].value);
     formData.append("email",this.userEditForm.controls['email'].value);
    //  console.log("----->> EditUser Valid <<------")
    //  console.log("-------"+JSON.stringify(formData));
      this.httpService.save(formData,  this.editUserProfile)
      .subscribe((resp) => {       
        
        this.requestData = new AppResponse(resp);
        if (this.requestData.status == 200) {
           this.tokenData = JSON.parse(localStorage.getItem(ApplicationConstants.SYSTEM_PORTAL));
           var saPortal = {
            session: []
           };
           
           saPortal.session.push({
             [ApplicationConstants.TOKEN_KEY] :  this.tokenData[0][ApplicationConstants.TOKEN_KEY],
             [ApplicationConstants.USER_PROFILE_INFO_ID_KEY] : this.tokenData[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY],
             [ApplicationConstants.EMAIL_KEY] :  this.tokenData[0][ApplicationConstants.EMAIL_KEY],
             [ApplicationConstants.FULL_NAME_KEY] : this.userEditForm.controls['firstName'].value +" "+this.userEditForm.controls['lastName'].value,
             [ApplicationConstants.GROUP_ID_KEY] :  this.tokenData[0][ApplicationConstants.GROUP_ID_KEY],
             [ApplicationConstants.URL_TOKENS_LIST_KEY] : this.tokenData[0][ApplicationConstants.URL_TOKENS_LIST_KEY],
             //code added by aishwaryaa for hb-749

            [ApplicationConstants.ROLES]: localStorage.getItem('SuperAdmin')=='true'?[8]:[],
            //code end 
            //  code added by Aishwaryaa for HB-722 
            
            //  code end 
            });
          //   if(localStorage.getItem('SuperAdmin')=='true'){
          //     saPortal.session.push({ [ApplicationConstants.ROLES]: [8]});
          //  }
          //  else{
          //    saPortal.session.push({ [ApplicationConstants.ROLES]: []});
          //  }





          window.localStorage.setItem(ApplicationConstants.SYSTEM_PORTAL, JSON.stringify(saPortal.session));
          this.submitted = false;
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.requestData.message);
          setTimeout(() => {
            this.spinner.hide();
            this.router.navigate(['home']);
          }, 5000); 
        } else {
          // console.log(this.requestData.errors)
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.requestData.errors}`);                     
        }
      });
    } 
  }

   resetData(): void {
    this.ngAfterViewInit();
  }

  fillTextfeildData(){
    if( this.responseData.data != null ){
      this.intlObj.setNumber(this.responseData.data.contact);
      // console.log("---"+this.countryCode);
      // console.log("[[]]]]"+(this.responseData.data.contact).substr(3) + "" +(this.countryCode).length);
      var cont;
      // if(typeof(this.countryCode) !== 'undefined'){
      //  console.log("---if");
      //  cont = (this.responseData.data.contact).substr(((this.countryCode).length + 1));
      //  }
      // else
      //  cont = (this.responseData.data.contact).substr(2);
      cont = this.intlObj.getNumber();
      // console.log("nummmmm"+$("#phoneInput").val());
      this.userEditForm = this.formBuilder.group({
        "firstName": [this.responseData.data.firstName, [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
        "lastName": [this.responseData.data.lastName, [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],        
       // "contact": [$("#phoneInput").val(),[Validators.required]],        
        "email": [this.responseData.data.email, [Validators.pattern(AppRegExConstants.EMAIL)]],   
        "userProfileInfoId": [this.responseData.data.userprofileinfoid, [Validators.required,]],        
       }); 
       this.intlObj.setNumber(this.responseData.data.contact);
       //HB-426 Hevisa Patel 28-01-2021 Start
       $("#phoneInput").focus();
       this.myInput.nativeElement.blur();
       this.resetFlag = true;
       //HB-426 Hevisa Patel 28-01-2021 End


       //this.validateContact(this.responseData.data.contact);
      // console.log("999999"+this.hasError(this.intlObj) + "" + !this.isError + "" );
    }
    
  }


  telInputObject(obj) {
    this.intlObj = obj;
    // console.log("-------"+JSON.stringify(obj));
    this.flag = true;
    this.countryCode = obj.dialCode;
   // obj.setNumber(this.responseData.data.contact);
  }

  onCountryChange(country: any) {
    // console.log("======="+country.dialCode);
    this.countryCode = country.dialCode;
    if(this.flag)
    this.intlObj.setNumber('');
    else
    this.flag = true;
  }

  hasError(obj) {
    this.isError = obj;
    // console.log('hasError: ', obj);
}

getNumber(obj){
  // console.log("*******"+JSON.stringify(obj));
}

test(event : any){
  // console.log("----ttttt");
}
  
validateContact(contact){
  const control = new FormControl(contact, Validators.pattern(AppRegExConstants.ALPHABETS));
   if(control.hasError){
    //  console.log("000000000000000");
   }else{
    //  console.log("llllll");
   }
  //  console.log("ERROr"+control.errors);  
}

checkForError(obj){
  this.resetFlag = false; //HB-426 Hevisa Patel 28-01-2021 
  this.myInput.nativeElement.blur();
  $("#phoneInput").focus();
}

numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    if(charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
       return true;
    else   
       return false;
  }
  return true;

}
/** HB-677 Priya Start */

changePassword(){
  this.router.navigate(['change-password',this.tokenService.getuserProfileId()],{skipLocationChange: true});
}
/** HB-677 Priya End*/
}