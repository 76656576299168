import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router } from '@angular/router';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { environment } from 'projects/system-portal/src/environments/environment';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';

@Component({
  selector: 'app-subscriber-list',
  templateUrl: './subscriber-list.component.html',
  styleUrls: ['./subscriber-list.component.css']
})
export class SubscriberListComponent implements OnInit {

  dataTable: any;
  resData: AppResponse;
  constructor(private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.SUBSCRIBER_LIST).subscribe(res => {
      this.resData = new AppResponse(res);
      setTimeout(() => {
        $('#gaListDataTable').DataTable({
            responsive: true,
            autoWidth: true,
            searching: true,
            // Added by Hema shanker HB-675 on 25/11/2021 Start
          stateSave: true,
          // Added by Hema shanker HB-675 on 25/11/2021 End
            /*"ordering": true,*/
            order: [],
            language: {
              zeroRecords: 'No records found.',
            },
           // Modified by Hema shanker HB-675 on 25/11/2021 Start
          lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
          // Modified by Hema shanker HB-675 on 25/11/2021 End
            pageLength: 5,
            pagingType: $(window).width() < 768 ? "full" : "full_numbers",
            processing: true,
            columnDefs: [{
              // Modified By hema shanker for HB-718 Start 
              targets: 3,
              // Modified By hema shanker for HB-718 End
              orderable: false
            },
            ]
          });         
      }, 200);
      if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });

  }

}
