import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApplicationConstants } from '../utils/ApplicationConstants';
import { TokenStorageService } from './services/token-storage.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,private token: TokenStorageService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.token.getToken()) {
      return true;
    }
    this.router.navigate(['login']);
    return false;
  }
}
