import { Injectable } from '@angular/core';
import { ApplicationConstants } from '../../utils/ApplicationConstants';

@Injectable({
  providedIn: 'root'
})

export class TokenStorageService {
  private roles: Array<string> = [];
  constructor() { }

  public saveUserTokenData(userTokenData: any) {

    const jsonObject = {};
    for (let i = 0; i < userTokenData.urlTokens.length; i++) {
      // Trim the excess whitespace.
      userTokenData.urlTokens[i] = userTokenData.urlTokens[i].replace(/^\s*/, '').replace(/\s*$/, '');
      jsonObject[userTokenData.urlTokens[i]] = userTokenData.urlTokens[i];
   }

    let gaPortal = {
    session: []
   };

    gaPortal.session.push({
     [ApplicationConstants.TOKEN_KEY] :  userTokenData.token,
     [ApplicationConstants.USER_PROFILE_INFO_ID_KEY] :  userTokenData.userProfileInfoId,
     [ApplicationConstants.AUTHORITIES_KEY] :  userTokenData.authorities,
     [ApplicationConstants.EMAIL_KEY] :  userTokenData.email,
     [ApplicationConstants.FULL_NAME_KEY] :  userTokenData.fullName,
     [ApplicationConstants.GROUP_ID_KEY] :  userTokenData.groupId,
     [ApplicationConstants.GROUP_NAME] : userTokenData.groupName,
     [ApplicationConstants.IS_PHONE_VERIFIED] : userTokenData.isphoneverified,
     [ApplicationConstants.URL_TOKENS_LIST_KEY] : jsonObject
    });

    window.localStorage.setItem(ApplicationConstants.GROUP_PORTAL, JSON.stringify(gaPortal.session));
  }

  public getToken(): string {
    if (localStorage.getItem(ApplicationConstants.GROUP_PORTAL)) {
      return JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_PORTAL))[0][ApplicationConstants.TOKEN_KEY];
    }
    return null;
  }

  public getuserProfileId(): string {
    return JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_PORTAL))[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY];
  }

  public getEmail(): string {
    return JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_PORTAL))[0][ApplicationConstants.EMAIL_KEY];
  }

  public getuserName(): string {
    return JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_PORTAL))[0][ApplicationConstants.FULL_NAME_KEY];
  }

  public getAuthorities(): string[] {
    this.roles = [];
    if (JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_PORTAL))[0][ApplicationConstants.FULL_NAME_KEY]) {
      return JSON.parse(localStorage.getItem(ApplicationConstants.AUTHORITIES_KEY))[0][ApplicationConstants.FULL_NAME_KEY].forEach(authority => {
        this.roles.push(authority.authority);
      });
    }
    return this.roles;
  }

  public getUrlTokenByKey(key: string): string {
    let data = {};
    if (JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_PORTAL))[0][ApplicationConstants.URL_TOKENS_LIST_KEY]) {
      data  = JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_PORTAL))[0][ApplicationConstants.URL_TOKENS_LIST_KEY];
    }
    return data[key];
  }

  public getGroupId(): string {
    return JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_PORTAL))[0][ApplicationConstants.GROUP_ID_KEY];
  }

  public getGroupName(): string {
    return JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_PORTAL))[0][ApplicationConstants.GROUP_NAME];
  }

  public clearSession() {
    window.localStorage.clear();
  }
}
