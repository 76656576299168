import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import * as XLSX from 'xlsx';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/system-portal/src/environments/environment';
import { Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from '../../utils/common-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
const helper = new JwtHelperService();

@Component({
  selector: 'app-audit-payment',
  templateUrl: './audit-payment.component.html',
  styleUrls: ['./audit-payment.component.css']
})
export class AuditPaymentComponent implements OnInit {

  reportFormGroup: FormGroup;
  groupresData: any;
  tableresData:any;

  rongHo: boolean;
  maxTodate: any = new Date();
  public minDate = new Date(2019, 12, 1, 0, 0);
  errorss: any = { isError: false, errorMessage: '' };
  isValidDate: any;
  public show = false;
  groupNameList = [];
  groupNameLength;
  gn:any;
  totalamount:number=0;
  filteredGroup: Observable<String[]>;
  constructor(private httpService: HttpTransactionService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private tokenService: TokenStorageService) { }

  ngOnInit() {

    this.spinner.show();
    this.reportFormGroup = this.formBuilder.group({
      'groupMasterId': ['', [Validators.required]],
      'groupName': ['', [Validators.required]],
      'startDate': ['', [Validators.required]],
      'endDate': [, [Validators.required]],
    });
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.SUBSCRIBER_LIST).subscribe(res => {
      // console.log(res)
      this.groupresData = new AppResponse(res);
      this.groupNameList=this.groupresData.data;
      this.groupNameLength=this.groupNameList.length;
    //  console.log(this.groupresData)
      if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });

    this.filteredGroup = this.reportFormGroup.controls['groupName'].valueChanges
    .pipe(
      startWith(''),
      map(value => value.length >= 3 ? this._filterForGroups(value) : [])
  );

  if (localStorage.getItem("reportFormGroup") != null) {
    this.reportFormGroup.controls['groupMasterId'].setValue(JSON.parse(localStorage.getItem("reportFormGroup")).groupMasterId);
    this.reportFormGroup.controls['groupName'].setValue(JSON.parse(localStorage.getItem("reportFormGroup")).groupName);
    this.reportFormGroup.controls['startDate'].setValue(JSON.parse(localStorage.getItem("reportFormGroup")).startDate);
    this.reportFormGroup.controls['endDate'].setValue(JSON.parse(localStorage.getItem("reportFormGroup")).endDate);
  }
  if (localStorage.getItem("requestReportData") != null) {
     this.tableresData = new AppResponse(JSON.parse( localStorage.getItem("requestReportData")) );
    //  console.log(this.resData)
  }
  }

  ngAfterViewInit(): void {
    this.TableInitialiseexport();
    this.spinner.hide();
  }


  get report() { return this.reportFormGroup.controls; }



  onKeyUpForGroupName(event: any) {
    // console.log(event.target.value);
    // console.log(this.groupNameList.length)
    if( event.target.value.length <= 0 ){
      this.rongHo = false;
      // this.homeAddress.setErrors({ 'required': true });
    }else{
      this.rongHo = false;
      for (var i = 0; i < this.groupNameLength; i++) {
        if (this.groupNameList[i].groupName == event.target.value) {

          this.reportFormGroup.controls['groupMasterId'].setValue(this.groupNameList[i].groupMasterId);
          this.reportFormGroup.controls['groupName'].setValue(this.groupNameList[i].groupName);
          // this.homeAddress.setErrors(null);
          // console.log(this.reportFormGroup.value)
          this.rongHo = false;
        } else {
          this.reportFormGroup.controls['groupMasterId'].setValue(null);
          // this.reportFormGroup.controls['HoDetails'].setValue(null);
          // this.homeAddress.setErrors({ invalid: true });
          this.rongHo = true;

        }
      }
    }
  };

  displayFn(home: any): string {
    // console.log(home)
    if (home == undefined) {
      //this.homeAddress.setErrors({ invalid: true })
      return ;
    } else {
      return home;
    }
  }

  selectGroupNameChangeHandler(selectedHome) {
    // console.log(selectedHome)
    for (var i = 0; i < this.groupNameList.length; i++) {

      if (this.groupNameList[i].groupMasterId == selectedHome.groupMasterId) {
        // this.reportFormGroup.controls['groupid'].setValue(this.groupNameList[i].groupMasterId);
        this.reportFormGroup.controls['groupMasterId'].setValue(selectedHome.groupMasterId);
        this.reportFormGroup.controls['groupName'].setValue(selectedHome.groupName);
      //  console.log(this.reportFormGroup.value);

      }
    }
  }

  private _filterForGroups(value: string): String[] {
    // modified by aishwaryaa for HB-907

    const filterValue = value.toLowerCase();
    // console.log(filterValue)
    this.gn = filterValue;
    return this.groupNameList.filter(option => option.groupName.toLowerCase().includes(filterValue));
    return this.gn.filter(option=>option.groupName.toLowerCase().includes(filterValue));
  }


  onSubmit() {

    this.totalamount=0;
  // this.TableInitialiseexport();
    $("#messageBox").empty();
    // this.spinner.show();
    // console.log(JSON.stringify(this.reportFormGroup.value));

    if (false) {
      // console.log("invalid")
      // this.spinner.hide();
      return;
    } else {


      console.log(this.reportFormGroup.value);
      this.isValidDate = this.validateDates(this.reportFormGroup.controls['startDate'].value, this.reportFormGroup.controls['endDate'].value);
      if (this.isValidDate) {
        this.httpService.save(this.reportFormGroup.value, environment.BASE_URL + ApplicationURIConstants.GET_PAYMENT_REPORT)
          .subscribe((res) => {

            if (res != undefined) {
              this.tableresData = new AppResponse(res);

              // console.log("Response ->>" + JSON.stringify(res));
              if (res.status == 200) {
                console.log('====================================');
                console.log("hi "+this.tableresData.data.length);
                console.log('====================================');
                if(this.tableresData.data.length>0){
                  $('#reportTable').dataTable().fnDestroy();
                  setTimeout(() => {
              
                    this.TableInitialiseexport();
                    this.spinner.hide();
              
                  }, 2000);



                }

                if (this.tableresData.data.length == 0) {

                  // this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, "No Record found");

                  setTimeout(() => {
                       $('#reportTable').dataTable()



                     },2000 );




              }
                for(let i =0 ;i<=this.tableresData.data.length;i++){

                  this.totalamount=this.totalamount+this.tableresData.data[i].amount;

                }


                console.log(this.totalamount);


                // // $('#reportTable').dataTable().fnDestroy();
                // setTimeout(() => {

                //   this.TableInitialiseexport();
                //   this.spinner.hide();

                // }, 2000);  //3s

              } else if (res.status == 401) {
                this.spinner.hide();
                this.router.navigate(['logout']);
              } else {
                $('#reportTable').dataTable().fnDestroy();
                this.spinner.hide();
                this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${res.message}`);

              }
            } else {
              this.spinner.hide();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, ApplicationConstants.INTERNAL_SEREVR_ERROR);
            }
          });


      } else {
        this.spinner.hide();
      }
    }
  }

  TableInitialiseexport() {
    $('#reportTable').DataTable({
      responsive: true,
      autoWidth: true,
      searching: true,

      order: [],
      language: {
        zeroRecords: 'No records found'
      },
      lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
      pageLength: 5,
      pagingType: $(window).width() < 768 ? "full" : "full_numbers",
      processing: true,
      columnDefs: [
      ]
    });
  }

  // TableInitialiseexport() {
  //   $('#reportTable').DataTable({
  //     responsive: true,
  //     autoWidth: true,
  //     searching: true,

  //     order: [],
  //     language: {
  //       zeroRecords: 'No records found.',
  //     },
  //     lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
  //     pageLength: 7,
  //     pagingType: $(window).width() < 768 ? "full" : "full_numbers",
  //     processing: true,
  //     columnDefs: [
  //     ]
  //   });
  // }
  Table2() {
    $('#reportTable2').DataTable({
      responsive: true,
      autoWidth: true,
      searching: true,

      order: [],
      language: {
        zeroRecords: 'No records found.',
      },
      lengthMenu: [[5, 10, 15, 20, -1], [5, 10, 15, 20, 'All']],
      pageLength: 5,
      pagingType: $(window).width() < 768 ? "full" : "full_numbers",
      processing: true,
      columnDefs: [
      ]
    });
  }


  /*name of the excel-file which will be downloaded. */
  fileName = 'DueReportExcelSheet.xlsx';
  exportexcel(): void {
    /* table id is passed over here */
    $('#reportTable').dataTable().fnDestroy();
    setTimeout(() => {

      this.TableInitialiseexport();
      this.spinner.hide();

    }, 2000);
    let element = document.getElementById('reportTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    $('row c[r*="3"]', ws).attr('s', '25');
    //console.log(wb);
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate != null && eDate != null) && (eDate) < (sDate)) {
      this.show = true;
      this.errorss = { isError: true, errorMessage: 'To Date should be greater then From Date.' };
      setTimeout(function () {
        this.show = false;
      }.bind(this), 6000);
      this.isValidDate = false;
    } else if ((sDate != null && eDate != null) && (sDate) > (eDate)) {
      this.show = true;
      this.errorss = { isError: true, errorMessage: 'From Date should be less then To Date.' };
      setTimeout(function () {
        this.show = false;
      }.bind(this), 6000);
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
// added by gopal for HB-584 on 27/12/2021 start
  saveData() {
    if (this.tableresData.status == 200) {

      localStorage.setItem("reportFormGroup", JSON.stringify(this.reportFormGroup.value));
      localStorage.setItem("requestReportData", JSON.stringify(this.tableresData));
    }
  }

}
