import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { TokenStorageService } from '../../../auth-token/services/token-storage.service';
import { ApplicationConstants } from '../../../utils/ApplicationConstants';

  @Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
  })
  export class HeaderComponent implements OnInit {
	userRole : string = "Super Admin";
    @Input() headerData: any;
    public groupConfiges:any;

    constructor(private router: Router, private location: Location, private tokenService: TokenStorageService) {
    }
    ngOnInit() {
		if(this.tokenService.getRoles().length == 0){
			this.userRole = "System Admin";
		}
      setTimeout(()=>{
        if(this.headerData === undefined || this.headerData === null ){
          this.groupConfiges= JSON.parse(localStorage.getItem(ApplicationConstants.GROUP_CONFIGS_KEY));
        } else {
          this.groupConfiges= this.headerData;
        }
      }, 500);

    }

    doLogout() {
      localStorage.removeItem(ApplicationConstants.GROUP_CONFIGS_KEY);
      this.tokenService.clearSession();
      this.router.navigate(['login']);
    }
    changePassword(){
      this.router.navigate(['change-password',this.tokenService.getuserProfileId()]);
    }
  }
