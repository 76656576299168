import { Component, OnInit } from '@angular/core';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { Router } from '@angular/router';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { environment } from 'projects/system-portal/src/environments/environment';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';

@Component({
  selector: 'app-system-subscription-history',
  templateUrl: './system-subscription-history.component.html',
  styleUrls: ['./system-subscription-history.component.css']
})
export class SystemSubscriptionHistoryComponent implements OnInit {
  dataTable: any;
  resData: AppResponse;
  constructor(private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService) { }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    // tslint:disable-next-line: max-line-length
    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.SUBSCRIPTION_HISTORY).subscribe(res => {
      this.resData = new AppResponse(res);
      console.log(this.resData);
      
      setTimeout(() => {
        $('#subscriptionhistory').DataTable({
            responsive: true,
            autoWidth: true,
            searching: true,
          stateSave: true,
            order: [],
            language: {
              zeroRecords: 'No records found.',
            },
          lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
            pageLength: 5,
            pagingType: $(window).width() < 768 ? "full" : "full_numbers",
            processing: true,
            columnDefs: [{
              targets: 3,
              orderable: false
            },
            ]
          });         
      }, 200);
      if (res.status === 401) {
        //401 token related issue
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        //403 URL not accessible
        this.router.navigate(['home']);
      }
    });

  }
}
