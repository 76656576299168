import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../../utils/common-service';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { AppRegExConstants } from 'src/app/utils/AppRegExConstants';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { environment } from 'projects/system-portal/src/environments/environment';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-system-admins-list',
  templateUrl: './system-admins-list.component.html',
  styleUrls: ['./system-admins-list.component.css']
})
export class SystemAdminsListComponent implements OnInit {

  dataTable: any;

  systemAdminListResponse: AppResponse;
  requestData: AppResponse;
  reqSentOTPResponse: AppResponse;

  systemAdminForm: FormGroup;
  systemAdminResetPwdEmailForm: FormGroup;
  submitted: boolean;

  intlObj: any;
  contactNum: any;
  isError: boolean = true;
  countryCode: any;
  countryCodeLength: any;
  flag: boolean = false;
  resetFlag: boolean = false;
  currentSADetail: any = [];

  resetPwdSentOTP: boolean = false;
  resetPwdSentOTPSubmit: boolean = false;
  resetPwdSentLinkSubmit: boolean = false;
  currSystemAdminStatus: boolean = false;

  private updateSystemAdmin = environment.BASE_URL + ApplicationURIConstants.EDIT_USER_PROFILE;
  private addSystemAdmin = environment.BASE_URL + ApplicationURIConstants.ADD_SYSTEMADMIN;
  private deleteSystemAdmin = environment.BASE_URL + ApplicationURIConstants.DELETE_SYSTEMADMIN;
  private deactivateSystemAdmin = environment.BASE_URL + ApplicationURIConstants.DEACTIVATE_SYSTEMADMIN;
  private activateSystemAdmin = environment.BASE_URL + ApplicationURIConstants.ACTIVATE_SYSTEMADMIN;
  private getSystemAdminsList = environment.BASE_URL + ApplicationURIConstants.GET_SYSTEMADMINS_LIST;

  private sendOTPtoEmailOfSystemAdmin = environment.BASE_URL + ApplicationURIConstants.SEND_OTP_TO_EMAIL_OF_SYSTEMADMIN;
  private validateOTPToSendResetPwdLink = environment.BASE_URL + ApplicationURIConstants.VALIDATE_OTP_TOSEND_RESETPWDLINK_SA;

  @ViewChild('phoneInpt2', { static: false }) phoneInpt2: ElementRef;
  @ViewChild('myInput', { static: true }) myInput: ElementRef;

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private spinner: NgxSpinnerService, private commonService: CommonService) {
  }

  ngOnInit() {
    this.systemAdminForm = this.formBuilder.group({
      "firstName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "lastName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "email": ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
      "contact": ['', [Validators.required]],
      "userProfileInfoId": ['']
    });
    this.systemAdminResetPwdEmailForm = this.formBuilder.group({
      "email": ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
    });
    $('.systemAdminContainer').hide();
  }

  ngAfterViewInit(): void {
    $('#saListDataTable').dataTable().fnDestroy();
    this.httpService.get(this.getSystemAdminsList).subscribe(res => {
      this.systemAdminListResponse = new AppResponse(res);
      setTimeout(() => {
        $('#saListDataTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
          // Added by Hema shanker HB-675 on 25/11/2021 Start
          stateSave: true,
          // Added by Hema shanker HB-675 on 25/11/2021 End
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          // Modified by Hema shanker HB-675 on 25/11/2021 Start
          lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
          // Modified by Hema shanker HB-675 on 25/11/2021 End
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 4,
            orderable: false
          },
          ]
        });
      }, 200);
      if (res.status === 401) {
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        this.router.navigate(['home']);
      }
    });

  }

  /** HB-678 Priya Start */
  addSystemAdminFrom() {
    this.systemAdminForm.reset();
    // console.log(JSON.stringify(this.systemAdminForm.value));
    $('.addBtnRow').show();
    $('.updateBtnRow').hide();
    $("#phoneInput").focus();
    this.myInput.nativeElement.blur();
    this.resetFlag = true;
    $('.systemAdminContainer').show();
    /* scroll down to form */
    document.getElementById("systemAdminDiv").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
  /** HB-678 Priya End */
  /** HB-679 Priya Start -  Edit Group Admin to populate form */
 
  /** HB-679 Priya End - */

  // convenience getter for easy access to form fields
  get f() { return this.systemAdminForm.controls; }

  get resetForm() { return this.systemAdminResetPwdEmailForm.controls; }

  //contact field fn

  /** HB-679,HB-678 Priya start */
  onSystemAdminSubmit() {
    this.spinner.show();

    //to identify which field is invalid
    const controls = this.systemAdminForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        // console.log("err::::- " + name);
      }
    }
    let contactnumber = this.intlObj.getNumber();
    // console.log('contact on submit' + "::" + contactnumber + "::" + this.intlObj.getNumber())
    this.systemAdminForm.controls['contact'].setValue(contactnumber);
    this.submitted = true;

    if (this.systemAdminForm.invalid || !this.isError) {
      this.spinner.hide();
      return;
    } else {
      var formData = new FormData();
      formData.append("contact", this.intlObj.getNumber());
      formData.append("firstName", this.systemAdminForm.controls['firstName'].value);
      formData.append("lastName", this.systemAdminForm.controls['lastName'].value);
      formData.append("email", this.systemAdminForm.controls['email'].value);

      if (this.systemAdminForm.controls['userProfileInfoId'].value != null) { //HB-679 Priya start

        //passing systemAdminid in edit
        formData.append("userProfileInfoId", this.systemAdminForm.controls['userProfileInfoId'].value);
        this.httpService.save(formData, this.updateSystemAdmin).subscribe((res) => {
          this.requestData = new AppResponse(res);
          if (this.requestData.status == 200) {
            this.submitted = false;
            this.systemAdminForm.reset();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.requestData.message);
            $('.systemAdminContainer').hide();
            setTimeout(() => {
              this.ngAfterViewInit();
            }, 3000);
          } else {
            // console.log(this.requestData.errors)
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.requestData.errors}`);
          }
        });
      }//HB-679 Priya End
      else {  //HB-678 Priya start

        this.httpService.save(formData, this.addSystemAdmin).subscribe((resp) => {

          this.requestData = new AppResponse(resp);
          if (this.requestData.status == 200) {
            this.submitted = false;
            this.systemAdminForm.reset();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.requestData.message);
            this.spinner.hide();
            $('.systemAdminContainer').hide();
            setTimeout(() => {
              this.ngAfterViewInit();
            }, 3000);
          } else {
            // console.log(this.requestData.errors)
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.requestData.errors}`);
          }
        });

      }   //HB-678 Priya End     
    }
  }
  populateSystemAdmin(data) {

    this.currentSADetail = data;
    this.currSystemAdminStatus = false;
    if (data.status == "true") {
      this.currSystemAdminStatus = true;
    }

    // console.log("Curr SA Status :" + this.currSystemAdminStatus);
    //alert(data.firstName+'::'+data.lastName+'::'+data.email+':::'+data.userprofileinfoid+':::'+data.status)
    this.intlObj.setNumber(data.contact);
    var cont;
    // if(typeof(this.countryCode) !== 'undefined'){
    //  console.log("---if");
    //  cont = (this.responseData.data.contact).substr(((this.countryCode).length + 1));
    //  }
    // else
    //  cont = (this.responseData.data.contact).substr(2);
    cont = this.intlObj.getNumber();
    this.systemAdminForm = this.formBuilder.group({
      "firstName": [data.firstName, [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "lastName": [data.lastName, [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS_WITH_SPACE)]],
      "email": [data.email, [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
      "contact": [data.contact, [Validators.required]],
      "userProfileInfoId": [data.userprofileinfoid],
    });
    this.intlObj.setNumber(data.contact);
    //HB-426 Hevisa Patel 28-01-2021 Start
    $("#phoneInput").focus();
    this.myInput.nativeElement.blur();
    this.resetFlag = true;
    $('.addBtnRow').hide();
    $('.updateBtnRow').show();
    $('.systemAdminContainer').show();
    /* scroll down to form */
    document.getElementById("systemAdminDiv").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
        return true;
      else
        return false;
    }
    return true;

  }

  checkForError(obj) {

    this.resetFlag = false; //HB-426 Hevisa Patel 28-01-2021 
    this.myInput.nativeElement.blur();
    $("#phoneInput").focus();
  }

  telInputObject(obj) {
    this.intlObj = obj;
    // console.log("-------"+JSON.stringify(obj));
    this.flag = true;
    this.countryCode = obj.dialCode;
   // obj.setNumber(this.responseData.data.contact);
  }

  onCountryChange(country: any) {
    // console.log("======="+country.dialCode);
    this.countryCode = country.dialCode;
    if(this.flag)
    this.intlObj.setNumber('');
    else
    this.flag = true;
  }
  hasError(obj) {
    this.isError = obj;
    // console.log('hasError: ', obj);
}

  getNumber(obj) {
    // console.log("getNumber::" + JSON.stringify(obj));
  }
  /** HB-679,HB-678 Priya end */

  //Reset Form
  resetSystemAdminForm() {
    this.systemAdminForm.reset();
  }
  //delete Group Admin
  deleteSystemAdminData() {
    if (confirm("Are you confirm to delete " + this.currentSADetail.firstName)) {
      let userProfileInfoId = this.systemAdminForm.controls['userProfileInfoId'].value;
      this.httpService.update(userProfileInfoId, this.deleteSystemAdmin + '/' + userProfileInfoId).subscribe((res) => {
        this.requestData = new AppResponse(res);
        if (this.requestData.status == 200) {
          this.submitted = false;
          this.systemAdminForm.reset();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.requestData.message);
          $('.systemAdminContainer').hide();
          setTimeout(() => {
            this.ngAfterViewInit();
          }, 3000);
        } else {
          // console.log(this.requestData.errors)
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.requestData.errors}`);
        }
      });
    }
  }
  //deactivate group  admin
  deactivateSystemAdminData() {
    if (confirm("Are you confirm to deactivate " + this.currentSADetail.firstName)) {
      let userProfileInfoId = this.systemAdminForm.controls['userProfileInfoId'].value;
      this.httpService.update(userProfileInfoId, this.deactivateSystemAdmin + '/' + userProfileInfoId).subscribe((res) => {
        this.requestData = new AppResponse(res);
        if (this.requestData.status == 200) {
          this.submitted = false;
          this.systemAdminForm.reset();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.requestData.message);
          $('.systemAdminContainer').hide();
          setTimeout(() => {
            this.ngAfterViewInit();
          }, 3000);
        } else {
          // console.log(this.requestData.errors)
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.requestData.errors}`);
        }
      });
    }
  }

  //deactivate group  admin
  activateSystemAdminData() {
    if (confirm("Are you confirm to activate " + this.currentSADetail.firstName)) {
      let userProfileInfoId = this.systemAdminForm.controls['userProfileInfoId'].value;
      this.httpService.update(userProfileInfoId, this.activateSystemAdmin + '/' + userProfileInfoId).subscribe((res) => {
        this.requestData = new AppResponse(res);
        if (this.requestData.status == 200) {
          this.submitted = false;
          this.systemAdminForm.reset();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.requestData.message);
          $('.systemAdminContainer').hide();
          setTimeout(() => {
            this.ngAfterViewInit();
          }, 3000);
        } else {
          // console.log(this.requestData.errors)
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.requestData.errors}`);
        }
      });
    }
  }

  //reset Pwd - send OTP to email
  sendOTPtoEmail() {
    this.resetPwdSentOTPSubmit = true;
    var formData = new FormData();
    formData.append("userProfileInfoId", this.currentSADetail.userProfileInfoId);
    formData.append("email", this.currentSADetail.email);
    formData.append("firstName", this.currentSADetail.firstName);
    formData.append("lastName", this.currentSADetail.lastName);
    formData.append("contact", this.currentSADetail.contact);

    this.httpService.update(formData, this.sendOTPtoEmailOfSystemAdmin).subscribe((res) => {

      this.reqSentOTPResponse = new AppResponse(res);
      if (this.reqSentOTPResponse.status == 200) {
        this.resetPwdSentOTP = true;
        this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.SUCCESS_LABLE, this.reqSentOTPResponse.message);
        // setTimeout(() => {
        //   $('#systemAdminResetPwd').hide();
        //   $('.systemAdminContainer').hide();

        // }, 3000);
      } else {
        // console.log(this.reqSentOTPResponse.errors)
        this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.DANGER_LABLE, `${this.reqSentOTPResponse.errors}`);
      }



    });
  }

  populateResetPwdForm() {
    this.systemAdminResetPwdEmailForm.controls['email'].setValue(this.currentSADetail.email);
  }

  sendResetPwdLink() {
    this.resetPwdSentOTPSubmit = true;
    var formData = new FormData();
    formData.append("userProfileInfoId", this.currentSADetail.userProfileInfoId);
    formData.append("email", this.currentSADetail.email);
    formData.append("firstName", this.currentSADetail.firstName);
    formData.append("lastName", this.currentSADetail.lastName);
    formData.append("contact", this.currentSADetail.contact);
    formData.append("otp", $('#otp').prop('value'));

    //alert( $('#otp').prop('value'))

    this.httpService.update(formData, this.validateOTPToSendResetPwdLink).subscribe((res) => {

      this.reqSentOTPResponse = new AppResponse(res);
      console.log("aishwaryaaa" + JSON.stringify(this.reqSentOTPResponse) ) ;
      
      if (this.reqSentOTPResponse.status == 200) {

        this.resetPwdSentOTP = true;
        this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.SUCCESS_LABLE, this.reqSentOTPResponse.message);
        // setTimeout(() => {
        //   $('#systemAdminResetPwd').hide();
        //   $('.systemAdminContainer').hide();
        //   // this.ngAfterViewInit();
        //   location.reload();
        // }, 3000);
      } else {
        // console.log(this.reqSentOTPResponse.errors)
        this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.DANGER_LABLE, `${this.reqSentOTPResponse.message}`);
      }



    });

  }
  closeResetPwdModal() {
    $('#systemAdminResetPwd').hide();
  }


}
