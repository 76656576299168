import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CommonService } from '../../utils/common-service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { AppRegExConstants } from 'projects/system-portal/src/app/utils/AppRegExConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { environment } from 'projects/system-portal/src/environments/environment';
import { TokenStorageService } from 'projects/group-portal/src/app/auth-token/services/token-storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as internal from 'assert';
declare var $: any;

@Component({
  selector: 'app-deactivate-subscriber',
  templateUrl: './deactivate-subscriber.component.html',
  styleUrls: ['./deactivate-subscriber.component.css']
})
export class DeactivateSubscriberComponent implements OnInit {

  @ViewChild('myInput', { static: false }) myInput: ElementRef; 
  deactivateSubscriberForm: FormGroup; 
  groupMasterId: string; 
  resData: AppResponse;  
  appResponse: AppResponse;
  gaResponse: AppResponse;
  subscriptionResponse: AppResponse;
  submitted = false;
  ddate:any;
  intlObj : any;
  contactNum : any;
  isError : boolean = true;
  countryCode : any;
  countryCodeLength : any;
  flag : boolean = false; 
  subscriberStatus = false;
  addedGA = [];
  deactivateDropDownArr = [];
  
  resetFlag : boolean = false;
  
  public subscriptionPlanYear :any;  

  public startDate: Date;
  public endDate : Date;
  public lastDateofMonth : Date;
  public formationDate : Date;
  public tempFormationDate;
  public tempStartDate : Date;
  public tempEndDate : Date; 
  public deactivateDate :  Date;
  public min = new Date(2019, 12, 1, 0, 0);
  public max = new Date();
  public minLeaseEndDate = new Date(2019, 12, 1, 0, 0);
  public minLeaseEndDateTemp ;

  private deactivateSubscriber = environment.BASE_URL + ApplicationURIConstants.DEACTIVATE_SUBSCRIBER;

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private httpService: HttpTransactionService, private router: Router, private commonService: CommonService, private tokenService: TokenStorageService, private spinner: NgxSpinnerService, private datePipe: DatePipe) { }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      this.groupMasterId = params.id;
    });

    this.deactivateSubscriberForm = this.formBuilder.group({
      "groupName": ['', [Validators.required, Validators.pattern(AppRegExConstants.GROUP_NAME)]],
      "groupRegistrationNum": ['', [Validators.required]],
      "subscriptionPlanType":['', [Validators.required]],
      "houseCount": ['', [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)]],
      "specialDiscount":['', [Validators.required]],      
      "deactivateDate":['', [Validators.required]],
      "endDate":['', [Validators.required]], 
      "addressLine1": ['', Validators.required],
      "country": ['', [ Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "zipCode": ['', [ Validators.pattern(AppRegExConstants.ALPHABETS_DIGITS_ONLY)]],
      "contact":['', Validators.required],
      "remarks":['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.deactivateSubscriberForm.controls; }

  onSubmit() {

    this.submitted = true;
    if(this.intlObj.getNumber()){
      this.deactivateSubscriberForm.controls['contact'].setValue(this.intlObj.getNumber());
    }
    // console.log("FORM:" + JSON.stringify(this.deactivateSubscriberForm.value));
    for (let el in this.deactivateSubscriberForm.controls) {
          if (this.deactivateSubscriberForm.controls[el].errors) {
            // console.log("Invalid :::"+el+"::::")
          }
    } 
    if (this.deactivateSubscriberForm.invalid) {
      return;
    } else {
      
      var formData = new FormData();
      formData.append("groupMasterId", this.groupMasterId);
      formData.append("groupName",this.deactivateSubscriberForm.controls['groupName'].value.trim());
      formData.append("groupRegistrationNum",this.deactivateSubscriberForm.controls['groupRegistrationNum'].value);      
      formData.append("subscriptionPlanType",this.deactivateSubscriberForm.controls['subscriptionPlanType'].value);
      formData.append("houseCount",this.deactivateSubscriberForm.controls['houseCount'].value);
      formData.append("addressLine1",this.deactivateSubscriberForm.controls['addressLine1'].value);
      formData.append("zipCode",this.deactivateSubscriberForm.controls['zipCode'].value);      
      formData.append("specialDiscount",this.deactivateSubscriberForm.controls['specialDiscount'].value);      
      formData.append("country",this.deactivateSubscriberForm.controls['country'].value);     
      formData.append("deactivateDate",this.deactivateSubscriberForm.controls['deactivateDate'].value);      
      formData.append("contact",this.appResponse.data.contact);
      formData.append("endDate",this.deactivateSubscriberForm.controls['endDate'].value);
      formData.append("remarks",this.deactivateSubscriberForm.controls['remarks'].value);
      formData.append("status", '0');
      formData.append(
        "actions",
        "Deactivated"
      );
      
      //alert(this.intlObj.getNumber());
      // console.log("FORM:" + JSON.stringify(formData));

      this.httpService.update(formData, this.deactivateSubscriber)
        .subscribe((res) => {
          this.resData = new AppResponse(res);
          if (res.status == 200) {
              this.submitted = false;
              this.deactivateSubscriberForm.reset();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE,`${this.resData.message}`);
              setTimeout(() => {
                this.router.navigate(['subscriber-list']);
                //below time set added by aishwaryaa for HB-631 30/12/2021
            }, 2000);

          } else {
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.resData.errors}`);
          }
        });
    }
  }

  //contact field fn
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if(charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
         return true;
      else   
         return false;
    }
    return true;
  
  }

  checkForError(obj){

    this.myInput.nativeElement.blur();
    $("#phoneInput").focus();
  }

  telInputObject(obj) {
    this.intlObj = obj;
    this.flag = true;
    obj.setNumber(this.appResponse.data.contact);
  }

  onCountryChange(country: any) {
    this.countryCode = country.dialCode;
    if(this.flag)
      this.intlObj.setNumber('');
    else
      this.flag = true;
    
  }
  hasError(obj) {
    this.isError = obj;
    // console.log('hasError: ', obj);
  }

  getNumber(obj){
    // console.log("*******"+JSON.stringify(obj));
  }

  //Endate
  changeEndDate(data){    
    if(this.deactivateSubscriberForm.controls['subscriptionPlanTerm'].value != "")    {
      if(this.deactivateSubscriberForm.controls['startDate'].value != null){
        if(this.deactivateSubscriberForm.controls['subscriptionPlanTerm'].value =="1year"){
          this.subscriptionPlanYear = 1;
        } else if(this.deactivateSubscriberForm.controls['subscriptionPlanTerm'].value =="2year"){
          this.subscriptionPlanYear = 2;
        }else if(this.deactivateSubscriberForm.controls['subscriptionPlanTerm'].value =="3year"){
          this.subscriptionPlanYear = 3;
        }      
        this.tempEndDate = new Date(this.deactivateSubscriberForm.controls['startDate'].value);
        this.endDate = new Date(this.tempEndDate.getFullYear() + this.subscriptionPlanYear, this.tempEndDate.getMonth(), this.tempEndDate.getDate());
        this.deactivateSubscriberForm.controls['endDate'].setValue(this.endDate);
      }
      else{
        this.deactivateSubscriberForm.controls['startDate'].setErrors({ required: true });
      }
    }
    else{
      this.deactivateSubscriberForm.controls['subscriptionPlanTerm'].setErrors({ required: true });
    }
  }
//Editing Groupadmins
  
  groupAdmins(): FormArray {
    return this.deactivateSubscriberForm.get("groupAdmins") as FormArray;
  }

  newGroupAdmin(): FormGroup {
    return this.formBuilder.group({
      "firstName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "lastName": ['', [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)]],
      "email": ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]]
    });
  }

  addGAEmail() {
    this.groupAdmins().push(this.newGroupAdmin());
  }

  removeGAEmail(ga) {
    this.groupAdmins().removeAt(ga);
  }

  //Edit Form
  ngAfterViewInit() {
      this.spinner.show();
      // console.log("-----");

      this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_SUBSCRIBER + '/' + this.groupMasterId).subscribe(data => {
        this.appResponse = new AppResponse(data);
        this.ddate=this.appResponse.data.scheduledDeactivateDate;
        this.spinner.hide();
        // console.log(this.appResponse);
        setTimeout(() => {
          //below time set added by aishwaryaa for HB-631 30/12/2021
        }, 2000);
        if( data.status === 200 ){
            
            this.populateDeactivateSubscriber();
        }  else if (data.status === 401) {
            //401 token related issue
            this.tokenService.clearSession();
            this.router.navigate(['login']);
      } else if (data.status === 403) {
            //403 URL not accessible
            this.router.navigate(['home']);
      }      
    });

     //get ga details
     this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_GROUPADMIN_DETAILS + '/' + this.groupMasterId).subscribe(data => {
          this.gaResponse = new AppResponse(data);
          setTimeout(() => {
            //below time set added by aishwaryaa for HB-631 30/12/2021
          }, 2000);
          if( data.status === 200 ){
          }  else if (data.status === 401) {
              //401 token related issue
              this.tokenService.clearSession();
              this.router.navigate(['login']);
          } else if (data.status === 403) {
              //403 URL not accessible
              this.router.navigate(['home']);
          } 
      });
  }
  populateDeactivateSubscriber(){

      if( this.appResponse.data != null ){
        if(this.appResponse.data.startDate != null){
          this.tempStartDate = new Date(this.appResponse.data.startDate);
          this.startDate = new Date(this.tempStartDate.getFullYear(), this.tempStartDate.getMonth(), this.tempStartDate.getDate());
          this.minLeaseEndDateTemp = this.startDate;
          var datetemp = new Date(this.minLeaseEndDateTemp.getFullYear(),this.minLeaseEndDateTemp.getMonth(),this.minLeaseEndDateTemp.getDate()+1);
          this.minLeaseEndDate = datetemp;
        }
        if(this.appResponse.data.endDate != null){
          this.tempEndDate = new Date(this.appResponse.data.endDate);
          this.endDate = new Date(this.tempEndDate.getFullYear(), this.tempEndDate.getMonth(), this.tempEndDate.getDate());
        }

        if(this.appResponse.data.formationDate != null){
          this.tempFormationDate = new Date(this.appResponse.data.formationDate);
          this.formationDate = new Date(this.tempFormationDate.getFullYear(), this.tempFormationDate.getMonth(), this.tempFormationDate.getDate());        
        }

        
        this.deactivateSubscriberForm = this.formBuilder.group({
          "groupName": [this.appResponse.data.groupName, [Validators.required, Validators.pattern(AppRegExConstants.GROUP_NAME)]],
          "groupRegistrationNum": [this.appResponse.data.groupRegistrationNum, [Validators.required]],
          "subscriptionPlanType":[this.appResponse.data.subscriptionPlanType, [Validators.required]],
          "houseCount": [this.appResponse.data.houseCount, [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)]],
         "specialDiscount":[this.appResponse.data.specialDiscount, [Validators.required]],      
         "endDate":[this.endDate, [Validators.required]], 
          "addressLine1": [this.appResponse.data.addressLine1, Validators.required],
          "country": [this.appResponse.data.country, [ Validators.pattern(AppRegExConstants.ALPHABETS)]],
          "zipCode": [this.appResponse.data.zipCode, [ Validators.pattern(AppRegExConstants.ALPHABETS_DIGITS_ONLY)]],
          "deactivateDate" : ['', Validators.required],
          "remarks" :[this.appResponse.data.remarks, Validators.required]
        });
        
        if(this.appResponse.data.status == 'true'){
          
          this.subscriberStatus = true;
        }      
        //Contact 
        if(this.appResponse.data.contact !=''){
          this.intlObj.setNumber(this.appResponse.data.contact);
         // $("#phoneInput").focus().val(this.appResponse.data.contact);
         $("#phoneInput").focus();
          this.myInput.nativeElement.blur();
          this.resetFlag = true;
        }
        this.DeactivateDropDwonPopulate(this.deactivateSubscriberForm.controls['endDate'].value);
       //DeactivateDropDownPopulate
        
    }
  }
  DeactivateDropDwonPopulate(subEndDate){
    this.tempStartDate = new Date();
    this.startDate = new Date(this.tempStartDate.getFullYear(), this.tempStartDate.getMonth(), this.tempStartDate.getDate());
    this.tempEndDate = new Date(subEndDate);
    this.endDate = new Date(this.tempEndDate.getFullYear(), this.tempEndDate.getMonth(), this.tempEndDate.getDate());

   
        let startYear  = this.startDate.getFullYear();
        let endYear    = this.endDate.getFullYear();
       // alert(this.tempStartDate.getFullYear()+'--'+this.tempStartDate.getMonth()+'--'+this.tempStartDate.getDate())
       // alert(this.endDate.getFullYear()+'--'+this.endDate.getMonth()+'--'+this.endDate.getDate())
        for(var i = startYear; i <= endYear; i++) {
          var endMonth = i != endYear ? 11 :this.endDate.getMonth();
          var startMon = i === startYear ? (this.startDate.getMonth()) : 0;
          for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
            var month = j+1;
            var displayMonth = month < 10 ? '0'+month : month;
            this.lastDateofMonth= new Date(i,month,0 );
            this.startDate = new Date(this.tempStartDate.getFullYear(), this.tempStartDate.getMonth(), this.tempStartDate.getDate());
    
            this.deactivateDropDownArr.push(this.lastDateofMonth);
          }
        }
  }

}
