import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../../utils/common-service';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { AppResponse } from 'src/app/model/AppResponse';
import { AppRegExConstants } from 'src/app/utils/AppRegExConstants';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { environment } from 'projects/system-portal/src/environments/environment';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription, timer } from 'rxjs';
import { finalize, map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-groupadmins-list',
  templateUrl: './groupadmins-list.component.html',
  styleUrls: ['./groupadmins-list.component.css']
})
export class GroupadminsListComponent implements OnInit {

  dataTable: any;
  groupAdminListResponse: AppResponse;
  requestData: AppResponse;
  requestDataPromote: AppResponse;
  groupMasterId: string;
  groupAdminForm: FormGroup;
  intlObj: any;
  contactNum: any;
  isError: boolean = true;
  countryCode: any;
  countryCodeLength: any;
  flag: boolean = false;
  resetFlag: boolean = false;
  submitted: boolean;
  Status: boolean = false;
  mess;
  primaryEmails: string[] = [];
 

  groupAdminResetPwdEmailForm: FormGroup;
  groupAdminSentOtp: FormGroup;
  promoteOtpSentForm: FormGroup;
  resetPwdSentOTP: boolean = false;
  sentOtpToAdmin: boolean = false;
  sentOtpUpdateAdminButton: boolean = false;
  sentOtpToAdminSubmit: boolean = false;
  sentOtpPromotePrimary: boolean = false;
  resetPwdSentOTPSubmit: boolean = false;
  sentOtpUpdateToSubmit: boolean = false;
  resetPwdSentLinkSubmit: boolean = false;
  currentGADetail: any;
  sentOtpButtonFrPromote: boolean = false;
  currGroupAdminStatus: boolean = false;
  addbuttonEnable: boolean = false;

  reqSentOTPResponse: AppResponse;
  sentOtpToGA: AppResponse;
  sentOtpUpdateResponse: AppResponse;
  sentOtpPromoteEmailResponse: AppResponse;

  private updateGroupAdmin = environment.BASE_URL + ApplicationURIConstants.UPDATE_GROUPADMIN;
  private addGroupAdmin = environment.BASE_URL + ApplicationURIConstants.ADD_GROUPADMIN;
  private deleteGroupAdmin = environment.BASE_URL + ApplicationURIConstants.DELETE_GROUPADMIN;
  private deactivateGroupAdmin = environment.BASE_URL + ApplicationURIConstants.DEACTIVATE_GROUPADMIN;
  private activateGroupAdmin = environment.BASE_URL + ApplicationURIConstants.ACTIVATE_GROUPADMIN;


  private promteasprimary = environment.BASE_URL + ApplicationURIConstants.PROMOTE_AS_PRIMARY_GROUP_ADMIN;


  private sendOTPtoEmailOfGroupAdmin = environment.BASE_URL + ApplicationURIConstants.SEND_OTP_TO_EMAIL_OF_GROUPADMIN;
  private sentOtpToPrimaryEmail = environment.BASE_URL + ApplicationURIConstants.SENT_EMAIL_TO_PRIMARY_GA;
  private validateOTPToSendResetPwdLink = environment.BASE_URL + ApplicationURIConstants.VALIDATE_OTP_TOSEND_RESETPWDLINK;
  private sentOtpToPromotePrimaryEmail = environment.BASE_URL + ApplicationURIConstants.SENT_OTP_TO_PROMOTE_PRIMARY_EMAIL;
  private emailVerificationLink = environment.BASE_URL + ApplicationURIConstants.EMAIL_VERIFICATION_LINK;

  @ViewChild('phoneInpt2', { static: false }) phoneInpt2: ElementRef;
  @ViewChild('myInput', { static: true }) myInput: ElementRef;
  primary: boolean = false;
  gmail: any;
  verifyCheck: boolean;
  emailCheck: boolean;
  primaryContactfrDelete : any;
  primaryContact: any;
  adddisable: boolean;
  enablebutton: boolean;
  showsuccessmessage: boolean = false;
  showdangermessage: boolean = false;
  alertmessage: any;
  emailEnable: boolean = false;
  promoteButtonEnable: boolean = false;

  paymentTypeMessage: any;
  showpymentTypesuccessmessage: boolean = false;
  showpymentTypedangermessage: boolean = false;

  otpSentFrPromoteMail: any;
  showPromoteMailSuccessMessage: boolean = false;
  showPromoteMaildangermessage: boolean = false;

  updateMessage: any;
  showupdatesuccessmessage: boolean = false;
  showupdatedangermessage: boolean = false;
  countdown$: Observable<number>;
  countdownValue: number = 30; // In seconds: 2 minutes * 60 seconds/minute
  showResend: boolean = false;
  showResendPromote: boolean = false;
  showSubmit: boolean = true;
  showmodal: boolean = false;
  showpromotemodal: boolean = false;
  contactValid: boolean = false;
  countdownSubscription?: Subscription;

  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService, private spinner: NgxSpinnerService, private commonService: CommonService) {
  }
  
  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      this.groupMasterId = params.id;
    });
    // this.startCountdown();
    
    this.groupAdminForm = this.formBuilder.group({
      "firstName": ['', [Validators.required, this.customUsernameValidator, Validators.maxLength(150)]],
     
     
      "lastName": ['', [Validators.required,this.customUsernameValidator, Validators.maxLength(150)]],
      "email": ['', [Validators.required, this.customEmailValidator]],
      "contact": ['', [Validators.required]],
      "groupAdminId": ['']

    });

   
  

    this.groupAdminForm.controls["email"].enable();
    this.groupAdminForm.controls["contact"].setValue("");

    this.groupAdminResetPwdEmailForm = this.formBuilder.group({
      "email": ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
    });
    this.groupAdminSentOtp = this.formBuilder.group({
      "primaryEmail": ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
    });
    this.promoteOtpSentForm = this.formBuilder.group({
      "primaryEmail": ['', [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)]],
    })
    $('.groupAdminContainer').hide();
  }
  customUsernameValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value || '';  // This ensures that value is at least an empty string
    const trimmedValue = value.trim();

    if (trimmedValue === "") {
      return { 'required': true };
    }

    if (/[^a-zA-Z ]/.test(trimmedValue)) {
      return { 'invalidCharacters': true };
    }

    if (/^ | $/.test(trimmedValue)) {
      return { 'startEndSpace': true };
    }

    return null; // return null if valid
}


customEmailValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const value = control.value;

  if (/^\s*$/.test(value)) {
      return { 'required': true };
  }

  const emailPattern = /^[a-zA-Z0-9._-]+(\s*[a-zA-Z0-9._-]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  
  if (!emailPattern.test(value)) {
      return { 'invalidEmailFormat': true };
  }

  return null; // return null if valid
}




startCountdown() {
    this.showResend = false; // ensure it's false at the start
  this.countdown$ = timer(0, 1000).pipe(
   
    map(elapsed => this.countdownValue - elapsed),
    tap(value => {
      if (value === 0) {
               this.showResend = true;
      }
    })
  );


  this.countdown$.subscribe();
}

// startCountdown() {
//   this.showResend = false;

//   this.countdown$ = timer(0, 1000).pipe(
//       take(this.countdownValue + 1),
//       map(elapsed => {
//           console.log(`Countdown value: ${this.countdownValue - elapsed}`);
//           return this.countdownValue - elapsed;
//       }),
//       finalize(() => {
//           console.log('Countdown finished');
//           this.showResend = true;
//       })
//   );

//   this.countdown$.subscribe();
// }






  resendOtp() {
    this.showResend = false;
    // this.showSubmit = true; // Show the submit button once OTP is resent
    this.startCountdown();
    // Implement your resend OTP logic here

    this.sentOtpToAdminSubmit = true;

    let contactnumber = this.intlObj.getNumber();
    // console.log('contact on submit' + "::" + contactnumber + "::" + this.intlObj.getNumber())
    this.groupAdminForm.controls['contact'].setValue(contactnumber);
    this.httpService.save({ primaryEmail: this.primaryEmails[0], firstName: this.groupAdminForm.controls['firstName'].value, lastName: this.groupAdminForm.controls['lastName'].value, contact: this.intlObj.getNumber(), email: this.groupAdminForm.controls['email'].value, }, this.sentOtpToPrimaryEmail).subscribe((res) => {


      this.sentOtpToGA = new AppResponse(res);
      console.log("resssssssssssssssssss" + this.groupAdminForm.controls['firstName'].value);
      // return;

      if (this.sentOtpToGA.status == 200) {

        console.log("inside 2000");

        this.sentOtpToAdmin = true;
        this.paymentTypeMessage = this.sentOtpToGA.message;
        this.showpymentTypesuccessmessage = true;
        this.showpymentTypedangermessage = false;

        setTimeout(() => {
          this.showpymentTypesuccessmessage = false;
          this.showpymentTypedangermessage = false;
          // $('#groupAdminResetPwd').hide();
          // $('.groupAdminContainer').hide();
          // this.ngAfterViewInit();
        }, 3000);
      } else {
        // console.log(this.reqSentOTPResponse.errors)
        // this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.DANGER_LABLE, `${this.sentOtpToGA.errors}`);
        this.paymentTypeMessage = this.sentOtpToGA.message;
        this.showpymentTypesuccessmessage = false;
        this.showpymentTypedangermessage = true;
      }



    });
  }


  resendOtpfrPromote() {
    this.showResend = false;
    // this.showSubmit = true; // Show the submit button once OTP is resent
    this.startCountdown();
    // Implement your resend OTP logic here

    this.sentOtpPromotePrimary = true;

    let contactnumber = this.intlObj.getNumber();
    // console.log('contact on submit' + "::" + contactnumber + "::" + this.intlObj.getNumber())
    this.groupAdminForm.controls['contact'].setValue(contactnumber);
    this.httpService.save({ primaryEmail: this.primaryEmails[0], firstName: this.groupAdminForm.controls['firstName'].value, lastName: this.groupAdminForm.controls['lastName'].value, contact: this.intlObj.getNumber(), email: this.groupAdminForm.controls['email'].value, }, this.sentOtpToPromotePrimaryEmail).subscribe((res) => {
      // gaFirstName:this.currentGADetail.firstName,gaLastName:this.currentGADetail.lastName
      this.sentOtpPromoteEmailResponse = new AppResponse(res);

      console.log(this.sentOtpPromoteEmailResponse);

      console.log("resssssssssssssssssss" + this.groupAdminForm.controls['firstName'].value);
      // return;

      console.log("hi insidev sentb otp ..................................................................................................................................................................");


      if (this.sentOtpPromoteEmailResponse.status == 200) {

        console.log("inside 2000");
        this.showpromotemodal = true;


        console.log(this.showpromotemodal);

        // this.startCountdown();
        this.sentOtpButtonFrPromote = true;
        // this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.SUCCESS_LABLE, this.sentOtpToGA.message);
        this.otpSentFrPromoteMail
          = this.sentOtpPromoteEmailResponse.message;
        this.showPromoteMailSuccessMessage = true;
        this.showPromoteMaildangermessage = false;

        setTimeout(() => {
          this.showPromoteMailSuccessMessage = false;
          this.showPromoteMaildangermessage = false;
          // $('#groupAdminResetPwd').hide();
          // $('.groupAdminContainer').hide();
          // this.ngAfterViewInit();
        }, 3000);
      } else {
        // this.sentOtpPromotePrimary = true;

        // console.log(this.reqSentOTPResponse.errors)
        // this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.DANGER_LABLE, `${this.sentOtpToGA.errors}`);
        this.otpSentFrPromoteMail = "OTP hasn't been sent! Please try again after Sometime"
        this.showPromoteMailSuccessMessage = false;
        this.showPromoteMaildangermessage = true;
      }



    });
  }

  // ... other methods and logic of your component

  ngAfterViewInit(): void {

    this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_GROUPADMIN_DETAILS + '/' + this.groupMasterId).subscribe(res => {

      this.groupAdminListResponse = new AppResponse(res);
      console.log(this.groupAdminListResponse)
      console.log(this.groupAdminListResponse.data[0].primarycontact);


      // this.primaryContact=this.groupAdminListResponse.data[0].primarycontact;
      console.log("Testinggggggggggggggggggggggggggggggg ressssss" + JSON.stringify(res))
      console.log("PrimaryyyyyyyyyyyyyContacttttttttttttt" + this.groupAdminListResponse.data[0].primarycontact);



      this.verifyCheck = this.groupAdminListResponse.data[0].emailVerified;
      this.emailCheck = this.groupAdminListResponse.data[0].emailSent;
      console.log("emailVerifieddddddddddddd" + this.groupAdminListResponse.data[0].emailVerified)

      // After your existing code:

      // Array to store emails with primarycontact as '1'
      if (this.groupAdminListResponse && this.groupAdminListResponse.data) {
        this.primaryEmails = this.groupAdminListResponse.data
          .filter(admin => admin.primarycontact === '1')
          .map(admin => admin.email);
      }


      console.log(this.primaryEmails + "primarryyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy");  // This will log all emails with primarycontact set to '1'



      // console.log(this.groupAdminListResponse.data.length)
      // let legth=this.groupAdminListResponse.data.length;
      $('#gaListDataTable').dataTable().fnDestroy();
      setTimeout(() => {
        $('#gaListDataTable').DataTable({
          responsive: true,
          autoWidth: true,
          searching: true,
          // Added by Hema shanker HB-675 on 25/11/2021 Start
          stateSave: true,
          // Added by Hema shanker HB-675 on 25/11/2021 End
          /*"ordering": true,*/
          order: [],
          language: {
            zeroRecords: 'No records found.',
          },
          // Modified by Hema shanker HB-675 on 25/11/2021 Start
          lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
          // Modified by Hema shanker HB-675 on 25/11/2021 End
          pageLength: 5,
          pagingType: $(window).width() < 768 ? "full" : "full_numbers",
          processing: true,
          columnDefs: [{
            targets: 4,
            orderable: false
          },
          ]
        });
      }, 200);
      if (res.status === 401) {
        this.tokenService.clearSession();
        this.router.navigate(['login']);
      } else if (res.status === 403) {
        this.router.navigate(['home']);
      }
    });

  }

  //Add Group Admin Form
  AddGroupAdminFrom() {

    this.intlObj.setNumber('');
    this.groupAdminForm.controls["email"].enable();
    this.groupAdminForm.controls["firstName"].setValue("");
    this.groupAdminForm.controls["lastName"].setValue("");
    this.groupAdminForm.controls["contact"].setValue("");
    this.groupAdminForm.controls["email"].setValue("");
    

    console.log("inside button");

    this.groupAdminForm.reset();
    // console.log(JSON.stringify(this.groupAdminForm.value));
    $('.addBtnRow').show();
    $('.updateBtnRow').hide();
    $("#phoneInput").focus();
    this.myInput.nativeElement.blur();
    this.resetFlag = true;
    $('.groupAdminContainer').show();
    /* scroll down to form */
    document.getElementById("groupAdminFormDiv").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });


    // if(this.groupAdminForm.invalid){
    //   this.adddisable=true;
    //   console.log(this.adddisable);

    // }else{
    //   this.adddisable=false;
    //   console.log(this.adddisable);

    // }
  }

  enablebtn() {
    console.log("inside enable");

    if (
      !this.groupAdminForm.controls["firstName"].value ||
      !this.groupAdminForm.controls["lastName"].value ||
      !this.groupAdminForm.controls["email"].value
    ) {
      this.enablebutton = false;
      console.log(this.enablebutton);
    } else {
      this.enablebutton = true;
      console.log(this.enablebutton);
    }

  }



  //Edit Group Admin
  populateGroupAdmin(data) {

    


    this.currentGADetail = data;
    //ADDED BY AISHWARYAA FOR HB-631 30/12/2021
    this.currGroupAdminStatus = false;
    if (data.status == true && data.emailVerified) {
      this.currGroupAdminStatus = true;

    }
    this.Status=data.status;
    this.verifyCheck = data.emailVerified;
    this.emailCheck = data.emailSent;
    this.primaryContactfrDelete = data.primarycontact;
    //CODE END 
    if (data.primarycontact == '1') {
      this.emailEnable = true;
      this.groupAdminForm.get('email').enable();
      console.log("inside primarycontact ifff");

    }
    else {
      this.emailEnable = false;
      this.groupAdminForm.get('email').disable();
      console.log("primary contact elsseeeeeeeeeee");

    }

    if (data.primarycontact == '1') {
      this.promoteButtonEnable = true;
      console.log("Promote button true");

    }
    else {
      this.promoteButtonEnable = false;
      console.log("Promote Button false");

    }

    // console.log("Curr GA Status :" + this.currGroupAdminStatus);

    //alert(data.firstName+'::'+data.lastName+'::'+data.email+':::'+data.groupAdminId+':::'+data.status)

    this.groupAdminForm = this.formBuilder.group({
      "firstName": [data.firstName.trim(),[Validators.required, this.customUsernameValidator, Validators.maxLength(150)]],
      "lastName": [data.lastName.trim(), [Validators.required,this.customUsernameValidator, Validators.maxLength(150)]],
      "email": [data.email.trim(), [Validators.required, this.customEmailValidator]],
      "contact": [data.contact, [Validators.required]],
      "groupAdminId": [data.groupAdminId],
    });
    this.primaryContact = data.primarycontact;
    this.intlObj.setNumber('');
    if (data.contact) {
      this.intlObj.setNumber(data.contact);
    }
    $("#phoneInput").focus();
    this.myInput.nativeElement.blur();
    this.resetFlag = true;
    $('.addBtnRow').hide();
    $('.updateBtnRow').show();
    $('.groupAdminContainer').show();
    /* scroll down to form */
    document.getElementById("groupAdminFormDiv").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.groupAdminForm.controls; }
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  get resetForm() { return this.groupAdminResetPwdEmailForm.controls; }

  //contact field fn
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return charCode >= 48 && charCode <= 57; // Only allow charCodes for 0-9
  }
  

  checkForError(obj) {

    
    this.resetFlag = false; //HB-426 Hevisa Patel 28-01-2021 
    this.myInput.nativeElement.blur();
    $("#phoneInput").focus();
  }

  telInputObject(obj) {
    this.intlObj = obj;
    this.flag = true;
    this.countryCode = obj.dialCode;
  }

  toggleFlag() {
    this.flag = !this.flag;

    if (this.flag && this.currentGADetail.contact) {
      this.intlObj.setNumber(this.currentGADetail.contact);
    } else {
      this.intlObj.setNumber('');
    }
  }

  onCountryChange(country: any) {
    this.countryCode = country.dialCode;
    if (!this.flag) {
      this.intlObj.setNumber(''); // if you want to clear the field when the country changes and the flag is false.
    }
  }



  hasError(obj) {
    this.isError = obj;
    this.resetFlag = obj;
    console.log('hasError: ', obj);
  }

  getNumber(obj) {
    console.log("getNumber::" + JSON.stringify(obj));
  }

  onGroupAdminSubmit() {
    this.spinner.show();

    //to identify which field is invalid, will remove this code in prod
    const controls = this.groupAdminForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        // console.log("err::::" + name);
      }
    }

    this.submitted = true;
    let contactnumber = this.intlObj.getNumber();
    // console.log('contact on submit' + "::" + contactnumber + "::" + this.intlObj.getNumber())
    this.groupAdminForm.controls['contact'].setValue(contactnumber);

    if (this.groupAdminForm.invalid || !this.isError) {
      this.spinner.hide();
      return;
    } else {
      if (!$('#otp').prop('value')) {
        // this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.DANGER_LABLE, `${"Please Provide OTP"}`);
        console.log("testing if for wrong otp");


        this.updateMessage = "Please Provide OTP";
        this.showupdatedangermessage = true;
        setTimeout(() => {
          this.showupdatedangermessage = false;
          this.ngAfterViewInit();

          // below time set added by aishwaryaa for HB-631
        }, 2000);
        return;

      }
      this.sentOtpToAdminSubmit = true;
      var formData = new FormData();
      formData.append("contact", this.intlObj.getNumber());
      formData.append("groupMasterId", this.groupMasterId);
      formData.append("firstName", this.groupAdminForm.controls['firstName'].value.trim());
      formData.append("lastName", this.groupAdminForm.controls['lastName'].value.trim());
      formData.append("email", this.groupAdminForm.controls['email'].value.trim());
      formData.append("primarycontact", "0");
      formData.append("primaryEmail", this.primaryEmails[0]);
      formData.append("otp", $('#otp').prop('value'));
      // formData.append()

      console.log("responseeeeeeeeeeeee" + this.groupAdminForm.controls['email'].value);
      console.log("Testingggggggggggggggggggggggggggggg" + formData)



      // return



      //ADD
      this.httpService.save(formData, this.addGroupAdmin).subscribe((resp) => {

        this.requestData = new AppResponse(resp);
        console.log("this is error checking before if" + this.requestData);

        // console.log("this is error checking before if" + JSON.stringify(resp.errors[0]));

        this.gmail = this.groupAdminForm.controls['email'].value
        if (this.requestData.status == 200) {
          this.submitted = false;
          this.groupAdminForm.reset();
          this.updateMessage = this.requestData.message;
          this.showupdatesuccessmessage = true;
          this.showupdatedangermessage = false;
          // this.spinner.hide();
          // $('#groupAdminSentPwd').hide();
          // $('.groupAdminContainer').hide();
          this.ngOnInit()
          location.reload()
          this.httpService.save({ email: this.gmail }, this.emailVerificationLink).subscribe((res) => {
            console.log("emailllllllllllllllllllllllllll" + res);
            // console.log("emailllllllllllllllllllllllllll"+res);

            if (res.status == 200) {
              // console.log("hello this is if")

              this.submitted = false;
              // this.addSubscriberForm.reset();
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.SUCCESS_LABLE,
                ApplicationConstants.GRP_REG_MSG
              );




            }
              else {

              //   {
              //     "status": 400,
              //     "errors": [
              //         "Email already registered."
              //     ],
              //     "message": "error",
              //     "data": null
              // }
              // console.log("hello this is else")
              // this.commonService.showNotificationSuccess(
              //   ApplicationConstants.MSG_BOX_LABEL,
              //   ApplicationConstants.DANGER_LABLE,
              //   `${this.requestData.errors}`

              // );
              this.updateMessage = this.requestData.message;

              this.showupdatedangermessage = true;
            }
            setTimeout(() => {
              this.showupdatedangermessage = false;
              this.ngAfterViewInit();

              // below time set added by aishwaryaa for HB-631
            }, 2000);
          });

        } else {
          console.log("else part checking wrong otp");

          if(this.requestData.message == "error"){
            // this.updateMessage = this.requestData.message=="error"?resp.errors[0]:this.requestData.message;

            this.updateMessage = resp.errors[0];
            this.showupdatedangermessage = true;
            this.ngOnInit()
            location.reload()
          }
          else{
            this.updateMessage = this.requestData.message;
            this.showupdatedangermessage = true;
          }

          // console.log(this.requestData.errors)
          // this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.requestData.errors}`);
     
        }
        setTimeout(() => {
          this.showupdatedangermessage = false;
          this.ngAfterViewInit();

          // below time set added by aishwaryaa for HB-631
        }, 2000);
      });



    }
  }


  promoteasprimary() {
    console.log("inside promote as primary");
    // this.spinner.show();

    // //to identify which field is invalid, will remove this code in prod
    // const controls = this.groupAdminForm.controls;
    // for (const name in controls) {
    //   if (controls[name].invalid) {
    //     // console.log("err::::" + name);
    //   }
    // }

    this.submitted = true;
    let contactnumber = this.intlObj.getNumber();
    // console.log('contact on submit' + "::" + contactnumber + "::" + this.intlObj.getNumber())
    this.groupAdminForm.controls['contact'].setValue(contactnumber);

    if (this.groupAdminForm.invalid || !this.isError) {
      this.spinner.hide();
      return;
    } else {
      if (!$('#otp').prop('value')) {
        // this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.DANGER_LABLE, `${"Please Provide OTP"}`);
        this.otpSentFrPromoteMail = "Please Provide OTP";
        this.showPromoteMaildangermessage = true;
        setTimeout(() => {
          this.showPromoteMaildangermessage = false;
          this.ngAfterViewInit();

          // below time set added by aishwaryaa for HB-631
        }, 2000);
        return;
      }
      this.sentOtpToAdminSubmit = true;
      var formData = new FormData();
      formData.append("contact", this.intlObj.getNumber());
      formData.append("groupMasterId", this.groupMasterId);
      formData.append("firstName", this.groupAdminForm.controls['firstName'].value);
      formData.append("lastName", this.groupAdminForm.controls['lastName'].value);
      formData.append("email", this.groupAdminForm.controls['email'].value);
      formData.append("primarycontact", "0");
      formData.append("primaryEmail", this.primaryEmails[0]);
      formData.append("otp", $('#otp').prop('value'));
      // formData.append()



      console.log("responseeeeeeeeeeeee" + this.groupAdminForm.controls['email'].value);
      console.log("Testingggggggggggggggggggggggggggggg" + formData)



      // return

      // this.httpService.save({primaryEmail:this.primaryEmails[0],firstName:this.groupAdminForm.controls['firstName'].value, lastName:this.groupAdminForm.controls['lastName'].value,contact: this.intlObj.getNumber(),email:this.groupAdminForm.controls['email'].value, otp:$('#otp').prop('value'),},primarycontact:).subscribe((res) => {

      //ADD
      this.httpService.save({ primaryEmail: this.primaryEmails[0], firstName: this.groupAdminForm.controls['firstName'].value, lastName: this.groupAdminForm.controls['lastName'].value, contact: this.intlObj.getNumber(), email: this.groupAdminForm.controls['email'].value, otp: $('#otp').prop('value'), }, this.promteasprimary).subscribe((resp) => {

        this.requestDataPromote = new AppResponse(resp);
        this.gmail = this.groupAdminForm.controls['email'].value
        if (this.requestDataPromote.status == 200) {

          console.log("inside 2000000000000");



          this.submitted = false;
          this.groupAdminForm.reset();
          this.otpSentFrPromoteMail = this.requestDataPromote.message;
          this.showPromoteMailSuccessMessage = true;
          this.showPromoteMaildangermessage = false;
          // this.spinner.hide();
          // $('#promoteOtpModel').hide();
          // $('#groupAdminResetPwd').hide();
          // $('.groupAdminContainer').hide();
          this.ngOnInit()
          location.reload()



          this.httpService.save({ email: this.gmail }, this.emailVerificationLink).subscribe((res) => {
            console.log("emailllllllllllllllllllllllllll" + res);
            // console.log("emailllllllllllllllllllllllllll"+res);

            if (res.status == 200) {
              // console.log("hello this is if")

              this.submitted = false;
              // this.addSubscriberForm.reset();
              this.commonService.showNotificationSuccess(
                ApplicationConstants.MSG_BOX_LABEL,
                ApplicationConstants.SUCCESS_LABLE,
                ApplicationConstants.GRP_REG_MSG
              );




            }
          else {
              // console.log("hello this is else")
              // this.commonService.showNotificationSuccess(
              //   ApplicationConstants.MSG_BOX_LABEL,
              //   ApplicationConstants.DANGER_LABLE,
              //   `${this.requestData.errors}`

              // );
              this.otpSentFrPromoteMail = this.requestDataPromote.message;

              this.showPromoteMaildangermessage = true;
            }
            setTimeout(() => {
              this.showPromoteMaildangermessage = false;
              this.ngAfterViewInit();
  
              // below time set added by aishwaryaa for HB-631
            }, 2000);
          });
       
        } else {
          // console.log(this.requestData.errors)
          // this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.requestData.errors}`);
          this.otpSentFrPromoteMail = this.requestDataPromote.message;

          this.showPromoteMaildangermessage = true;
        }
      });
      setTimeout(() => {
        this.showPromoteMaildangermessage = false;
        this.ngAfterViewInit();

        // below time set added by aishwaryaa for HB-631
      }, 2000);

    }

  }


  updateGroupAdminData() {
    if (this.groupAdminForm.controls['groupAdminId'].value != null) {
      //Edit

      this.spinner.show();

      //to identify which field is invalid, will remove this code in prod
      const controls = this.groupAdminForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          // console.log("err::::" + name);
        }
      }

      this.submitted = true;
      let contactnumber = this.intlObj.getNumber();
      // console.log('contact on submit' + "::" + contactnumber + "::" + this.intlObj.getNumber())
      this.groupAdminForm.controls['contact'].setValue(contactnumber);

      if (this.groupAdminForm.invalid || !this.isError) {
        this.spinner.hide();
        return;
      }
      var formData = new FormData();
      formData.append("contact", this.intlObj.getNumber());
      formData.append("groupMasterId", this.groupMasterId);
      formData.append("firstName", this.groupAdminForm.controls['firstName'].value.trim());
      formData.append("lastName", this.groupAdminForm.controls['lastName'].value.trim());
      formData.append("email", this.groupAdminForm.controls['email'].value.trim());
      formData.append("primarycontact", this.primaryContact);


      // formData.append()

      console.log("responseeeeeeeeeeeee" + this.groupAdminForm.controls['email'].value);
      console.log("Testingggggggggggggggggggggggggggggg" + formData)
      console.log("Testingggggggggggggggggggggggggggggg Primaryyyyyyyyyyyyyyyyyy" + this.primaryContact[0]);


      //passing groupadminid in edit
      formData.append("groupAdminId", this.groupAdminForm.controls['groupAdminId'].value);


      this.httpService.update(formData, this.updateGroupAdmin).subscribe((res) => {
        this.requestData = new AppResponse(res);
        if (this.requestData.status == 200) {
          $('.groupAdminContainer').hide();
          this.submitted = false;
          this.groupAdminForm.reset();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.requestData.message);



          setTimeout(() => {
            this.ngAfterViewInit();
            // below time set added by aishwaryaa for HB-631
          }, 1000);
        } else {
          // console.log(this.requestData.errors)
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.requestData.errors}`);
        }
      });
    }

  }



  //Reset Form
  resetGroupAdminForm() {
    this.groupAdminForm.reset();
  }
  //delete Group Admin
  deleteGroupAdminData() {
    console.log("this is primary test" + this.primaryContactfrDelete);
    if(this.primaryContactfrDelete == 1){
      alert("To delete the primary group admin, please assign another group admin as the primary admin.")
    }
    else{
      if (confirm("Are you confirm to delete " + this.currentGADetail.firstName)) {
        // code added by aishwaryaa for HB-865
        let legth = this.groupAdminListResponse.data.length;
        if (legth <= 1) {
          alert("Please add the group admin before you delete")
        }
        // code end 
        else {
          let groupAdminId = this.groupAdminForm.controls['groupAdminId'].value;
          this.httpService.update(groupAdminId, this.deleteGroupAdmin + '/' + groupAdminId).subscribe((res) => {
            this.requestData = new AppResponse(res);
            if (this.requestData.status == 200) {
              this.submitted = false;
              this.groupAdminForm.reset();
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.requestData.message);
              $('.groupAdminContainer').hide();
              setTimeout(() => {
                this.ngAfterViewInit();
                // below time set added by aishwaryaa for HB-631
              }, 1000);
            } else {
              // console.log(this.requestData.errors)
              this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.requestData.errors}`);
            }
          });
        }
  
      }
    }
    
    
  }
  //deactivate group  admin
  deactivateGroupAdminData() {
    if(this.primaryContactfrDelete == 1){
      alert("To deactivate the primary group admin, please assign another group admin as the primary admin.")
    }
    else{
      if (confirm("Are you sure you want to Deactivate Group Admin " + this.currentGADetail.firstName.trim())) {
        let groupAdminId = this.groupAdminForm.controls['groupAdminId'].value;
        this.httpService.update(groupAdminId, this.deactivateGroupAdmin + '/' + groupAdminId).subscribe((res) => {
          this.requestData = new AppResponse(res);
          if (this.requestData.status == 200) {
            this.submitted = false;
            this.groupAdminForm.reset();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, this.requestData.message);
            $('.groupAdminContainer').hide();
            setTimeout(() => {
              this.ngAfterViewInit();
            }, 3000);
          } else {
            // console.log(this.requestData.errors)
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.requestData.errors}`);
          }
        });
      }
    }
    //Deactivate msg modified by aishwaryaa for HB-904
   
  }

  //activate group  admin
  activateGroupAdminData() {
    // if(this.emailCheck){
    //   confirm("Are you confirm to activate and sent a verification mail to " + this.currentGADetail.firstName)
    // }
    // else{
    // if (confirm(`Are you confirm to activate   ${this.currentGADetail.firstName} ${this.emailCheck ? 'and you want to ent email verification': ''}`)) {

    if (confirm(` ${this.emailCheck && this.Status && !this.verifyCheck ? 'Are you sure you want to resend the email verification link?' : `Are you confirm to activate ${this.currentGADetail.firstName.trim()}`}`))

   
    {

      console.log("Active grouppppppppppppppppppppppppppppp")
      let groupAdminId = this.groupAdminForm.controls['groupAdminId'].value;
      this.gmail = this.groupAdminForm.controls['email'].value
      // console.log("testinggggggggggggggggggggggggggg Admin DAta"+this.groupAdminForm.controls['emailVerfied'].value)
      this.httpService.update(groupAdminId, this.activateGroupAdmin + '/' + groupAdminId).subscribe((res) => {
        this.requestData = new AppResponse(res);
        if (this.requestData.status == 200) {
          if(!this.verifyCheck){
            this.submitted = false;
            this.groupAdminForm.reset();
            this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${"Email Verification Link Resent Successfully"}`);
            $('.groupAdminContainer').hide();
          }
          else{
                
          this.submitted = false;
          this.groupAdminForm.reset();
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE, `${"Group admin has been activated successfully."}`);
          $('.groupAdminContainer').hide();
          }
          console.log("this is one in if");
        

          if (!this.verifyCheck) {
            this.httpService.save({ email: this.gmail }, this.emailVerificationLink).subscribe((res) => {
              console.log("emailllllllllllllllllllllllllll" + res);
              // console.log("emailllllllllllllllllllllllllll"+res);

              if (res.status == 200) {
                console.log("hello this is if")
                console.log("this is otwo in if");
                this.submitted = false;
                // this.addSubscriberForm.reset();
                // this.commonService.showNotificationSuccess(
                //   ApplicationConstants.MSG_BOX_LABEL,
                //   ApplicationConstants.SUCCESS_LABLE,
                //   ApplicationConstants.GRP_REG_MSG
                // );



              } else {
                console.log("this is three in if");
                console.log("hello this is else")
                this.commonService.showNotificationSuccess(
                  ApplicationConstants.MSG_BOX_LABEL,
                  ApplicationConstants.DANGER_LABLE,
                  `${this.requestData.errors}`
                );
              }
            });
          }







          setTimeout(() => {
            this.ngAfterViewInit();
          }, 3000);
        } else {
          // console.log(this.requestData.errors)
          this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.DANGER_LABLE, `${this.requestData.errors}`);
        }
      });
    }
    // }

  }

  //reset Pwd - send OTP to email
  sendOTPtoEmail() {
    this.resetPwdSentOTPSubmit = true;
    var formData = new FormData();
    formData.append("groupMasterId", this.groupMasterId);
    formData.append("groupAdminId", this.currentGADetail.groupAdminId);
    formData.append("email", this.currentGADetail.email);
    formData.append("firstName", this.currentGADetail.firstName);
    formData.append("lastName", this.currentGADetail.lastName);
    formData.append("contact", this.currentGADetail.contact);

    this.httpService.update(formData, this.sendOTPtoEmailOfGroupAdmin).subscribe((res) => {

      this.reqSentOTPResponse = new AppResponse(res);
      if (this.reqSentOTPResponse.status == 200) {
        this.resetPwdSentOTP = true;
        this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.SUCCESS_LABLE, this.reqSentOTPResponse.message);
        setTimeout(() => {
          // $('#groupAdminResetPwd').hide();
          // $('.groupAdminContainer').hide();
          // this.ngAfterViewInit();
        }, 2000);
      } else {
        // console.log(this.reqSentOTPResponse.errors)
        this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.DANGER_LABLE, `${this.reqSentOTPResponse.errors}`);
      }



    });
  }


  sendOTPtoPrimaryEmail() {

    this.sentOtpToAdminSubmit = true;
    this.startCountdown()


    // console.log("email logggggggggggggggggggggg"+this.primaryEmails[0]);
    // formData.append("contact", this.intlObj.getNumber());
    //   formData.append("groupMasterId", this.groupMasterId);
    //   formData.append("firstName", this.groupAdminForm.controls['firstName'].value);
    //   formData.append("lastName", this.groupAdminForm.controls['lastName'].value);
    //   formData.append("email", this.groupAdminForm.controls['email'].value);

    // return;
    let contactnumber = this.intlObj.getNumber();
    // console.log('contact on submit' + "::" + contactnumber + "::" + this.intlObj.getNumber())
    this.groupAdminForm.controls['contact'].setValue(contactnumber);
    this.httpService.save({ primaryEmail: this.primaryEmails[0], firstName: this.groupAdminForm.controls['firstName'].value, lastName: this.groupAdminForm.controls['lastName'].value, contact: this.intlObj.getNumber(), email: this.groupAdminForm.controls['email'].value, }, this.sentOtpToPrimaryEmail).subscribe((res) => {
      // gaFirstName:this.currentGADetail.firstName,gaLastName:this.currentGADetail.lastName
      this.sentOtpToGA = new AppResponse(res);
      console.log("resssssssssssssssssss" + this.groupAdminForm.controls['firstName'].value);
      // return;

      if (this.sentOtpToGA.status == 200) {

        console.log("inside 2000");
        this.showmodal = true;
        console.log(this.showmodal);

        this.sentOtpToAdmin = true;
        // this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.SUCCESS_LABLE, this.sentOtpToGA.message);
        this.paymentTypeMessage = this.sentOtpToGA.message;
        this.showpymentTypesuccessmessage = true;
        this.showpymentTypedangermessage = false;

        setTimeout(() => {
          this.showpymentTypesuccessmessage = false;
          this.showpymentTypedangermessage = false;
          // $('#groupAdminResetPwd').hide();
          // $('.groupAdminContainer').hide();
          // this.ngAfterViewInit();
        }, 3000);
      } else {
        // console.log(this.reqSentOTPResponse.errors)
        // this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.DANGER_LABLE, `${this.sentOtpToGA.errors}`);
        this.paymentTypeMessage = "OTP hasn't been sent! Please try again after Sometime"
        this.showpymentTypesuccessmessage = false;
        this.showpymentTypedangermessage = true;
      }



    });
  }

  populateResetPwdForm() {
    this.groupAdminResetPwdEmailForm.controls['email'].setValue(this.currentGADetail.email);
  }

  sentOtpToPromote() {
    console.log("This is sentOtp For Promote");

    if (this.primaryEmails && this.primaryEmails.length > 0) {
      this.promoteOtpSentForm.controls['primaryEmail'].setValue(this.primaryEmails[0]);

    }
  }


  sendOTPtoPromoteEmail() {

    this.sentOtpPromotePrimary = true;
this.startCountdown();

    // console.log("email logggggggggggggggggggggg"+this.primaryEmails[0]);
    // formData.append("contact", this.intlObj.getNumber());
    //   formData.append("groupMasterId", this.groupMasterId);
    //   formData.append("firstName", this.groupAdminForm.controls['firstName'].value);
    //   formData.append("lastName", this.groupAdminForm.controls['lastName'].value);
    //   formData.append("email", this.groupAdminForm.controls['email'].value);

    // return;
    let contactnumber = this.intlObj.getNumber();
    // console.log('contact on submit' + "::" + contactnumber + "::" + this.intlObj.getNumber())
    this.groupAdminForm.controls['contact'].setValue(contactnumber);
    this.httpService.save({ primaryEmail: this.primaryEmails[0], firstName: this.groupAdminForm.controls['firstName'].value, lastName: this.groupAdminForm.controls['lastName'].value, contact: this.intlObj.getNumber(), email: this.groupAdminForm.controls['email'].value, }, this.sentOtpToPromotePrimaryEmail).subscribe((res) => {
      // gaFirstName:this.currentGADetail.firstName,gaLastName:this.currentGADetail.lastName
      this.sentOtpPromoteEmailResponse = new AppResponse(res);

      console.log(this.sentOtpPromoteEmailResponse);

      console.log("resssssssssssssssssss" + this.groupAdminForm.controls['firstName'].value);
      // return;

      console.log("hi insidev sentb otp ..................................................................................................................................................................");


      if (this.sentOtpPromoteEmailResponse.status == 200) {

        console.log("inside 2000");
        this.showpromotemodal = true;


        console.log(this.showpromotemodal);

        // this.startCountdown();
        this.sentOtpButtonFrPromote = true;
        // this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.SUCCESS_LABLE, this.sentOtpToGA.message);
        this.otpSentFrPromoteMail
          = this.sentOtpPromoteEmailResponse.message;
        this.showPromoteMailSuccessMessage = true;
        this.showPromoteMaildangermessage = false;

        setTimeout(() => {
          this.showPromoteMailSuccessMessage = false;
          this.showPromoteMaildangermessage = false;
          // $('#groupAdminResetPwd').hide();
          // $('.groupAdminContainer').hide();
          // this.ngAfterViewInit();
        }, 3000);
      } else {
        // this.sentOtpPromotePrimary = true;

        // console.log(this.reqSentOTPResponse.errors)
        // this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.DANGER_LABLE, `${this.sentOtpToGA.errors}`);
        this.otpSentFrPromoteMail = "OTP hasn't been sent! Please try again after Sometime"
        this.showPromoteMailSuccessMessage = false;
        this.showPromoteMaildangermessage = true;
      }



    });
  }

  sentOtp() {

    // this.resetPwdSentOTPSubmit = true;

    // this.submitted = true
    console.log("hsfddddddddddddddddddddddddddddddddddddddddddd")
    if (this.primaryEmails && this.primaryEmails.length > 0) {
      this.groupAdminSentOtp.controls['primaryEmail'].setValue(this.primaryEmails[0]);

    }
  }



  sendResetPwdLink() {
    if (!$('#otp').prop('value')) {
      this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.DANGER_LABLE, `${"Please Provide OTP"}`);
      return;
    }
    this.resetPwdSentOTPSubmit = true;
    var formData = new FormData();
    formData.append("groupMasterId", this.groupMasterId);
    formData.append("groupAdminId", this.currentGADetail.groupAdminId);
    formData.append("email", this.currentGADetail.email);
    formData.append("firstName", this.currentGADetail.firstName);
    formData.append("lastName", this.currentGADetail.lastName);
    formData.append("contact", this.currentGADetail.contact);
    formData.append("otp", $('#otp').prop('value'));

    //alert( $('#otp').prop('value'))

    this.httpService.update(formData, this.validateOTPToSendResetPwdLink).subscribe((res) => {

      this.reqSentOTPResponse = new AppResponse(res);
      console.log("aishwaryaa" + JSON.stringify(this.reqSentOTPResponse))
      if (this.reqSentOTPResponse.status == 200) {
        this.resetPwdSentOTP = true;
        this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.SUCCESS_LABLE, this.reqSentOTPResponse.message);
        setTimeout(() => {
          $('#groupAdminResetPwd').hide();
          // $('.groupAdminContainer').hide();
          // this.ngAfterViewInit();
          // $('.groupAdminContainer').show();
          location.reload();
          // this.router.navigate(['/groupadmins-list', this.groupMasterId]);
        }, 3000);
      }
      if (this.reqSentOTPResponse.status == 400) {
        // this.mess="Home Deleted Sucessfully !"
        // console.log(this.reqSentOTPResponse.errors)
        this.commonService.showNotificationSuccessInModal(ApplicationConstants.MSG_BOX_LABEL_IN_MODAL, ApplicationConstants.DANGER_LABLE, `${"Please Provide Valid OTP"}`);
      }



    });

  }
  closeResetPwdModal() {
    $(".alert").removeClass('show');
    $(".alert").remove();
    // $("#messageBoxInModal").alert('close');
 
    $('#groupAdminResetPwd').hide();
    this.resetPwdSentOTPSubmit=false;
    this.resetPwdSentOTP = false;
  }
 
  closePromoteModel(){
   
    $('#promoteOtpModel').hide();
    this.sentOtpPromotePrimary=false;
    this.showpromotemodal = false;
    this.sentOtpButtonFrPromote=false;
  }


  closeAddAdminModel(){
    console.log("test this for close add model");
    $('#groupAdminSentPwd').hide();
    this.sentOtpToAdminSubmit=false;
    this.showmodal= false;
    this.sentOtpToAdmin = false;
  //   $('#groupAdminSentPwd').modal({
  //     backdrop: 'static',
  //     keyboard: false
  // });
 
  }
}
