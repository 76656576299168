import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import * as XLSX from 'xlsx';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AppResponse } from 'src/app/model/AppResponse';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { environment } from 'projects/system-portal/src/environments/environment';
import { Router } from '@angular/router';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { CommonService } from '../../utils/common-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

const helper = new JwtHelperService();

@Component({
  selector: 'app-zipcode-percentage',
  templateUrl: './zipcode-percentage.component.html',
  styleUrls: ['./zipcode-percentage.component.css']
})
export class ZipcodePercentageComponent implements OnInit {
  dataTable: any;
  resData: AppResponse;
  constructor(private httpService: HttpTransactionService,private spinner: NgxSpinnerService, private router: Router, private tokenService: TokenStorageService, private commonService: CommonService) { }

 
    ngOnInit() {
    }
    ngAfterViewInit(): void {
      // tslint:disable-next-line: max-line-length
      this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GET_TAXPERCENTAGE).subscribe(res => {
        this.resData = new AppResponse(res);
        console.log(this.resData);
        
        setTimeout(() => {
          $('#subscriptionhistory').DataTable({
              responsive: true,
              autoWidth: true,
              searching: true,
            stateSave: true,
              order: [],
              language: {
                zeroRecords: 'No records found.',
              },
            lengthMenu: [[5, 10, 15, 20], [5, 10, 15, 20]],
              pageLength: 5,
              pagingType: $(window).width() < 768 ? "full" : "full_numbers",
              processing: true,
              columnDefs: [{
                targets: 3,
                orderable: false
              },
              ]
            });         
        }, 200);
        if (res.status === 401) {
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(['login']);
        } else if (res.status === 403) {
          //403 URL not accessible
          this.router.navigate(['home']);
        }
      });
  
    }
    generatetax(){
      console.log("inside");
      this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GENERATE_TAX).subscribe(res => {
        this.resData = new AppResponse(res);
        console.log(this.resData);
    
        if (res.status === 401) {
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(['login']);
        } else if (res.status === 200) {
          this.spinner.show();
    console.log("inside status");
    window.location.reload();
    this.commonService.showNotificationSuccess(ApplicationConstants.MSG_BOX_LABEL, ApplicationConstants.SUCCESS_LABLE,this.resData.data.message );
    
          // Refresh the table after 2 seconds
          setTimeout(() => {

            console.log("insdie set time out");
            
            this.ngOnInit();
          }, 2000);
        } else if (res.status === 403) {
          //403 URL not accessible
          this.router.navigate(['home']);
        }
      });  
    }
    

  }
  