import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TokenStorageService } from '../../../auth-token/services/token-storage.service';
//import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationConstants } from '../../../utils/ApplicationConstants';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

declare let $: any;

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  showFiller = false;
  sidesize:any;
  rulerSizeClose:number = 120;
  rulerSizeOpen:number= 145;
  activeColor:string="'red'";
  normalColor:string="'pink'";
  initial:String;
  ///////////////////////////
  static:boolean=true;
  crstate:boolean=false;
  sdstate:boolean=false;
  nostate:boolean=false;
  nostatic:boolean=false;
  public sideNavState: boolean = false;
  public subCrOpen: boolean=false;
  public subSdOpen:boolean=false;
  public subNotOpen:boolean=false;
  public resview:boolean=false;
  
  public profileopen:boolean=false;
//////////////////////////
 nshover:boolean=false;

  @Output() messageEvent = new EventEmitter();
  constructor(private location: Location, public tokenService: TokenStorageService,private router: Router) { }
    userName: string;    
    roles: any[] = []; // HB-677 Priya Rangasamy
    isSytemAdminLoggedin: boolean =false;

  sendTitle(title: string) {
    this.messageEvent.emit(title);
  }

   //////////////////////////////////////////////
   isStatic(){
    this.static=!this.static
    this.nostatic=!this.nostatic

  }
  togglecr(){
    this.crstate=!this.crstate
  }
  togglesd(){
    this.sdstate=!this.sdstate
  }
  toggleno(){
    this.nostate=!this.nostate
  }
  //////////////////////////////////////////
 
  sinenavOpen() {
    this.sideNavState = !this.sideNavState
    // console.log(this.sideNavState)
    this.profileopen=this.profileopen
  }
  subComplainopen(){
    this.subCrOpen=!this.subCrOpen
  } 
  subComplainclose(){
    this.subCrOpen=this.sideNavState
  }
  subSdopen(){
    this.subSdOpen=!this.subSdOpen
    // console.log( this.subSdOpen)
  } 
  subSdClose(){
    this.subSdOpen=this.sideNavState
    // console.log( this.subSdOpen)
  }
 subNotopen(){
      this.subNotOpen=!this.subNotOpen
      // console.log(this.subNotOpen)
 }
 subNotclose(){
    this.subNotOpen=this.sideNavState
 }

 toggleprofile(){
  this.profileopen=!this.profileopen
  // console.log(this.profileopen)
 }
  /////////////////////////////////////////


  ngOnInit() {
    this.userName = this.tokenService.getuserName();
    this.roles = this.tokenService.getRoles();
    /**
     * while  user login, query written to get roles of user by skipping systemadmin role id, groupadmin role id
     * so response will come as [] for system admin
     */
    // console.log("Roles "+JSON.stringify(this.roles))
    if(this.roles.length == 0){
      this.isSytemAdminLoggedin = true;
      //added by aishwaryaa for HB-749
      localStorage.setItem('SuperAdmin', 'false');
    }

    else{
      localStorage.setItem('SuperAdmin', 'true');
    }
    //code end 

    this.getShortName();

    $(document).ready(() => {

      if ($(window).width() < 521) {
        $('.sidebar').toggleClass('toggled');
          $('.sidebar .collapse').collapse('hide');
      }
      

      $(window).resize(() => {
        if ($(window).width() < 521) {
          $('.sidebar').toggleClass('toggled');
          $('.sidebar .collapse').collapse('hide');
        }
      });
      
      $('#sidebarToggle, #sidebarToggleTop').on('click', () => {
        $('body').toggleClass('sidebar-toggled');
        $('.sidebar').toggleClass('toggled');
        if ($('.sidebar').hasClass('toggled')) {
          $('.sidebar .collapse').collapse('hide');
        }
      });

      $(window).resize(() => {
        if ($(window).width() < 768) {
          $('.sidebar .collapse').collapse('hide');
        }
      });

      // // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
      $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function (e) {
        if ($(window).width() > 768) {
          // tslint:disable-next-line: one-variable-per-declaration
          var e0 = e.originalEvent,
            delta = e0.wheelDelta || -e0.detail;
          this.scrollTop += (delta < 0 ? 1 : -1) * 30;
          e.preventDefault();
        }
      });

      // Scroll to top button appear
      $(document).on('scroll', function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
          $('.scroll-to-top').fadeIn();
        } else {
          $('.scroll-to-top').fadeOut();
        }
      });

      $('.nav-link.active-link').closest( 'li.parent' ).addClass('active');

      var route = this.router.url;
      var splitRoute = route.split('/');
      if(splitRoute[1] === 'change-password'){
        $('#cpId').addClass('active');
      }
    });
  }

  getShortName() { 
    this.initial=this.userName.split(' ').map(n => n[0]).join('');
    // console.log(this.initial);
  }

  doLogout() {
    localStorage.removeItem(ApplicationConstants.GROUP_CONFIGS_KEY);
    this.tokenService.clearSession();
    this.router.navigate(['login']);
  }
  changePassword(){
    this.router.navigate(['change-password',this.tokenService.getuserProfileId()]);
  }
}
