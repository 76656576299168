import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'projects/system-portal/src/environments/environment';
import { AppResponse } from 'src/app/model/AppResponse';
import { HttpTransactionService } from 'src/app/services/http-transaction.service';
import { TokenStorageService } from '../../auth-token/services/token-storage.service';
import { ApplicationConstants } from '../../utils/ApplicationConstants';
import { ApplicationURIConstants } from '../../utils/ApplicationURIConstants';
import { JwtHelperService } from "@auth0/angular-jwt";
import { takeWhile } from 'rxjs/operators';

const helper = new JwtHelperService();
 
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  resData: AppResponse;
  alive : boolean;
  
  constructor(private httpService: HttpTransactionService, private router: Router, private tokenService: TokenStorageService) {
  }


  ngOnInit() {

  
   this.httpService.get(environment.BASE_URL + ApplicationURIConstants.GROUP_LIST).subscribe(res => {
       if (res.status === 401) {
         //401 token related issue
         this.tokenService.clearSession();
        this.router.navigate(['login']);
       } else if (res.status === 403) {
         //403 URL not accessible
         this.router.navigate(['home']);
       }
     });

    
  }

}
