import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "../../utils/common-service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  ValidationErrors,
  FormControl,
} from "@angular/forms";
import { HttpTransactionService } from "src/app/services/http-transaction.service";
import { AppRegExConstants } from "../../utils/AppRegExConstants";
import { AppResponse } from "src/app/model/AppResponse";
import { ApplicationConstants } from "../../utils/ApplicationConstants";
import { ApplicationURIConstants } from "../../utils/ApplicationURIConstants";
import { environment } from "projects/system-portal/src/environments/environment";
import { TokenStorageService } from "projects/group-portal/src/app/auth-token/services/token-storage.service";
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe, formatDate } from "@angular/common";
import * as internal from "assert";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
declare var $: any;

@Component({
  selector: 'app-activate-subscriber',
  templateUrl: './activate-subscriber.component.html',
  styleUrls: ['./activate-subscriber.component.css']
})
export class ActivateSubscriberComponent implements OnInit {
 
  @ViewChild("phoneInpt2", { static: false }) phoneInpt2: ElementRef;
  @ViewChild("myInput", { static: true }) myInput: ElementRef;

  editSubscriberForm: FormGroup;
  groupMasterId: string;
  resData: AppResponse;
  appResponse: AppResponse;
  gaResponse: AppResponse;
  subscriptionResponse: AppResponse;
  submitted = false;
  intlObj: any;
  sdp: boolean = false;
  contactNum: any;
  isError: boolean = true;
  countryCode: any;
  countryCodeLength: any;
  baseamount: any;
  addtionalHouseCount: any;
  specialDiscount: number;
  specialDiscountType: any;
  tempAmount: any;
  costPerUnit: any;
  temp: any;
  temp2: any;
  totalAmount: any;
  totalAmountrounded: number;
  dollorType: boolean = false;
  percentageType: boolean = false;
  remarks: boolean = false;
  flag: boolean = false;
  subscriberStatus: boolean = false;
  addedGA = [];
  sdp2: boolean;
  sdp3: boolean;
  sd:boolean=false;
  sdvalid:boolean=false;
  dollorlimiterror: boolean;
  showpherror: boolean = false;
  finalamount: number;
  showbaseamounterror: boolean;

  subscriptionConfigResponse: AppResponse;
  subscriptionConfigArr = [];
  public subPlanType: string;
  public subPlanTerm: string;
  public houseCount: number;
  public subConfig = [];
  public tooltip: string = ApplicationConstants.DEFAULT_TOOLTIP_MSG;

  resetFlag: boolean = false;

  public subscriptionPlanYear: any;

  public startDate: Date;
  public endDate: Date;
  public formationDate: Date;
  public tempFormationDate;
  public tempStartDate: Date;
  public tempEndDate: Date;

  public min = new Date(2019, 12, 1, 0, 0);
  public max = new Date();
  public formationmindate = new Date(
    this.max.getFullYear() - 100, 1,
    12,
    10,
    30
  );
  public minDateOfBirth = new Date(1899, 12, 1, 0, 0);
  public maxDateOfBirth = new Date();
  public minLeaseEndDate = new Date(2019, 12, 1, 0, 0);
  public minLeaseEndDateTemp;

  private activateSubscriber = environment.BASE_URL + ApplicationURIConstants.ACTIVATE_SUBSCRIBER;
  amountperhouse: number;
  subplanterm: any;
  subplantype: any;
  totaldiscount: any;
  actualhousecost: number;
  tempper: number;
  specialdiscountinvalid: boolean;
  specialdiscountshow: boolean;

  freeTrail: any;
  hidediscount: boolean = false;
  housecounterr: boolean;
  showpayment: boolean=false;
  dateerror: boolean=false;
  basictrue: boolean;
  extendedtrue: boolean;
  premiumtrue: boolean;
  virtualarcprice: number;
  dedicatedphoneprice: number;
  webhostingprice: number;
  domainprice: number;
  addonamount: number;
  virtualmanagerprice: number;
  ten: number;
  premiumaddonamount: number;
  domaindateold: any;
  showdate: boolean;
  domainnewdate: any;
  domainDateOld: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private httpService: HttpTransactionService,
    private router: Router,
    private commonService: CommonService,
    private tokenService: TokenStorageService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe
  ) {}
  // added by gopal for HB-629 on 31/12/2021
  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.groupMasterId = params.id;
    });
    this.editSubscriberForm = this.formBuilder.group({
      groupName: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.GROUP_NAME)],
      ],
      groupRegistrationNum: ["", [Validators.required]],
      entityIdentityNumber: [
        "",
        [
          Validators.required,
          Validators.pattern(AppRegExConstants.ENTITY_NUMBER),
        ],
      ],
      subscriptionPlanTerm: ["", [Validators.required]],
      subscriptionPlanType: ["", [Validators.required]],
      houseCount: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)],
      ],
      housecost: ["", [Validators.required]], //pattern validation removed -Validators.pattern(AppRegExConstants.DECIMAL_INPUT
      formationDate: ["", [Validators.required]],
      specialDiscount: ["", [Validators.required]],
      discount: [""],
      baseAmount: [""],
      priceperhouse: [""],
      discounttype: [""],
      totalSubscription: [""],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      addressLine1: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)],
      ],
      country: ["", [Validators.pattern(AppRegExConstants.ALPHABETS)]],
      //"email":['',[ Validators.pattern(AppRegExConstants.EMAIL)]],
      zipCode: [
        "",
        [ Validators.required,
          Validators.pattern(AppRegExConstants.DIGITS),
          Validators.minLength(5),
          Validators.maxLength(6),
        ],
      ], //changed validation from ALPHABETS_DIGITS_ONLY to DIGITS
      contact: ["", Validators.required],
      remarks: [""],
      publishkey: [""],
      secretkey: [""],
      accountid: [""],
      purchasesmail: ["", [Validators.pattern(AppRegExConstants.EMAIL)]],
      salesmail: ["", [Validators.pattern(AppRegExConstants.EMAIL)]],
      expensesmail: ["", [Validators.pattern(AppRegExConstants.EMAIL)]],
      suppliersmail: ["", [Validators.pattern(AppRegExConstants.EMAIL)]],
      virtualmanager:[false],
      dedicatedphone: [false],
      virtualarc:[false],
      webhosting: [false],
      domainregistration: [false],
      premiumvirtualarc:[false],

      groupAdmins: this.formBuilder.array([]),
    });
    //get Subscription config
    this.httpService
      .get(
        environment.BASE_URL + ApplicationURIConstants.GET_SUBSCRIPTION_CONFIG
      )
      .subscribe((data) => {
        this.subscriptionConfigResponse = new AppResponse(data);
        // console.log("test"+this.subscriptionConfigResponse.data);
        if (data.status === 200) {
          console.log("aishwaryaaaaa"+this.subscriptionConfigResponse);
          console.log("aishu"+data);
          this.showdomaindate();
          
          if (this.subscriptionConfigResponse.data != null) {
            for (let obj of this.subscriptionConfigResponse.data) {

              this.subscriptionConfigArr.push(obj);
            }
            // console.log(this.subscriptionConfigArr);
          }
        } else if (data.status === 401) {
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(["login"]);
        } else if (data.status === 403) {
          //403 URL not accessible
          this.router.navigate(["home"]);
        }
      });

    //get ga details
    this.httpService
      .get(
        environment.BASE_URL +
          ApplicationURIConstants.GET_ACTIVE_GROUPADMIN_DETAILS +
          "/" +
          this.groupMasterId
      )
      .subscribe((data) => {
        this.gaResponse = new AppResponse(data);
        console.log(this.gaResponse);

        setTimeout(() => {
          //below time set added by aishwaryaa for HB-631 30/12/2021
        }, 2000);
        if (data.status === 200) {
          console.log("b.aishu"+this.gaResponse);
          this.editSubscriberForm.controls['subscriptionPlanType'].valueChanges.subscribe(value => {
            console.log("insdie value change ");
            
              this.editSubscriberForm.controls['premiumvirtualarc'].setValue(false);
              this.editSubscriberForm.controls['virtualarc'].setValue(false);
              this.editSubscriberForm.controls['webhosting'].setValue(false);
              this.editSubscriberForm.controls['domainregistration'].setValue(false);
              this.editSubscriberForm.controls['virtualmanager'].setValue(false);
              this.editSubscriberForm.controls['dedicatedphone'].setValue(false);
            });
        } else if (data.status === 401) {
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(["login"]);
        } else if (data.status === 403) {
          //403 URL not accessible
          this.router.navigate(["home"]);
        }
      });

    
      this.forcheckbox();
    // this.editSubscriberForm.controls['discount'].disable();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.editSubscriberForm.controls;
  }


  showdomaindate() {
    this.domaindateold = this.appResponse.data.domainRegistrationdate;
    
    this.editSubscriberForm.controls['domainregistration'].valueChanges.subscribe(value => {
      console.log("inside value change");
      
      if (value === false) {
        this.showdate = false;
        this.domaindateold = null;
        this.domainnewdate = null;
      } else {
        this.showdate = true;
        if (!this.domainnewdate) {
          this.domainnewdate = new Date();
        }
      }
    });
  }
  
  
  onSubmit() {

// console.log(this.startDate);
// console.log(this.editSubscriberForm.controls['startDate'].value);



    if(this.editSubscriberForm.controls['startDate'].value == this.startDate){
      this.dateerror=true;
      // console.log(this.dateerror);
      return;
      
    }else {
      this.dateerror=false;
      // console.log(this.dateerror);
    
    }

    console.log("insideeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
    
    // if (this.intlObj.getNumber().length == 0) {
    //   this.showpherror = true;
    //   this.isError = true;
    // } else {
    //   this.showpherror = false;
    //   this.isError = false;
    // }

    // if(this.editSubscriberForm.get("groupAdmins").value.length == 0){
    //   this.commonService.showNotificationSuccess(
    //     ApplicationConstants.MSG_BOX_LABEL,
    //     ApplicationConstants.DANGER_LABLE,
    //     "Please Add Atleast One Group Admin"
    //   );
    //   return;
    // }
    if (this.sdp || this.sdp2 || this.sdp3 || this.sd || this.sdvalid || this.dollorlimiterror || !this.isError || this.showpherror) {
      return;
    }

    this.appResponse.data.domainRegistrationdate=new Date(this.domaindateold)
    // console.log(this.domaindateold);
    // console.log(this.domainnewdate);
   
this.showdomaindate();

    this.check();
    this.submitted = true;
    // // console.log("FORM:" + JSON.stringify(this.editSubscriberForm.value));
    // for (let el in this.editSubscriberForm.controls) {
    //   if (this.editSubscriberForm.controls[el].errors) {
    //     console.log("Invalid :::" + el + "::::")
    //   }
    // }
    if (this.intlObj.getNumber().length == 0) {
      return;
    }
    if (this.editSubscriberForm.invalid) {
      console.log("inside invalis block ");

      return;
    } else {
      console.log("test is in onsubmit else");
      var formData = new FormData();
      formData.append("groupMasterId", this.groupMasterId);
      formData.append(
        "groupName",
        this.editSubscriberForm.controls["groupName"].value.trim()
      );
      formData.append(
        "groupRegistrationNum",
        this.editSubscriberForm.controls["groupRegistrationNum"].value
      );
      formData.append(
        "entityIdentityNumber",
        this.editSubscriberForm.controls["entityIdentityNumber"].value
      );
      formData.append(
        "subscriptionPlanType",
        this.editSubscriberForm.controls["subscriptionPlanType"].value
      );
      formData.append(
        "formationDate",
        this.editSubscriberForm.controls["formationDate"].value
        
      );
      formData.append(
        "subscriptionPlanTerm",
        this.editSubscriberForm.controls["subscriptionPlanTerm"].value
      );
      formData.append(
        "groupAdminsData",
        JSON.stringify(this.editSubscriberForm.get("groupAdmins").value)
      );
      formData.append(
        "houseCount",
        this.editSubscriberForm.controls["houseCount"].value
      );
      formData.append(
        "addressLine1",
        this.editSubscriberForm.controls["addressLine1"].value
      );
      formData.append(
        "housecost",
        this.editSubscriberForm.controls["housecost"].value
      );
      formData.append(
        "zipCode",
        this.editSubscriberForm.controls["zipCode"].value
      );
      formData.append(
        "specialDiscount",
        this.editSubscriberForm.controls["specialDiscount"].value
      );
      formData.append(
        "totalSubscription",
        this.editSubscriberForm.controls["totalSubscription"].value
      );
      // formData.append("price", this.editSubscriberForm.controls['price'].value);
      formData.append(
        "discount",
        this.editSubscriberForm.controls["discount"].value
      );
      formData.append(
        "discountType",
        this.editSubscriberForm.controls["discounttype"].value
      );
      formData.append(
        "country",
        this.editSubscriberForm.controls["country"].value
      );
      formData.append(
        "startDate",
        this.editSubscriberForm.controls["startDate"].value
      );
      formData.append("contact", this.intlObj.getNumber());
      formData.append(
        "endDate",
        this.editSubscriberForm.controls["endDate"].value
      );
      formData.append("status", this.appResponse.data.status);
      formData.append(
        "remarks",
        this.editSubscriberForm.controls["remarks"].value
      );
      formData.append(
        "publishkey",
        this.editSubscriberForm.controls["publishkey"].value
      );
      formData.append(
        "secretkey",
        this.editSubscriberForm.controls["secretkey"].value
      );
      formData.append(
        "accountid",
        this.editSubscriberForm.controls["accountid"].value
      );
      formData.append(
        "purchasesMail",
        this.editSubscriberForm.controls["purchasesmail"].value
      );
      formData.append(
        "suppliersMail",
        this.editSubscriberForm.controls["suppliersmail"].value
      );
      formData.append(
        "expensesMail",
        this.editSubscriberForm.controls["expensesmail"].value
      );
      formData.append(
        "salesMail",
        this.editSubscriberForm.controls["salesmail"].value
      );
      formData.append(
        "domainregistration",
        this.editSubscriberForm.controls["domainregistration"].value
      );
      formData.append(
        "virtualmanager",
        this.editSubscriberForm.controls["virtualmanager"].value
      );
      formData.append(
        "dedicatedphone",
        this.editSubscriberForm.controls["dedicatedphone"].value
      );
      formData.append(
        "virtualarc",
        this.editSubscriberForm.controls["virtualarc"].value
      );  formData.append(
        "premiumvirtualarc",
        this.editSubscriberForm.controls["premiumvirtualarc"].value
      );
      formData.append(
        "webhosting",
        this.editSubscriberForm.controls["webhosting"].value
      );
      formData.append(
        "actions",
        "Activated"
      );
      const currentDateTime = new Date();
      const domainNewDateTime = new Date(this.domainnewdate);
      
      // Extract the date parts without the time
      const currentDate = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate());
      const domainNewDate = new Date(domainNewDateTime.getFullYear(), domainNewDateTime.getMonth(), domainNewDateTime.getDate());

      this.domainDateOld = new Date(this.domaindateold.getFullYear(), this.domaindateold.getMonth(), this.domaindateold.getDate()+1);

      // console.log(domainNewDate.getTime() === currentDate.getTime());
      
      if (domainNewDate.getTime() === currentDate.getTime()) {
        console.log("Inside if");
        formData.append("domainregistrationdate", this.domainnewdate);
      } else {
        formData.append("domainregistrationdate",this.domainDateOld);
      }

      // console.log("FORM:" + JSON.stringify(formData));

      this.httpService
        .update(formData, this.activateSubscriber)
        .subscribe((res) => {
          this.resData = new AppResponse(res);
          console.log(this.resData);
          if (res.status == 200) {
            this.submitted = false;
            //this.editSubscriberForm.reset();
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.SUCCESS_LABLE,
              ApplicationConstants.GRP_UPDATE_MSG
            );
            setTimeout(() => {
              this.router.navigate(["subscriber-list"]);
              //below time set added by aishwaryaa for HB-631 30/12/2021
            }, 2000);
          } else {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.resData.errors}`
            );
          }
        });
    }
  }

  //contact field fn
  numberOnly(event): boolean {
    this.showpherror = false;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode == 40 || charCode == 41 || charCode == 45 || charCode == 32)
        return true;
      else return false;
    }
    return true;
  }

  check() {
    Object.keys(this.editSubscriberForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors =
        this.editSubscriberForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }

  checkForError(obj) {
    if (obj.target.value.length == 0) {
      this.isError = true;
      this.showpherror = true;
    } else {
      this.showpherror = false;
      this.isError = false;
    }

    this.resetFlag = false; //HB-426 Hevisa Patel 28-01-2021
    this.myInput.nativeElement.blur();
    $("#phoneInput").focus();
  }

  telInputObject(obj) {
    this.intlObj = obj;
    this.flag = true;
    this.flag = true;
    this.countryCode = obj.dialCode;
    this.showpherror = false;
  }

  onCountryChange(country: any) {
    this.countryCode = country.dialCode;
    if (this.flag) this.intlObj.setNumber("");
    else this.flag = true;
  }

  hasError(obj) {
    this.isError = obj;
    if (this.intlObj.getNumber().length == 0) {
      this.isError = true;
      this.showpherror = true;
    } else {
      this.showpherror = false;
      this.isError = false;
    }
    if (obj) {
      this.isError = obj;
    }
  }

  getNumber(obj) {
    // console.log("*******" + JSON.stringify(obj));
  }

  changeSpecialDiscountAdditionalHouseCost() {
    this.housecounterr = false;
    if (this.editSubscriberForm.controls["houseCount"].value == 0) {
      this.housecounterr = true;
    }
    if (this.editSubscriberForm.controls["houseCount"].value) {
      console.log("true");
    } else {
      this.housecounterr = false;
    }
    this.subPlanTerm =
      this.editSubscriberForm.controls["subscriptionPlanTerm"].value;
    // this.freeTrail = this.editSubscriberForm.controls['trailperiod'].value;
    this.subPlanType =
      this.editSubscriberForm.controls["subscriptionPlanType"].value;
    this.houseCount = this.editSubscriberForm.controls["houseCount"].value;
    this.editSubscriberForm.controls["housecost"].setValue(0);
    this.editSubscriberForm.controls["specialDiscount"].setValue(0);
    this.tooltip = ApplicationConstants.DEFAULT_TOOLTIP_MSG;
    this.subConfig = [];

    if (
      this.subscriptionConfigArr &&
      this.subPlanTerm != "" &&
      this.subPlanType != "" &&
      this.houseCount &&
      this.subscriptionConfigResponse.data
    ) {
      if (this.houseCount > ApplicationConstants.DEFAULT_HOUSE_COUNT) {
        this.hidediscount = true;

        if (this.houseCount == 0) {
          this.housecounterr = true;
        }
        console.log("inside above 235 in dis");

        // console.log(this.subConfig);

        this.subscriptionConfigArr.forEach((currentElement) => {
          console.log("in for");

          if (
            currentElement.subscriptionplanterm.toLowerCase() ==
              this.subPlanTerm &&
            currentElement.subscriptionplantype.toLowerCase() ==
              this.subPlanType.toLowerCase()
          ) {
            console.log("in  one if ");

            // console.log(currentElement.subscriptionplanterm.toLowerCase() + currentElement.subscriptionplantype.toLowerCase());
            // console.log(currentElement);

            this.subConfig.push(currentElement);
            // console.log(this.subConfig.length);
          }
        });
        // console.log(this.subConfig);
      } else {
        this.hidediscount = false;
        if (this.houseCount == 0) {
          this.housecounterr = true;
        } else {
          this.housecounterr = false;
        }
        console.log(this.houseCount);

        console.log("inside below 235 in dis");
        // this.subConfig.forEach(i=>{
        //   // this.subConfig.pop();
        //   console.log(i);

        // })

        // console.log(this.subConfig);

        this.subscriptionConfigArr.forEach((currentElement) => {
          // console.log(currentElement.subscriptionplanterm.toLowerCase() + currentElement.subscriptionplantype.toLowerCase());
          if (
            currentElement.subscriptionplanterm.toLowerCase() ==
              this.subPlanTerm &&
            currentElement.subscriptionplantype.toLowerCase() ==
              this.subPlanType.toLowerCase()
          ) {
            // console.log(currentElement.subscriptionplanterm.toLowerCase() + currentElement.subscriptionplantype.toLowerCase());
            //   console.log(currentElement);
            this.subConfig.push(currentElement);
            // this.subConfig[0].additionalpriceperhouse=0;
            //  this.subConfig[0].specialdiscount=0;
            //  console.log(this.subConfig.length);
          }
          // console.log(this.subConfig);

          // console.log(this.subConfig[0].specialdiscount);
          // console.log(this.subConfig[0].additionalpriceperhouse);
        });

        // if(this.subplantype=='Basic'){
        //   this.editSubscriberForm.controls['totalcostperhouse'].setValue(1);
        // }else if(this.subplantype=='Extended'){
        //   // this.subConfig[0].price=658;
        //   this.editSubscriberForm.controls['totalcostperhouse'].setValue(2);
        // }else{
        //   // this.subConfig[0].price=1316;
        //   this.editSubscriberForm.controls['totalcostperhouse'].setValue(4);
        // }
      }

      // console.log(this.subConfig);

      if (this.subConfig) {
        console.log(this.subConfig);

        this.editSubscriberForm.controls["housecost"].setValue(
          this.subConfig[0].additionalpriceperhouse
        );
        this.editSubscriberForm.controls["specialDiscount"].setValue(
          this.subConfig[0].specialdiscount
        );
        console.log(this.subConfig[0].price);

        // this.editSubscriberForm.controls['baseamount'].setValue(this.subConfig[0].price);

        this.tooltip = this.subConfig[0].tooltip;
        this.baseamount = this.subConfig[0].price;
        this.editSubscriberForm.controls["baseAmount"].setValue(
          this.baseamount
        );
        // console.log( this.baseamount);
      }
    }
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }



  varc(){
    console.log(this.editSubscriberForm.controls['virtualarc'].value);
    
      }
      hosting(){
        console.log(this.editSubscriberForm.controls['webhosting'].value);
      }
      domain(){
        console.log(this.editSubscriberForm.controls['domainregistration'].value);
      }
      dphonenumber(){
        console.log(this.editSubscriberForm.controls['dedicatedphone'].value);
      }
      vmanager(){
        console.log(this.editSubscriberForm.controls['virtualmanager'].value);
      }
     vparc(){
    console.log(this.editSubscriberForm.controls['virtualarc'].value);
    
      }
    
    
    
      forcheckbox(){
        if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Basic'){
          this.basictrue=true;
          this.extendedtrue=false;
          this.premiumtrue=false;
          this.plantypenull();
        }else if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Extended'){
          this.extendedtrue=true;
          this.basictrue=false;
          this.premiumtrue=false;
          this.plantypenull();
        }else if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Premium'){
          this.premiumtrue=true;
          this.basictrue=false;
          this.extendedtrue=false;
          this.plantypenull();
        }
        
      }

      plantypenull(){
        this.editSubscriberForm.controls['subscriptionPlanType'].valueChanges.subscribe(value => {
          console.log("insdie value change ");
          
            this.editSubscriberForm.controls['premiumvirtualarc'].setValue(false);
            this.editSubscriberForm.controls['virtualarc'].setValue(false);
            this.editSubscriberForm.controls['webhosting'].setValue(false);
            this.editSubscriberForm.controls['domainregistration'].setValue(false);
            this.editSubscriberForm.controls['virtualmanager'].setValue(false);
            this.editSubscriberForm.controls['dedicatedphone'].setValue(false);
          });
      }
  calculate() {
    // if( this.houseCount && this.specialDiscount && this.specialDiscountType != null){
    if (this.editSubscriberForm.controls["discount"].value != null) {
      if(this.editSubscriberForm.controls["discounttype"].value=="dollor"&&this.editSubscriberForm.controls["discount"].value==0){
        this.sdvalid=true;
        this.sd=false;
      }else {
        this.sdvalid=false;
        this.sd=false;
      }
      this.remarks = true;
      // console.log("inside spl if")
    } else {
      this.remarks = false;
      if(this.editSubscriberForm.controls["discounttype"].value=="dollor"){
        // console.log("inside null dollor");
        
        this.sd=true;
        this.sdvalid=false;
      }else{
        this.sd=false;
        this.sdvalid=false;
      }
    }
    
    // console.log("base amount"+ this.baseamount);
    // console.log("housecount"+ this.houseCount);
    // this.costPerUnit=this.subConfig[0].additionalpriceperhouse;
    // console.log("cost per unit"+ this.costPerUnit);
    
    // console.log("Special discount: " +  this.specialDiscount);
    
    this.subplanterm = this.subConfig[0].subscriptionplanterm;
    this.subplantype = this.subConfig[0].subscriptionplantype;
    this.virtualarcprice=this.subConfig[0].virtualarc;
    this.virtualmanagerprice=this.subConfig[0].virtualmanager;
    this.dedicatedphoneprice=this.subConfig[0].dedicatedphone;
    this.webhostingprice=this.subConfig[0].webhosting;
    this.domainprice=this.subConfig[0].domainregistration;

    console.log(this.virtualarcprice+"virtual arc");
    console.log(this.virtualmanagerprice+"virtual manager");
    console.log(this.dedicatedphoneprice+"dedicated phone");
    console.log(this.webhostingprice+"web hosting");
    console.log(this.domainprice +"domain registration");
    console.log(this.subplanterm);
    console.log(this.subplantype);

    this.specialDiscountType =
      this.editSubscriberForm.controls["discounttype"].value;
    // document.getElementById("radio1").checked
    if (
      this.specialDiscountType == "percentage" ||
      this.specialDiscountType == "dollor"
    ) {

    // this.editSubscriberForm.controls['discount'].enable();

      // console.log("inside if per ");

      this.specialdiscountshow = true;
      // this.form.controls['Form controller name'].disable();
    } else {
      // console.log("inside else per ");
      this.specialdiscountshow = false;
    }
    // console.log("Special discount Type: " +  this.specialDiscountType);

    this.specialDiscount = this.editSubscriberForm.controls["discount"].value;
    if (this.houseCount >= 235) {
      this.addtionalHouseCount = this.houseCount - 235;
      // console.log(this.addtionalHouseCount);
    } else if (this.houseCount < 235) {
      this.sdp = false;
      this.sdp2 = false;
      this.sdp3 = false;
      this.totalAmount = this.houseCount;
      if (this.specialDiscountType == "dollor") {
        if (this.specialDiscount > this.baseamount) {
          this.showbaseamounterror = true;
        } else {
          this.showbaseamounterror = false;

          this.finalamount = this.baseamount - this.specialDiscount;
        }
      } else {
        this.showbaseamounterror = false;
        this.finalamount = this.baseamount;
      }
      // if(this.specialDiscount==''){
      //   this.finalamount=this.baseamount-this.specialDiscount
      // }
      // this.editSubscriberForm.controls["totalSubscription"].setValue(
      //   this.finalamount
      // );
      // console.log("near function for checkbox");
      
      if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Basic'){
        this.editSubscriberForm.controls["totalSubscription"].setValue(
            this.finalamount );
            
      }else if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Extended'){
        this.addonamount=0;
        if(this.editSubscriberForm.controls['virtualarc'].value==true){
                this.addonamount+=Number(this.virtualarcprice);
        } if(this.editSubscriberForm.controls['virtualmanager'].value==true){
          this.addonamount+=Number(this.virtualmanagerprice);
        } if(this.editSubscriberForm.controls['dedicatedphone'].value==true){
          this.addonamount+=Number(this.dedicatedphoneprice);
        } if(this.editSubscriberForm.controls['webhosting'].value==true){
          this.addonamount+=Number(this.webhostingprice);
        } if(this.editSubscriberForm.controls['domainregistration'].value==true){
          this.addonamount+=Number(this.domainprice);
        }
this.editSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.addonamount));
        
      }else if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Premium'){
     
        if(this.addonamount>0){
          console.log("inside if");
          
          this.editSubscriberForm.controls["totalSubscription"].setValue(this.finalamount);
          this.premiumaddonamount=0;
          if(this.editSubscriberForm.controls['premiumvirtualarc'].value==true){

            console.log("inside premium if");
            
            this.premiumaddonamount+=Number(this.virtualarcprice); 
            this.editSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.premiumaddonamount));
          }else{
            this.editSubscriberForm.controls["totalSubscription"].setValue(this.finalamount);
          }
        }else{
          this.editSubscriberForm.controls["totalSubscription"].setValue(this.finalamount);
          this.premiumaddonamount=0;
          console.log("inside premium if");

          if(this.editSubscriberForm.controls['premiumvirtualarc'].value==true){
            this.premiumaddonamount+=Number(this.virtualarcprice); 
            this.editSubscriberForm.controls["totalSubscription"].setValue(Number(this.finalamount) +Number(this.premiumaddonamount));
          }else{
            this.editSubscriberForm.controls["totalSubscription"].setValue(this.finalamount);
          }
        }
      }
console.log(this.finalamount + this.addonamount);

      return this.baseamount;
    }
    // console.log("Additional house count"+ this.addtionalHouseCount);

    this.specialDiscount = this.editSubscriberForm.controls["discount"].value;
    console.log(this.specialDiscount + "special discoubt");
    if (this.specialDiscountType == "percentage") {
      this.dollorlimiterror=false;
      // if (this.specialDiscount <= 90 && this.specialDiscount > 0) {
        
        if (this.subplanterm == "1year") {
          this.totaldiscount = Number(this.specialDiscount) + 10;
        } else if (this.subplanterm == "2year") {
          this.totaldiscount = Number(this.specialDiscount) + 20;
        } else if (this.subplanterm == "3year") {
          this.totaldiscount = Number(this.specialDiscount) + 30;
        }
        if (this.subplantype == "Basic") {
          this.actualhousecost = 1;
        } else if (this.subplantype == "Extended") {
          this.actualhousecost = 2;
        } else {
          this.actualhousecost = 4;
        }
        console.log(this.totaldiscount);
        console.log(this.actualhousecost);

        // console.log("temamount" + this.tempAmount);
        this.sdp = false;
        // console.log("percentage con");
        // console.log(this.actualhousecost);

        this.tempper = (this.actualhousecost / 100) * this.totaldiscount;
        console.log(this.tempper + "tempper");

        this.amountperhouse = this.actualhousecost - this.tempper;
        console.log(this.amountperhouse + "amount per hopuse");

        this.editSubscriberForm.controls["priceperhouse"].setValue(
          this.amountperhouse.toFixed(2)
        );
        this.tempAmount = this.addtionalHouseCount * this.amountperhouse;
        console.log(this.tempAmount + "temp amoiunt");

        // this.temp = this.tempAmount/100 * this.totaldiscount;
        // console.log(this.temp);
        // this.temp2 = this.tempAmount - this.temp;
        // console.log(this.temp2);
        this.totalAmount = this.baseamount + this.tempAmount;
        console.log(this.totalAmount + "total am ount ");

        // console.log(this.totalAmount);
        // console.log("test total amount percentage test"+ this.totalAmount.toFixed(2));
        this.totalAmount = this.totalAmount.toFixed(2);
        // console.log(this.totalAmount);
        // this.totalAmount=this.totalAmount.toFixed(2);
        // console.log(this.totalAmount);
        // this.editSubscriberForm.controls["totalSubscription"].setValue(
        //   this.totalAmount
        // );
       
      if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Basic'){
        this.editSubscriberForm.controls["totalSubscription"].setValue(
            this.totalAmount );
            
      }else if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Extended'){
        this.addonamount=0;
        if(this.editSubscriberForm.controls['virtualarc'].value==true){
                this.addonamount+=Number(this.virtualarcprice);
        } if(this.editSubscriberForm.controls['virtualmanager'].value==true){
          this.addonamount+=Number(this.virtualmanagerprice);
        } if(this.editSubscriberForm.controls['dedicatedphone'].value==true){
          this.addonamount+=Number(this.dedicatedphoneprice);
        } if(this.editSubscriberForm.controls['webhosting'].value==true){
          this.addonamount+=Number(this.webhostingprice);
        } if(this.editSubscriberForm.controls['domainregistration'].value==true){
          this.addonamount+=Number(this.domainprice);
        }
this.editSubscriberForm.controls["totalSubscription"].setValue(Number(this.totalAmount) +Number(this.addonamount));
        
      }else if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Premium'){
     
        if(this.addonamount>0){
          console.log("inside if");
          
          this.editSubscriberForm.controls["totalSubscription"].setValue(this.totalAmount);
          this.premiumaddonamount=0;
          if(this.editSubscriberForm.controls['premiumvirtualarc'].value==true){

            console.log("inside premium if");
            
            this.premiumaddonamount+=Number(this.virtualarcprice); 
            this.editSubscriberForm.controls["totalSubscription"].setValue(Number(this.totalAmount) +Number(this.premiumaddonamount));
          }else{
            this.editSubscriberForm.controls["totalSubscription"].setValue(this.totalAmount);
          }
        }else{
          this.editSubscriberForm.controls["totalSubscription"].setValue(this.totalAmount);
          this.premiumaddonamount=0;
          console.log("inside premium if");

          if(this.editSubscriberForm.controls['premiumvirtualarc'].value==true){
            this.premiumaddonamount+=Number(this.virtualarcprice); 
            this.editSubscriberForm.controls["totalSubscription"].setValue(Number(this.totalAmount) +Number(this.premiumaddonamount));
          }else{
            this.editSubscriberForm.controls["totalSubscription"].setValue(this.totalAmount);
          }
        }
      }
        // console.log( this.editSubscriberForm.controls['totalSubscription'].value)
      // }
      if (this.subplanterm == "1year") {
        // this.totaldiscount=this.specialDiscount+10;
        if (this.specialDiscount <= 90 && this.specialDiscount > 0) {
        } else {
          this.sdp = true;
          this.sdp2 = false;
          this.sdp3 = false;
        }
      } else if (this.subplanterm == "2year") {
        // this.totaldiscount=this.specialDiscount+20;
        if (this.specialDiscount <= 80 && this.specialDiscount > 0) {
          this.sdp2 = false;
        } else {
          this.sdp2 = true;
          this.sdp = false;
          this.sdp3 = false;
        }
      } else if (this.subplanterm == "3year") {
        // this.totaldiscount=this.specialDiscount+30;
        if (this.specialDiscount <= 70 && this.specialDiscount > 0) {
          this.sdp3 = false;
        } else {
          this.sdp3 = true;
          this.sdp2 = false;
          this.sdp = false;
        }
      }
    } else if (this.specialDiscountType == "dollor") {
      this.sdp = false;
      this.sdp2 = false;
      this.sdp3 = false;
      this.specialdiscountinvalid = false;
      // console.log("dollor con");
      // if(this.tempAmount>=this.specialDiscount){
      //   this.temp = this.tempAmount-this.specialDiscount;
      //   // console.log(this.temp)
      //   this.totalAmount=this.baseamount+this.temp;
      //   this.totalAmount=this.totalAmount.toFixed(2);
      //   // console.log(this.totalAmount);
      //   // console.log(this.totalAmount);
      //   // console.log("test total amountttttt"+ this.totalAmount.toFixed(2))
      //   this.editSubscriberForm.controls['totalSubscription'].setValue(this.totalAmount);
      //   // console.log("total sub"+ this.editSubscriberForm.controls['totalSubscription'].value)
      // }
      if (this.houseCount >= 235) {
        this.addtionalHouseCount = this.houseCount - 235;
        // console.log(this.addtionalHouseCount);
      } else if (this.houseCount < 235) {
        console.log(this.totalAmount + "below 235 house u");
        this.totalAmount = this.houseCount;
        this.finalamount = this.baseamount - this.specialDiscount;
        this.editSubscriberForm.controls["totalSubscription"].setValue(
          this.finalamount
        );
        console.log(this.finalamount + "below 235 house c");
        return this.baseamount;
      }
      if (this.subplanterm == "1year") {
        this.totaldiscount = 10;
      } else if (this.subplanterm == "2year") {
        this.totaldiscount = 20;
      } else {
        this.totaldiscount = 30;
      }
      if (this.subplantype == "Basic") {
        this.actualhousecost = 1;
      } else if (this.subplantype == "Extended") {
        this.actualhousecost = 2;
      } else {
        this.actualhousecost = 4;
      }
      console.log(this.totaldiscount);
      console.log(this.actualhousecost);

      this.tempper = (this.actualhousecost / 100) * this.totaldiscount;
      this.amountperhouse = this.actualhousecost - this.tempper;

      this.editSubscriberForm.controls["priceperhouse"].setValue(
        this.amountperhouse.toFixed(2)
      );

      this.tempAmount = this.addtionalHouseCount * this.amountperhouse;
      this.tempAmount = this.tempAmount - this.specialDiscount;
      //
      this.totalAmount = this.baseamount + this.tempAmount;
      // console.log(this.totalAmount);
      // console.log("test total amount percentage test"+ this.totalAmount.toFixed(2));
      this.totalAmount = this.totalAmount.toFixed(2);
      // console.log(this.totalAmount);
      // this.totalAmount=this.totalAmount.toFixed(2);
      // console.log(this.totalAmount);
      if (this.totalAmount < 0) {
        this.dollorlimiterror = true;
      } else {
        this.dollorlimiterror = false;
      }
      // this.editSubscriberForm.controls["totalSubscription"].setValue(
      //   this.totalAmount
      // );

      
      if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Basic'){
        this.editSubscriberForm.controls["totalSubscription"].setValue(
            this.totalAmount );
            
      }else if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Extended'){
        this.addonamount=0;
        if(this.editSubscriberForm.controls['virtualarc'].value==true){
                this.addonamount+=Number(this.virtualarcprice);
        } if(this.editSubscriberForm.controls['virtualmanager'].value==true){
          this.addonamount+=Number(this.virtualmanagerprice);
        } if(this.editSubscriberForm.controls['dedicatedphone'].value==true){
          this.addonamount+=Number(this.dedicatedphoneprice);
        } if(this.editSubscriberForm.controls['webhosting'].value==true){
          this.addonamount+=Number(this.webhostingprice);
        } if(this.editSubscriberForm.controls['domainregistration'].value==true){
          this.addonamount+=Number(this.domainprice);
        }
this.editSubscriberForm.controls["totalSubscription"].setValue(Number(this.totalAmount) +Number(this.addonamount));
        
      }else if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Premium'){
     
        if(this.addonamount>0){
          console.log("inside if");
          
          this.editSubscriberForm.controls["totalSubscription"].setValue(this.totalAmount);
          this.premiumaddonamount=0;
          if(this.editSubscriberForm.controls['premiumvirtualarc'].value==true){

            console.log("inside premium if");
            
            this.premiumaddonamount+=Number(this.virtualarcprice); 
            this.editSubscriberForm.controls["totalSubscription"].setValue(Number(this.totalAmount) +Number(this.premiumaddonamount));
          }else{
            this.editSubscriberForm.controls["totalSubscription"].setValue(this.totalAmount);
          }
        }else{
          this.editSubscriberForm.controls["totalSubscription"].setValue(this.totalAmount);
          this.premiumaddonamount=0;
          console.log("inside premium if");

          if(this.editSubscriberForm.controls['premiumvirtualarc'].value==true){
            this.premiumaddonamount+=Number(this.virtualarcprice); 
            this.editSubscriberForm.controls["totalSubscription"].setValue(Number(this.totalAmount) +Number(this.premiumaddonamount));
          }else{
            this.editSubscriberForm.controls["totalSubscription"].setValue(this.totalAmount);
          }
        }
      }
    } else {
      if (this.houseCount >= 235) {
        console.log("inside above");

        this.addtionalHouseCount = this.houseCount - 235;
        if (this.subplanterm == "1year") {
          this.totaldiscount = 10;
        } else if (this.subplanterm == "2year") {
          this.totaldiscount = 20;
        } else {
          this.totaldiscount = 30;
        }
        if (this.subplantype == "Basic") {
          this.actualhousecost = 1;
        } else if (this.subplantype == "Extended") {
          this.actualhousecost = 2;
        } else {
          this.actualhousecost = 4;
        }
        this.tempper = (this.actualhousecost / 100) * this.totaldiscount;
        this.amountperhouse = this.actualhousecost - this.tempper;
        this.editSubscriberForm.controls["priceperhouse"].setValue(
          this.amountperhouse.toFixed(2)
        );
        this.tempAmount = this.addtionalHouseCount * this.amountperhouse;
        console.log(this.totaldiscount);
        this.totalAmount = this.baseamount + this.tempAmount;

        this.totalAmount = this.totalAmount.toFixed(2);

        // this.editSubscriberForm.controls["totalSubscription"].setValue(
        //   this.totalAmount
        // );
        
      if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Basic'){
        this.editSubscriberForm.controls["totalSubscription"].setValue(
            this.totalAmount );
            
      }else if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Extended'){
        this.addonamount=0;
        if(this.editSubscriberForm.controls['virtualarc'].value==true){
                this.addonamount+=Number(this.virtualarcprice);
        } if(this.editSubscriberForm.controls['virtualmanager'].value==true){
          this.addonamount+=Number(this.virtualmanagerprice);
        } if(this.editSubscriberForm.controls['dedicatedphone'].value==true){
          this.addonamount+=Number(this.dedicatedphoneprice);
        } if(this.editSubscriberForm.controls['webhosting'].value==true){
          this.addonamount+=Number(this.webhostingprice);
        } if(this.editSubscriberForm.controls['domainregistration'].value==true){
          this.addonamount+=Number(this.domainprice);
        }
this.editSubscriberForm.controls["totalSubscription"].setValue(Number(this.totalAmount) +Number(this.addonamount));
        
      }else if(this.editSubscriberForm.controls['subscriptionPlanType'].value=='Premium'){
     
        if(this.addonamount>0){
          console.log("inside if");
          
          this.editSubscriberForm.controls["totalSubscription"].setValue(this.totalAmount);
          this.premiumaddonamount=0;
          if(this.editSubscriberForm.controls['premiumvirtualarc'].value==true){

            console.log("inside premium if");
            
            this.premiumaddonamount+=Number(this.virtualarcprice); 
            this.editSubscriberForm.controls["totalSubscription"].setValue(Number(this.totalAmount) +Number(this.premiumaddonamount));
          }else{
            this.editSubscriberForm.controls["totalSubscription"].setValue(this.totalAmount);
          }
        }else{
          this.editSubscriberForm.controls["totalSubscription"].setValue(this.totalAmount);
          this.premiumaddonamount=0;
          console.log("inside premium if");

          if(this.editSubscriberForm.controls['premiumvirtualarc'].value==true){
            this.premiumaddonamount+=Number(this.virtualarcprice); 
            this.editSubscriberForm.controls["totalSubscription"].setValue(Number(this.totalAmount) +Number(this.premiumaddonamount));
          }else{
            this.editSubscriberForm.controls["totalSubscription"].setValue(this.totalAmount);
          }
        }
      }
      
      
  console.log(this.totalAmount + this.addonamount);
       
      } else if (this.houseCount < 235) {
        // console.log("inside below");
        if (this.subplantype == "Basic") {
          this.actualhousecost = 1;
        } else if (this.subplantype == "Extended") {
          this.actualhousecost = 2;
        } else {
          this.actualhousecost = 4;
        }
        this.totalAmount = this.houseCount;

        this.editSubscriberForm.controls["totalcostperhouse"].setValue(
          this.actualhousecost
        );

        this.editSubscriberForm.controls["totalSubscription"].setValue(
          this.baseamount
        );
        return this.baseamount;
      }
    }

    // }
    // else{
    //   console.log("condition failed")
    // }
  }

  changeEndDate(data) {
    if (this.editSubscriberForm.controls["subscriptionPlanTerm"].value != "") {
      if (this.editSubscriberForm.controls["startDate"].value != null) {
        if (
          this.editSubscriberForm.controls["subscriptionPlanTerm"].value ==
          "1year"
        ) {
          this.subscriptionPlanYear = 1;
        } else if (
          this.editSubscriberForm.controls["subscriptionPlanTerm"].value ==
          "2year"
        ) {
          this.subscriptionPlanYear = 2;
        } else if (
          this.editSubscriberForm.controls["subscriptionPlanTerm"].value ==
          "3year"
        ) {
          this.subscriptionPlanYear = 3;
        }
        this.tempEndDate = new Date(
          this.editSubscriberForm.controls["startDate"].value
        );
        this.endDate = new Date(
          this.tempEndDate.getFullYear() + this.subscriptionPlanYear,
          this.tempEndDate.getMonth(),
          this.tempEndDate.getDate()-1
        );
        this.editSubscriberForm.controls["endDate"].setValue(this.endDate);
      } else {
        this.editSubscriberForm.controls["startDate"].setErrors({
          required: true,
        });
      }
    } else {
      this.editSubscriberForm.controls["subscriptionPlanTerm"].setErrors({
        required: true,
      });
    }

    
    if(this.editSubscriberForm.controls['startDate'].value == this.startDate){
      this.dateerror=true;
      // console.log(this.dateerror);
      return;
      
    }else {
      this.dateerror=false;
      // console.log(this.dateerror);
   
    }
  }
  //Editing Groupadmins

  groupAdmins(): FormArray {
    return this.editSubscriberForm.get("groupAdmins") as FormArray;
  }

  newGroupAdmin(): FormGroup {
    return this.formBuilder.group({
      firstName: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)],
      ],
      lastName: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.ALPHABETS)],
      ],
      email: [
        "",
        [Validators.required, Validators.pattern(AppRegExConstants.EMAIL)],
      ],
      contact: [""],
    });
  }

  addGAEmail() {
    this.groupAdmins().push(this.newGroupAdmin());
  }

  removeGAEmail(ga) {
    this.groupAdmins().removeAt(ga);
  }

  //Edit Form
  ngAfterViewInit() {
    this.spinner.show();
    // console.log("-----");

    this.httpService
      .get(
        environment.BASE_URL +
          ApplicationURIConstants.GET_SUBSCRIBER +
          "/" +
          this.groupMasterId
      )
      .subscribe((data) => {
        this.appResponse = new AppResponse(data);
        console.log(this.appResponse);
        this.spinner.hide();

        //   if(this.appResponse.data.discountType == "percentage"){
        //       this.percentageType=true;
        //       this.dollorType=false;
        //   }
        //  else if(this.appResponse.data.discountType == "dollor"){
        //     this.percentageType=false;
        //     this.dollorType=true;
        // }

        setTimeout(() => {
          //below time set added by aishwaryaa for HB-631 30/12/2021
        }, 2000);
        if (data.status === 200) {
          if (this.appResponse.data == null) {
            this.commonService.showNotificationSuccess(
              ApplicationConstants.MSG_BOX_LABEL,
              ApplicationConstants.DANGER_LABLE,
              `${this.appResponse.message}`
            );
            setTimeout(() => {
              this.router.navigate(["subscriber-list"]);
              //below time set added by aishwaryaa for HB-631 30/12/2021
            }, 2000);
            
          } else {
            if(this.appResponse.data.ssn!=""){
              this.showpayment=true;
            }
            if(this.appResponse.data.ssn==null){
              this.showpayment=false;
            }
            this.populateEditSubscriber();
            for (let el in this.editSubscriberForm.controls) {
              if (this.editSubscriberForm.controls[el].errors) {
                console.log("Invalid :::" + el + "::::");
              } else {
                console.log("no error");
              }
            }
          }
          this.editSubscriberForm.controls['subscriptionPlanType'].valueChanges.subscribe(value => {
          console.log("insdie value change ");
          
            this.editSubscriberForm.controls['premiumvirtualarc'].setValue(false);
            this.editSubscriberForm.controls['virtualarc'].setValue(false);
            this.editSubscriberForm.controls['webhosting'].setValue(false);
            this.editSubscriberForm.controls['domainregistration'].setValue(false);
            this.editSubscriberForm.controls['virtualmanager'].setValue(false);
            this.editSubscriberForm.controls['dedicatedphone'].setValue(false);
          });
        } else if (data.status === 401) {
          //401 token related issue
          this.tokenService.clearSession();
          this.router.navigate(["login"]);
        } else if (data.status === 403) {
          //403 URL not accessible
          this.router.navigate(["home"]);
        }
      });
  }
  populateEditSubscriber() {
    console.log(this.appResponse.data)
    if (this.appResponse.data != null) {
      if (this.appResponse.data.startDate != null) {
        this.tempStartDate = new Date();
        this.startDate = new Date(
          this.tempStartDate.getFullYear(),
          this.tempStartDate.getMonth(),
          this.tempStartDate.getDate()
        );
        this.minLeaseEndDateTemp = this.startDate;
        var datetemp = new Date(
          this.minLeaseEndDateTemp.getFullYear(),
          this.minLeaseEndDateTemp.getMonth(),
          this.minLeaseEndDateTemp.getDate() + 1
        );
        this.minLeaseEndDate = datetemp;
      }
      // console.log(this.startDate + ':::' + this.appResponse.data.startDate)
      if (this.appResponse.data.endDate != null) {
        this.tempEndDate = new Date();
        this.endDate = new Date(
          this.tempEndDate.getFullYear()+1,
          this.tempEndDate.getMonth(),
          this.tempEndDate.getDate()-1
        );

        if(this.appResponse.data.subscriptionPlanTerm=='1year'){
          this.endDate = new Date(
            this.tempEndDate.getFullYear()+1,
            this.tempEndDate.getMonth(),
            this.tempEndDate.getDate()-1
          );  
        } if(this.appResponse.data.subscriptionPlanTerm=='2year'){
          this.endDate = new Date(
            this.tempEndDate.getFullYear()+2,
            this.tempEndDate.getMonth(),
            this.tempEndDate.getDate()-1
          );
  
        }if(this.appResponse.data.subscriptionPlanTerm=='3year'){
          this.endDate = new Date(
            this.tempEndDate.getFullYear()+3,
            this.tempEndDate.getMonth(),
            this.tempEndDate.getDate()-1
          );
  
        }
      }

      if (this.appResponse.data.formationDate != null) {
        this.tempFormationDate = new Date(this.appResponse.data.formationDate);
        this.formationDate = new Date(
          this.tempFormationDate.getFullYear(),
          this.tempFormationDate.getMonth(),
          this.tempFormationDate.getDate()
        );
      }
      // console.log(this.endDate)

      this.editSubscriberForm = this.formBuilder.group({
        groupName: [
          this.appResponse.data.groupName,
          [
            Validators.required,
            Validators.pattern(AppRegExConstants.GROUP_NAME),
          ],
        ],
        groupRegistrationNum: [
          this.appResponse.data.groupRegistrationNum,
          [
            Validators.required,
            
          ],
        ],
        entityIdentityNumber: [
          this.appResponse.data.entityIdentityNumber,
          [
            Validators.required,
            Validators.pattern(AppRegExConstants.ENTITY_NUMBER),
          ],
        ],
        subscriptionPlanTerm: [
          this.appResponse.data.subscriptionPlanTerm,
          [Validators.required],
        ],
        subscriptionPlanType: [
          this.appResponse.data.subscriptionPlanType,
          [Validators.required],
        ],
        houseCount: [
          this.appResponse.data.houseCount,
          [Validators.required, Validators.pattern(AppRegExConstants.DIGITS)],
        ],
        housecost: [this.appResponse.data.houseCost, [Validators.required]], //pattern validation remvoed - Validators.pattern(AppRegExConstants.DECIMAL_INPUT)
        formationDate: [this.formationDate, [Validators.required]],
        specialDiscount: [
          this.appResponse.data.specialDiscount,
          [Validators.required],
        ],
        startDate: [this.startDate, [Validators.required]],
        endDate: [this.endDate, [Validators.required]],
        addressLine1: [
          this.appResponse.data.addressLine1,
          [
            Validators.required,
            Validators.pattern(AppRegExConstants.ADDRESS),
            this.noWhitespaceValidator,
          ],
        ],
        remarks: [this.appResponse.data.remarks],
        discount: [this.appResponse.data.discount],
        discounttype: [this.appResponse.data.discounttype],
        totalSubscription: [this.appResponse.data.price],
        country: [
          this.appResponse.data.country,
          [Validators.pattern(AppRegExConstants.ALPHABETS)],
        ],
        zipCode: [
          this.appResponse.data.zipCode,
          [ Validators.required,
            Validators.pattern(AppRegExConstants.ZIPCODE),
            Validators.minLength(5),
            Validators.maxLength(6),
          ],
        ], //validation changed from ALPHABETS_DIGITS_ONLY to DIGITS
        baseAmount: [""],
        priceperhouse: [""],
        publishkey: [this.appResponse.data.publishkey],
        secretkey: [this.appResponse.data.secretkey],
        accountid: [this.appResponse.data.accountid],
        purchasesmail: [
          this.appResponse.data.purchasesMail,
          [Validators.pattern(AppRegExConstants.EMAIL)],
        ],
        salesmail: [
          this.appResponse.data.salesMail,
          [Validators.pattern(AppRegExConstants.EMAIL)],
        ],
        expensesmail: [
          this.appResponse.data.expensesMail,
          [Validators.pattern(AppRegExConstants.EMAIL)],
        ],
        suppliersmail: [
          this.appResponse.data.suppliersMail,
          [Validators.pattern(AppRegExConstants.EMAIL)],
        ],
        virtualmanager:[
          this.appResponse.data.virtualManager==1
          
        ],
        dedicatedphone:[
          this.appResponse.data.dedicatedPhone==1
          
        ], virtualarc:[
          this.appResponse.data.virtualArc==1
          
        ], webhosting:[
          this.appResponse.data.webHosting==1
          
        ], domainregistration:[
          this.appResponse.data.domainRegistration==1
          
        ],premiumvirtualarc:[
          this.appResponse.data.premiumVirtualArc==1
        ],
        groupAdmins: this.formBuilder.array([]),
      });
      console.log(this.appResponse.data.specialDiscount + "type" );



      this.subscriberStatus =
        this.appResponse.data.status == "true" ? true : false;
      //alert(this.appResponse.data.status + ':::'+this.subscriberStatus)
      //Contact
      if (this.appResponse.data.contact != "") {
        this.intlObj.setNumber(this.appResponse.data.contact);
        $("#phoneInput").focus().val(this.appResponse.data.contact);
        this.myInput.nativeElement.blur();
        this.resetFlag = true;
        this.showpherror = false;
      }

      if (this.gaResponse != null) {
        const gaRow = this.editSubscriberForm.get("groupAdmins") as FormArray; // get the array
        gaRow.clear(); // clear the array
        this.gaResponse.data.forEach((element) => {
          let fg = this.newGroupAdmin(); // create the group
          fg.reset(element); // fill the data
          gaRow.push(fg); // add to array
        });
        this.newGroupAdmin();
      }
      // if(this.appResponse.data.discounttype == 'percentage' || this.appResponse.data.discounttype=='dollor' ){
      //   this.editSubscriberForm.controls['discount'].enable();
      //   }else{
      //     this.editSubscriberForm.controls['discount'].disable();
      //   }
      if(this.appResponse.data.discounttype==null){
        this.editSubscriberForm.controls['discounttype'].setValue('dollor')
        this.editSubscriberForm.controls['discount'].setValue(0);
      }
      this.forcheckbox();
      this.changeSpecialDiscountAdditionalHouseCost();
      this.showdomaindate();
      this.calculate();
            
// if(this.appResponse.data.specialDiscount>0){
//   this.editSubscriberForm.controls['discount'].enable();
// }else{
//   this.editSubscriberForm.controls['discount'].disable();
// }
  
      
    }
  }
}

