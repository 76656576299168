import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../components/login/login.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { AuthGuard } from '../auth-token/AuthGuard ';
import { GaListComponent } from '../components/ga-list/ga-list.component';
import { SaConfigListComponent } from '../components/sa-config-list/sa-config-list.component';
import { GroupRegistrationComponent } from '../components/group-registration/group-registration.component';
import { GaViewComponent } from '../components/ga-view/ga-view.component';
import { EditGroupComponent } from '../components/edit-group/edit-group.component';
import { EditProfileComponent } from '../components/edit-profile/edit-profile.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { SystemAdminsListComponent } from '../components/system-admins-list/system-admins-list.component'; //HB-683 Priya
import { SubscriberListComponent } from '../components/subscriber-list/subscriber-list.component';  // HB-630 Priya
import { EditSubscriberComponent } from '../components/edit-subscriber/edit-subscriber.component'; //HB-629 Priya
import { AddSubscriberComponent } from '../components/add-subscriber/add-subscriber.component';  // HB-629 Priya
import { GroupadminsListComponent } from '../components/groupadmins-list/groupadmins-list.component'; // HB-631 Priya
import { DeactivateSubscriberComponent } from '../components/deactivate-subscriber/deactivate-subscriber.component';  // HB-632 Priya
import { ActivateSubscriberComponent } from '../components/activate-subscriber/activate-subscriber.component';  // HB-654 Priya
import { AuditPaymentComponent } from '../components/audit-payment/audit-payment.component';
import { SystemSubscriptionHistoryComponent } from '../components/system-subscription-history/system-subscription-history.component';
import { BillingHistoryComponent } from '../components/billing-history/billing-history.component';
import { ZipcodePercentageComponent } from '../components/zipcode-percentage/zipcode-percentage.component';

const routes: Routes = [

   { path: 'login', component: LoginComponent},
   { path: 'home', component: DashboardComponent},
   { path: 'ga-list', component: GaListComponent, canActivate: [AuthGuard]},
   { path: 'sa-config-list', component: SaConfigListComponent, canActivate: [AuthGuard] },
   { path: 'group-registration', component:GroupRegistrationComponent},
   { path: 'ga-view/:id', component:GaViewComponent},
   { path: 'edit-group/:id',component:EditGroupComponent},
   { path: 'edit-profile', component:EditProfileComponent},
   { path: 'tokens/:token',component:ResetPasswordComponent},
   { path: 'forgot-password', component:ForgotPasswordComponent},
   { path: 'change-password/:id', component:ChangePasswordComponent},   
   { path: 'system-admins-list', component:  SystemAdminsListComponent, canActivate: [AuthGuard]}, //HB-683 Priya
   { path: 'subscriber-list', component:  SubscriberListComponent, canActivate: [AuthGuard]}, //HB-630 Priya
   { path: 'edit-subscriber/:id', component:  EditSubscriberComponent}, //HB-629 Priya
   { path: 'add-subscriber', component:  AddSubscriberComponent}, //HB-629 Priya  
   { path: 'groupadmins-list/:id', component: GroupadminsListComponent}, // HB-631 Priya   
   { path: 'deactivate-subscriber/:id', component: DeactivateSubscriberComponent}, // HB-632 Priya   
   { path: 'activate-subscriber/:id', component: ActivateSubscriberComponent}, // HB-654 Priya
   {path: 'audit-payment',component:AuditPaymentComponent},
   {path:'system-subscriptionhistory',component:SystemSubscriptionHistoryComponent},
   {path:'billing-history/:id',component:BillingHistoryComponent},

   {path:'zipcode-percentage',component:ZipcodePercentageComponent},
   { path: '**',  component: LoginComponent, canActivate: [AuthGuard]}

 ];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
