import { Injectable } from '@angular/core';
import { ApplicationConstants } from '../../utils/ApplicationConstants';

@Injectable({
  providedIn: 'root'
})

export class TokenStorageService {
  private roles: Array<string> = [];
  private listRoles: Array<Int32Array> = []; //HB-677 Priya
  
  constructor() { }

  public saveUserTokenData(userTokenData: any) {
    
    let jsonObject = {};
    for(var i = 0; i < userTokenData.urlTokens.length; i++) {
      // Trim the excess whitespace.
      userTokenData.urlTokens[i] = userTokenData.urlTokens[i].replace(/^\s*/, "").replace(/\s*$/, "");
      jsonObject[userTokenData.urlTokens[i]] = userTokenData.urlTokens[i];
   }
   //window.localStorage.setItem(ApplicationConstants.URL_TOKENS_LIST_KEY, JSON.stringify(jsonObject));

   var saPortal = {
    session: []
   };

   saPortal.session.push({
     [ApplicationConstants.TOKEN_KEY] :  userTokenData.token,
     [ApplicationConstants.USER_PROFILE_INFO_ID_KEY] :  userTokenData.userProfileInfoId,
     [ApplicationConstants.AUTHORITIES_KEY] :  userTokenData.authorities,
     [ApplicationConstants.EMAIL_KEY] :  userTokenData.email,
     [ApplicationConstants.ROLES] : userTokenData.roles, //HB-677 Priya
     [ApplicationConstants.FULL_NAME_KEY] :  userTokenData.fullName,
     [ApplicationConstants.GROUP_ID_KEY] :  userTokenData.groupId,
     [ApplicationConstants.URL_TOKENS_LIST_KEY] : jsonObject
    });

   window.localStorage.setItem(ApplicationConstants.SYSTEM_PORTAL, JSON.stringify(saPortal.session));
  }
 /** HB-677 Priya Start */
  public getRoles(): Int32List[] {
    // console.log("IMP:"+JSON.parse(localStorage.getItem(ApplicationConstants.SYSTEM_PORTAL))[0][ApplicationConstants.ROLES]);
    this.listRoles = JSON.parse(localStorage.getItem(ApplicationConstants.SYSTEM_PORTAL))[0][ApplicationConstants.ROLES];
    return this.listRoles;
  }
  /** HB-677 Priya End */
  public getToken(): string {
    if(localStorage.getItem(ApplicationConstants.SYSTEM_PORTAL)){
      return JSON.parse(localStorage.getItem(ApplicationConstants.SYSTEM_PORTAL))[0][ApplicationConstants.TOKEN_KEY];
    }
    return null;
  }

  public getuserProfileId(): string {
    return JSON.parse(localStorage.getItem(ApplicationConstants.SYSTEM_PORTAL))[0][ApplicationConstants.USER_PROFILE_INFO_ID_KEY]
  }

  public getEmail(): string {
    return JSON.parse(localStorage.getItem(ApplicationConstants.SYSTEM_PORTAL))[0][ApplicationConstants.EMAIL_KEY]
  }

  public getuserName(): string {
    return JSON.parse(localStorage.getItem(ApplicationConstants.SYSTEM_PORTAL))[0][ApplicationConstants.FULL_NAME_KEY]
  }

  public getAuthorities(): string[] {
    this.roles = [];
    if (JSON.parse(localStorage.getItem(ApplicationConstants.SYSTEM_PORTAL))[0][ApplicationConstants.FULL_NAME_KEY]) {
      return JSON.parse(localStorage.getItem(ApplicationConstants.AUTHORITIES_KEY))[0][ApplicationConstants.FULL_NAME_KEY].forEach(authority => {
        this.roles.push(authority.authority);
      });
    }
    return this.roles;
  }

  public getUrlTokenByKey(key : string): string {
    let data = {};
    if (JSON.parse(localStorage.getItem(ApplicationConstants.SYSTEM_PORTAL))[0][ApplicationConstants.URL_TOKENS_LIST_KEY]) {
      data  = JSON.parse(localStorage.getItem(ApplicationConstants.SYSTEM_PORTAL))[0][ApplicationConstants.URL_TOKENS_LIST_KEY];
    }
    return data[key];
  }

  public clearSession(){
    window.localStorage.clear();
  }
}
